import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import GiftCardForm from '../../components/GiftCardForm';
import { InformationType } from '../../models/Brand';
import { BrandPageProps } from '../../models/BrandPage';
import { GiftCard } from '../../models/GiftCard';
import idinImage from "../../static/img/iDIN-illustration.png";
import calculateImage from "../../static/img/calculation-illustration.png";
import { t } from 'i18next';

interface GiftCardViewProps extends BrandPageProps {
    onNavigate: (giftCard: GiftCard) => void;
    onNavigateCardNumber: (cardNumber: string) => void;
}

const GiftCardView = (props: GiftCardViewProps) => {
    const navigate = useNavigate();

    const handleGiftCardSuccess = React.useCallback(async (giftCard: GiftCard) => {
        props.onNavigate(giftCard);
        navigate(`/${props.brand.code}/identify/provider`);
    }, [props, navigate]);

    return (
        <>
            {
                (props.brand.styling?.informationType === InformationType.ContainerAbove) &&
                <Section className="content">
                    {props.brand.styling?.informationType === InformationType.ContainerAbove &&
                        <p>
                            {t('identify__explanationGiftCard')}
                        </p>
                    }
                </Section>
            }
            <GiftCardForm brand={props.brand} onSuccess={handleGiftCardSuccess} url={`/api/GiftCard/`} method={"POST"} />
            {props.brand.styling?.informationType === InformationType.ContainerBelow &&
                <>
                    <Section className="content" id="content-1">
                    <DescriptionLabel as="h2" display={3} > {t('identify__explanation')}</DescriptionLabel>
                    <DescriptionLabel as="h3" display={5} muted> {t('identify__cardIdentification')}</DescriptionLabel>
                    <p>
                        {t('identify__explanationGiftCardAmount')}
                            
                        </p>
                    </Section>
                    <Section className="content">
                        <Row>
                            <Col sm={12} md={6} className="explainer">
                                <img
                                    src={idinImage}
                                    alt="iDIN"
                                    className="img-fluid img-whitespace-8"
                                />
                            </Col>
                            <Col sm={12} md={6} className="explainer">
                            <DescriptionLabel as="h4" className="display-6">{t('identify__idinIdentificationBy') }</DescriptionLabel>
                                <p>
                                {t('identify__explanationIdin')}
                                </p>
                            </Col>
                        </Row>
                    </Section>
                    <hr />
                    <Section className="content">
                        <Row>
                            <Col sm={12} md={6} className="explainer">
                                <img
                                    src={calculateImage}
                                    alt="iDIN"
                                    className="img-fluid img-whitespace-8"
                                />
                            </Col>
                            <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('identify__giftCard') }</h4>
                                <p>
                                {t('identify__giftCardExplanation') }
                                </p>
                            </Col>
                        </Row>
                    </Section>
                </>
            }
        </>
    );
};

export default GiftCardView;