import { Navigate, useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React from "react";
import { t } from 'i18next';
import { Customer } from "../../models/Customer";
import CustomerInfo from "../../components/CustomerInfo";
interface IdentifySuccessViewProps extends BrandPageProps {
    giftCard?: GiftCard,
    customer?: Customer,
    email?: string;
    cardNumber?: string;
}
const IdentifySuccess = (props: IdentifySuccessViewProps) => {
    const navigate = useNavigate();
    const handleStartNewRegistration = React.useCallback((e: React.MouseEvent) => {
        navigate(`/${props.brand.code}/identify/`);
    }, [navigate, props.brand.code]);


    if (!props.giftCard)
        return <Navigate to={`/${props.brand.code}/identify`} replace />;

    return (
        props.giftCard ?
            <>
                <Section>
                    <div className="content ">
                        <div className="panel">
                            <div className="panel-row">
                                <div className="icon-check-circle__color bg-success icon-md"></div>
                                <div className="panel">
                                    <div className="label">{t("identify__success_registered")}</div>
                                    <div className="bigger">
                                        <span className="muted">{props.cardNumber?.replace(/[^a-z0-9]/gi, '')
                                            .replace(/(.{4})/g, '$1 ').trim()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel">
                            <p>{t("identify__success_description")}</p>
                        </div>
                    </div>
                
                </Section>
                <Section>
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={handleStartNewRegistration}>
                            {t('identify__register_another_card')}
                        </button>
                    </div>
                </Section>                

            </> :
            <>  </>
    );
};


export default IdentifySuccess;

