﻿import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { CodeScanner } from '../CodeScanner/CodeScanner';
import { ToolTip } from '../CustomTooltip';
import { ToolTipProps } from '../CustomTooltip/ToolTip';
import { InputProps } from '.';

interface CardInputProps extends InputProps {
    label: string,
    onChange: (value: string | undefined) => void,
    inputRef: RefObject<HTMLInputElement>;
    useScanner?: boolean;
    addToolTip?: boolean;
    toolTip?: {
        props: ToolTipProps;
        enabled: boolean;
        type: "icon" | "subtext";
    };
    placeholder?: string,
}

const LEFT_TO_RIGHT_MARK = "‎\u200e"; 

const CardInput: React.FC<CardInputProps> = (props: CardInputProps) => {
    const [cardNumber, setCardNumber] = useState<string>();
    const [displayCardNumber, setDisplayCardNumber] = useState<string>();
    const [renderCapture, setRenderCapture] = useState<boolean>(false);

    const handleScan = React.useCallback((decodedText: string, decodedResult: any) => {
        setCardNumber(decodedText);
        setDisplayCardNumber(decodedText
            .replace(/\s/gi, '')
            .replace(/(.{4})/g, '$1 ').trim());

        props.inputRef.current?.focus();
        setRenderCapture(false);
    }, [setCardNumber, setDisplayCardNumber, props.inputRef, setRenderCapture]);

    const toggleCapture = React.useCallback(() => {
        setRenderCapture(!renderCapture);
    }, [setRenderCapture]);

    const onCardNumberChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const newCardNumber = event.target.value.replace(LEFT_TO_RIGHT_MARK, "");
        
        setCardNumber(newCardNumber.replace(/\s/g, ''));

        setDisplayCardNumber(newCardNumber
            .replace(/\s/gi, '')
            .replace(/(.{4})/g, '$1 ').trim());
    }, [setCardNumber, setDisplayCardNumber]);

    useEffect(() => {
        props.onChange(cardNumber);
    }, [cardNumber]);

    return (
        <Form.Group className={`form-group ${props.className}`}>
            {props.useScanner &&
                <CodeScanner fps={10}
                    qrbox={{ width: 250, height: 250 }}
                    disableFlip={false}
                    scanSuccessFallback={handleScan}
                    rememberLastUsedCamera={false}
                    isScanning={renderCapture} />
            }

            {props.label &&
                <React.Fragment>
                    <Form.Label>{props.label}</Form.Label>

                    {props.toolTip?.enabled && props.toolTip.type === "icon" &&
                        <ToolTip {...props.toolTip.props}></ToolTip>
                    }
                </React.Fragment>
            }
            <InputGroup>
                <Form.Control placeholder={props.placeholder} name="giftCardNumber"
                    className={
                        props.error ? "giftCardNumber large error" : "giftCardNumber large"
                    }
                    value={displayCardNumber ? LEFT_TO_RIGHT_MARK + displayCardNumber : displayCardNumber}
                    required
                    type="text"
                    inputMode="text"
                    onChange={onCardNumberChange}
                    ref={props.inputRef}
                    aria-describedby="captureButton"
                    disabled={props.disabled}
                />
                {props.useScanner &&
                    <Button className={
                        props.error
                            ? "btn-icon is-secondary error"
                            : "btn-icon is-secondary"
                    }
                        title="Scan kaartnummer"
                        onClick={toggleCapture}
                        id="captureButton"
                        disabled={props.disabled }
                    >
                        <div className="icon icon-camera__invert"></div>
                    </Button>
                }
            </InputGroup>
            {props.toolTip?.enabled && props.toolTip.type == "subtext" &&
                <Form.Text className="muted">{props.toolTip.props.text}</Form.Text>
            }
        </Form.Group>
    );
};

export default CardInput;