import { useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { Section, Title } from 'intersolve.web.componentlib';
import React, { useEffect } from "react";
import { t } from 'i18next';

interface PayOutCancelledViewProps extends BrandPageProps {
    giftCard?: GiftCard,
}

const PayOutCardCancelled = (props: PayOutCancelledViewProps) => {
    const navigate = useNavigate();
    const handleStartNewPayOut = React.useCallback((e: React.MouseEvent) => {
        navigate(`/${props.brand.code}/payout/amount/`);
    }, [navigate, props.brand.code]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <Section className="section">
                    <div className="content">
                        <h1 className="display-2 has-icon">
                            <span className="icon icon-warning__color bg-warning"></span>
                            {t("payout_cancelled_title")}
                        </h1>
                        <div className="panel">
                            {t("payout_cancelled_text")}
                        </div>
                    </div>
                </Section>

                <Section className="section">
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={handleStartNewPayOut}>
                            {t('generic__retryButtonLabel')}
                        </button>
                    </div>
                </Section>

            </> :
            <>  </>
    );
};


export default PayOutCardCancelled;

