import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RetryButton, Title, Section, Panel } from "intersolve.web.componentlib";
import { useNavigate } from "react-router";
import { CreditCard } from "../../models/CreditCard";
import CardBalance from "../../components/CardBalance";
import { BrandPageProps } from "../../models/BrandPage";

interface VisaFailedProps extends BrandPageProps {
    creditCard?: CreditCard;
}
function VisaFailed(props: VisaFailedProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    const handleBack = React.useCallback(() => {
        navigate(`/${props.brand.code}/visa/activate/otc`, { replace: true });
    }, [navigate, props.brand.code]);

    useEffect(() => {
        if (!props.creditCard)
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
    });

    return (
        props.creditCard ?
            <>
                <Title
                    title={t("visa__activation_failed_title")}
                    subtitle={t("visa__activation_failed_subtitle")}
                ></Title>
                <Section>
                    <div className="content">
                        <Panel>
                            <p>
                                {t("visa__activation_failed_text")}
                            </p>
                        </Panel>
                    </div>
                </Section>
                <CardBalance
                    tag_type="danger"
                    tag_title={t("generic__tagInactive")}
                    card_title={t("visa__cardnumber")}
                    boxed={true}
                    creditCard={props.creditCard}
                />
                <Section>
                    <div className="content">
                        <RetryButton type="submit" onClick={handleBack}>
                            {t("form__retryButton")}
                        </RetryButton>
                    </div>
                </Section>
            </> :
            <></>
    );
}

export default VisaFailed;
