import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/*
* This component is used to handle the redirect callback from the identity provider. It will redirect the user to the flow he was on before the start of the identification process.
*/
const RedirectCallbackHandler = () => {
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(window.location.search);
    const state = searchParams.get('state');

    useEffect(() => {
        if (state) {
            const storedInfo = localStorage.getItem(state);
            if (storedInfo) {
                const parsedInfo = JSON.parse(storedInfo);
                navigate(`${parsedInfo.url}${window.location.search}`);
            }
        }
        else {
            navigate("/");
        }

    }, []);

    return <></>;

};

export default RedirectCallbackHandler;