import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Form } from "react-bootstrap";
import EditableListItem from "../../EditableListItem";
import { DefaultButton, RetryButton, countryList, Panel } from "intersolve.web.componentlib";
import { useTranslation } from 'react-i18next';
import { Brand } from "../../../models/Brand";
import { CardHolderInformation } from "../../../models/CardHolderInformation";
import { CreditCard } from "../../../models/CreditCard";

interface VisaReviewInfoFormProps {
    isReview: boolean;
    isPartial?: boolean;
    readOnly?: boolean;
    brand: Brand;
    holderInformation?: CardHolderInformation;
    creditCard: CreditCard;
}

const VisaReviewInfoForm = (props: VisaReviewInfoFormProps) => {
    const { t, i18n } = useTranslation();
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [formEnabled, setFormEnabled] = useState(false);
    const [editEnabled, setEditEnabled] = useState(true);
    const [reviewChecked, setReviewChecked] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [error, setError] = useState(false);
    const [allDataEntered, setAllDataEntered] = useState(false);

    const [nationality, setNationality] = useState(props.holderInformation?.nationality);
    const [countryOfBirth, setCountryOfBirth] = useState(props.holderInformation?.countryOfBirth);
    const [dateOfBirth, setDateOfBirth] = useState(props.holderInformation?.dateOfBirth);
    const [countries, setCountries] = useState<any[]>([]);

    useEffect(() => {
        if (!props.isReview) {
            setReviewChecked(true);
            setSubmitEnabled(true);
        }
        // when partial is true, enable only the fields that are partial
        // the edit button should be hidden
        // the submit button should be enabled

        if (props.isPartial) {
            setFormEnabled(true);
            setEditEnabled(false);
            setSubmitEnabled(true);
        }

        if (props.readOnly) {
            setFormEnabled(false);
            setEditEnabled(false);
            setSubmitEnabled(false);
        }
    }, [props.isPartial, props.isReview, props.readOnly]);

    useEffect(() => {
        const countries = countryList(i18n.language);
        setCountries(countries);
    }, [i18n.language]);

    useEffect(() => {
        if (dateOfBirth && nationality && countryOfBirth) {
            setAllDataEntered(true);
        }
    }, [dateOfBirth, nationality, countryOfBirth]);

    useEffect(() => {
        if (props.holderInformation !== undefined && nationality === undefined) {
            setNationality(props.holderInformation.nationality);
            setCountryOfBirth(props.holderInformation.countryOfBirth);
            setDateOfBirth(props.holderInformation.dateOfBirth);
        }
    }, [nationality, props.holderInformation]);

    const handleReviewChange = React.useCallback((event) => {
        setReviewChecked(event.target.checked);
        // if review is checked, enable submit button, disable edit button
        // if review is unchecked and form is not enabled, do not enable form, but do enable edit form button
        // if review is unchecked and form is enabled, disable submit button, enable edit button

        if (event.target.checked) {
            setSubmitEnabled(true);
            setEditEnabled(false);
        }
        if (!event.target.checked && !formEnabled) {
            setSubmitEnabled(false);
            setEditEnabled(true);
            if (props.isPartial)
                setFormEnabled(true);
        }
        if (event.target.checked && formEnabled) {
            setSubmitEnabled(true);
            setEditEnabled(false);
            setFormEnabled(false);
        }
    }, [formEnabled, props.isPartial]);

    const navigate = useNavigate();

    const handleAdditionalInformationSubmit = React.useCallback(async (event) => {
        event.preventDefault();
        setError(false);
        try {
            const response = await fetch(`/api/visa/UpdateHolderInformation/${props.brand.code}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: props.creditCard.cardNumber,
                    securityCode: props.creditCard.securityCode,
                    dateOfBirth: dateOfBirth,
                    countryOfBirth: countryOfBirth,
                    nationality: nationality
                })
            });

            if (response.ok)
                navigate(`/${props.brand.code}/visa/activate/pin`, { replace: true });
            else 
                setError(true);
        }
        catch {
            setError(true);
            console.error("Error while submitting additional information");
        }
    }, [countryOfBirth, dateOfBirth, nationality, navigate, props.brand.code, props.creditCard.cardNumber, props.creditCard.securityCode]);

    const handleSubmit = React.useCallback((event) => {
        event.preventDefault();
        if (submitEnabled) {
            setFormEnabled(false);
            navigate(`/${props.brand.code}/visa/activate/pin`, { replace: true });
        } else {
            // scroll to checkbox
            if (props.isReview) {
                const checkbox = document.getElementById("reviewInfo");
                checkbox!.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [navigate, props.brand.code, props.isReview, submitEnabled]);

    const toggleForm = React.useCallback(() => {
        setResetForm(formEnabled);
        setFormEnabled(!formEnabled);
    }, [formEnabled]);

    return (
        <>
            {error &&
                <Panel>
                    <Alert key="danger" variant="danger my-0">
                        {t("visa__reviewInformationError")}
                    </Alert>
                </Panel>
            }
            <Form onSubmit={handleSubmit}>
                <h4 className="display-6">
                    {t("form__personal_information_title")}
                </h4>
                <ul className="list-group">
                    <EditableListItem
                        isEditable={formEnabled}
                        isReset={resetForm}
                        type="name"
                        label={t("form__name")}
                        defaultValue={props.holderInformation?.holderName}
                    />
                    <EditableListItem
                        isEditable={formEnabled}
                        isReset={resetForm}
                        type="address"
                        label={t("form__address")}
                        defaultAddress={{
                            street: props.holderInformation?.address?.addressLine1,
                            city: props.holderInformation?.address?.city,
                            postalCode: props.holderInformation?.address?.postalCode,
                        }}
                    />
                    <EditableListItem
                        isEditable={formEnabled}
                        isReset={resetForm}
                        type="email"
                        label={t("form__email")}
                        defaultValue={props.holderInformation?.email}
                    />
                    <EditableListItem
                        isEditable={formEnabled}
                        isReset={resetForm}
                        type="tel"
                        label={t("form__phone")}
                        defaultValue={props.holderInformation?.phoneNumber}
                    />
                </ul>
                <h4 className="display-6">
                    {t("form__national_info_title")}
                </h4>
                <ul className="list-group">
                    <EditableListItem
                        isEditable={formEnabled}
                        isPartial={props.isPartial}
                        isReset={resetForm}
                        type="date"
                        label={t("form__birthdate")}
                        isRequired={true}
                        onChange={setDateOfBirth}
                        defaultValue={dateOfBirth}
                    />
                    <EditableListItem
                        isEditable={formEnabled}
                        isPartial={props.isPartial}
                        isReset={resetForm}
                        items={countries.map(i => { return { name: i.name, value: i.code }; })}
                        type="select"
                        label={t("form__country_of_birth")}
                        selectLabel={t("generic__selectCountry")}
                        defaultValue={countryOfBirth}
                        isRequired={true}
                        onChange={setCountryOfBirth}
                    />
                    <EditableListItem
                        isEditable={formEnabled}
                        isPartial={props.isPartial}
                        isReset={resetForm}
                        items={countries.map(i => { return { name: i.name, value: i.code }; })}
                        selectLabel={t("generic__selectNationality")}
                        type="select"
                        label={t("form__nationality")}
                        defaultValue={nationality}
                        isRequired={true}
                        onChange={setNationality}
                    />
                </ul>
                {props.isReview && (
                    <Form.Check>
                        <Form.Check.Input
                            type="checkbox"
                            checked={reviewChecked}
                            onChange={handleReviewChange}
                            id="reviewInfo"
                            name="reviewInfo"
                            required
                            disabled={!allDataEntered }
                        />
                        <Form.Check.Label htmlFor="reviewInfo">
                            {t("form__reviewed")}
                        </Form.Check.Label>
                    </Form.Check>
                )}
                {props.isReview || formEnabled ? (
                    <DefaultButton className="btn btn-primary" onClick={handleAdditionalInformationSubmit}>
                        {t("form__confirmButton")}
                    </DefaultButton>
                ) : (
                    false
                )}
                {/* when the form is enabled show a cancel button, otherwise show an edit button */}
                {/* when form is partial, edit button and cancel button should be hidden */}
                {!props.isPartial && !props.readOnly && (
                    <>
                        {formEnabled ? (
                            <RetryButton
                                className="btn btn-light"
                                type="button"
                                onClick={toggleForm}
                            >
                                {t("form__discardButton")}
                            </RetryButton>
                        ) : (
                            <DefaultButton
                                className="btn btn-light"
                                type="button"
                                onClick={toggleForm}
                                disabled={!editEnabled}
                            >
                                {t("form__editButton")}
                            </DefaultButton>
                        )}
                    </>
                )}
            </Form>
        </>
    );
};

export default VisaReviewInfoForm;
