import React, { useState, useCallback, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { GiftCard, PayoutGiftCard } from '../models/GiftCard';
import PayOutCheck from '../pages/PayOut/PayOutCheck';
import PayOutCard from '../pages/PayOut/PayOutCard';
import PayOutCardConfirm from '../pages/PayOut/PayOutCardConfirm';
import IDINCallbackHandler from '../components/iDINCallbackHandler';
import { Customer } from '../models/Customer';
import PayOutCardSuccess from '../pages/PayOut/PayOutCardSuccess';
import { Brand } from '../models/Brand';
import { Helmet } from 'react-helmet-async';
import PayOutLayOut from '../pages/PayOut/PayOutLayOut';
import PayOutCardCancelled from '../pages/PayOut/PayOutCardCancelled';
import PayOutCardNoBalance from '../pages/PayOut/PayOutCardNoBalance';
import PayOutCardUnsufficientBalance from '../pages/PayOut/PayOutCardUnsufficientBalance';
import PayOutCardFailed from '../pages/PayOut/PayOutCardFailed';
import PayOutCardAlreadyRegistered from '../pages/PayOut/PayOutCardAlreadyRegistered';

export const PayOutRoutes = () => {
    const [brand, setBrand] = useState<Brand>();
    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(async () => {
        try {
            const response = await fetch(`/api/brand/${brandId}`);
            const data = await response.json();
            setBrand(data);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    }, [setBrand, brandId]);
       

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <PayOutLayOut brand={brand}>
                    <PayOutRouteSet brand={brand} />
                </PayOutLayOut>
            }
        </>
    );
};

interface PayOutRouteProps {
    brand: Brand;
}

const PayOutRouteSet = (props: PayOutRouteProps) => {

    const [customer, setCustomer] = useState<Customer>();
    const [giftCard, setGiftCard] = useState<PayoutGiftCard>();
    const [securityCode, setSecurityCode] = useState<string>();
    
    const navigate = useNavigate();

    const fetchCustomerBySessionId = useCallback(async (brandId, sessionId, cardNumber, securityCodeValue) => {
        const response = await fetch(`/api/Authentication/${brandId}/customer`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: cardNumber,
                    securityCode: securityCodeValue,
                    sessionId: sessionId
                })
            });
        try {
            if (response.ok) {
                const data = await response.json();
                setCustomer(data);
                navigate(`/${props.brand.code}/payout/verification`);
            }
            else if (response.status === 400) {
                const errorResponse = await response.json();

                if (errorResponse.code === "USER_CANCELLED")
                    navigate(`/${props.brand.code}/payout/cancelled/`);
                else if (errorResponse.code === "CARD_ALREADY_REGISTERED")
                    navigate(`/${props.brand.code}/payout/alreadyregistered/`);
                else
                    navigate(`/${props.brand.code}/payout/failed/`);
            }
            else
            {               
                navigate(`/${props.brand.code}/payout/failed/`);
            }
        }
        catch (error) {
            navigate(`/${props.brand.code}/payout/failed/`);
        }
    }, [navigate, props.brand.code]);

    const onCallBack = useCallback(async (sessionId, state) => {
        if (state) {
            const storage = localStorage.getItem(state);
            if (storage) {
                const parsed = JSON.parse(storage);
                setGiftCard(parsed.giftCard);
                setSecurityCode(parsed.securityCode);
                const giftCardData = parsed.giftCard;
                const securityCodeData = parsed.securityCode;

                localStorage.removeItem(state);
                await fetchCustomerBySessionId(props.brand.code, sessionId, giftCardData.code, securityCodeData);
            }
        }      
    }, [fetchCustomerBySessionId, props.brand.code]);

    const onNavigateGiftCardView = useCallback((giftCard: PayoutGiftCard, securityCode: string | undefined) => {
        setGiftCard(giftCard);
        setSecurityCode(securityCode);
    }, [setGiftCard, setSecurityCode]);

    const clearData = useCallback(async () => {
        setGiftCard(undefined);
        setSecurityCode(undefined);
        setCustomer(undefined);
    }, [setGiftCard, setSecurityCode])

    return (
        <>
            <Routes>
                <Route>
                    <Route path="amount" element={<PayOutCard giftCard={giftCard} brand={props.brand} securityCode={securityCode} />} />
                    <Route path="verification" element={<PayOutCardConfirm giftCard={giftCard} securityCode={securityCode} brand={props.brand} customer={customer} />} />
                    <Route path="success" element={<PayOutCardSuccess giftCard={giftCard} brand={props.brand} onNew={clearData} />} />
                    <Route path="unsufficientbalance" element={<PayOutCardUnsufficientBalance giftCard={giftCard} brand={props.brand} customer={customer} onRetry={clearData} />} />
                    <Route path="nobalance" element={<PayOutCardNoBalance giftCard={giftCard} brand={props.brand} customer={customer} onRetry={clearData} />} />
                    <Route path="callback" element={<IDINCallbackHandler onCallBack={onCallBack} />} />
                    <Route path="failed" element={<PayOutCardFailed brand={props.brand} giftCard={giftCard} />} />
                    <Route path="cancelled" element={<PayOutCardCancelled brand={props.brand} giftCard={giftCard} />} />
                    <Route path="alreadyregistered" element={<PayOutCardAlreadyRegistered brand={props.brand} giftCard={giftCard} onNew={clearData} />} />
                    <Route path="" element={<PayOutCheck brand={props.brand} onNavigate={onNavigateGiftCardView} giftCard={giftCard} />} />
                </Route>
            </Routes>
        </>
    );

};