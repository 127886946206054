import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ToolTipProps {
    text: string;
    className?: string;
    placement?: 'right' | 'left' | 'bottom' | 'top';
}

const ToolTip = (props: ToolTipProps) => {
    return (
        <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={
                <Tooltip className={props.className ?? ""} >
                    {props.text}
                </Tooltip>
            }
            placement={props.placement}
        >
            <span className="icon-xs icon-information__color"></span>
        </OverlayTrigger>
    );
};

export default ToolTip;