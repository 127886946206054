import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useParams } from 'react-router-dom';
import { Brand } from '../models/Brand';
import { BalanceGiftCard, GiftCard } from '../models/GiftCard';
import BalanceForgotSuccess from '../pages/reset/BalanceForgotSuccess';
import BalanceForgotCode from '../pages/reset/BalanceForgotCode';
import ResetLayout from '../pages/reset/ResetLayout';
import ErrorPage from '../pages/generic/Error';

export const ResetRoutes = () => {
    const [brand, setBrand] = useState<Brand>();

    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(() => {
        fetch(`/api/brand/${brandId}`)
            .then((res) => res.json())
            .then((res) => setBrand(res));
    }, [setBrand, brandId]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <ResetLayout brand={brand}>
                    <ResetRouteSet brand={brand} />
                </ResetLayout>
            }
        </>
    );
};

interface ResetRouteProps {
    brand: Brand;
}

const ResetRouteSet = (props: ResetRouteProps) => {
    const [giftCard, setGiftCard] = useState<BalanceGiftCard>();

    const onSecurityNavigate = useCallback((giftCard: GiftCard) => {
        setGiftCard(giftCard as BalanceGiftCard);
    }, []);

    return <>
        <Routes>
            <Route path="error" element={<ErrorPage brand={props.brand} />} />
            <Route path="success" element={<BalanceForgotSuccess brand={props.brand} giftCard={giftCard} />} />
            <Route path="" element={<BalanceForgotCode brand={props.brand} onNavigate={onSecurityNavigate} />} />
        </Routes>
    </>;
};