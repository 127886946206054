import React from 'react';

interface CurrencyProps {
    code: string;
}

interface ICurrency {
    code: string,
    symbol: string;
}

//Generate a function that returns a currency symbol based on the currency code
const currencyList: ICurrency[] = [
    { code: "EUR", symbol: "&euro;" },
    { code: "EUR_MPV", symbol: "&euro;" },
    { code: "USD", symbol: "&dollar;" },
    { code: "GBP", symbol: "&pound;" },
    { code: "JPY", symbol: "&yen;" },
    { code: "CNY", symbol: "&yen;" },
    { code: "AUD", symbol: "&dollar;" },
    { code: "CAD", symbol: "&dollar;" },
    { code: "CHF", symbol: "&#8355;" },
    { code: "SEK", symbol: "&#107;&#114;" },
    { code: "NOK", symbol: "&#107;&#114;" },
    { code: "DKK", symbol: "&#107;&#114;" },
    { code: "RUB", symbol: "&#8381;" },
    { code: "INR", symbol: "&#8377;" },
    { code: "BRL", symbol: "&#82;&dollar;" },
    { code: "MXN", symbol: "&dollar;" },
    { code: "ZAR", symbol: "&#82;" },
    { code: "TRY", symbol: "&#8378;" },
    { code: "HKD", symbol: "&dollar;" },
    { code: "SGD", symbol: "&dollar;" },
    { code: "NZD", symbol: "&dollar;" },
    { code: "THB", symbol: "&#3647;" },
    { code: "TWD", symbol: "&dollar;" },
    { code: "PHP", symbol: "&#8369;" },
    { code: "IDR", symbol: "&#82;&#112;" },
    { code: "KRW", symbol: "&#8361;" },
    { code: "MYR", symbol: "&#82;&#77;" },
    { code: "ILS", symbol: "&#8362;" },
    { code: "PLN", symbol: "&#122;&#322;" },
    { code: "HUF", symbol: "&#70;&#116;" },
    { code: "CZK", symbol: "&#75;&#269;" },
    { code: "AED", symbol: "&#1583;.&#1573;" },
    { code: "SAR", symbol: "&#1583;.&#1573;" },
    { code: "ARS", symbol: "&dollar;" },
    { code: "CLP", symbol: "&dollar;" },
    { code: "COP", symbol: "&dollar;" },
    { code: "PEN", symbol: "&#83;&#47;&#46;" },
    { code: "UYU", symbol: "&dollar;&#85;" },
    { code: "VND", symbol: "&#8363;" },
    { code: "BOB", symbol: "&dollar;&#98;" },
    { code: "CRC", symbol: "&#8353;" },
    { code: "GTQ", symbol: "&#81;" },
    { code: "HNL", symbol: "&#76;" },
    { code: "NIO", symbol: "&#67;&dollar;" },
    { code: "PAB", symbol: "&#66;&#47;&#46;" },
    { code: "PYG", symbol: "&#71;&#115;" },
    { code: "UYU", symbol: "&dollar;&#85;" },
    { code: "VEF", symbol: "&#66;&#115;" },
    { code: "BGN", symbol: "&#1083;&#1074;" },
    { code: "RON", symbol: "&#108;&#101;&#105;" },
    { code: "HRK", symbol: "&#107;&#110;" },
    { code: "ISK", symbol: "&#107;&#114;" },
    { code: "BAM", symbol: "&#75;&#77;" },
    { code: "MKD", symbol: "&#1076;&#1077;&#1085;" },
    { code: "LTL", symbol: "&#76;&#116;" },
    { code: "LVL", symbol: "&#76;&#115;" },
    { code: "EEK", symbol: "&#107;&#114;" },
    { code: "AZN", symbol: "&#8380;" },
    { code: "GEL", symbol: "&#4314;" },
    { code: "ALL", symbol: "&#76;&#101;&#107;" },
    { code: "AMD", symbol: "&#1403;" },
    { code: "KZT", symbol: "&#1083;&#1074;" },
    { code: "KGS", symbol: "&#1083;&#1074;" },
    { code: "TJS", symbol: "&#84;&#74;&#83;" },
    { code: "UZS", symbol: "&#1083;&#1074;" },
    { code: "BDT", symbol: "&#2547;" },
    { code: "LKR", symbol: "&#8360;" },
    { code: "MVR", symbol: ".&#1923;" },
    { code: "MUR", symbol: "&#8360;" },
    { code: "NPR", symbol: "&#8360;" },
    { code: "PKR", symbol: "&#8360;" },
    { code: "SCR", symbol: "&#8360;" },
    { code: "EGP", symbol: "&pound;" },
    { code: "KWD", symbol: "&#1583;.&#1603;" },
    { code: "OMR", symbol: "&#65020;" },
    { code: "QAR", symbol: "&#65020;" },
    { code: "SYP", symbol: "&pound;" },
    { code: "TND", symbol: "&#1583;.&#1578;" },
    { code: "YER", symbol: "&#65020;" },
    { code: "IRR", symbol: "&#65020;" },
    { code: "JOD", symbol: "&#74;&#68;" },
    { code: "LBP", symbol: "&pound;" },
    { code: "LYD", symbol: "&#1604;.&#1583;" },
    { code: "SOS", symbol: "&#83;" },
    { code: "SDG", symbol: "&pound;" },
    { code: "SLL", symbol: "&#76;&#101;" },
    { code: "KES", symbol: "&#75;&#83;&#104;" },
    { code: "SHP", symbol: "&pound;" },
    { code: "SBD", symbol: "&dollar;" },
    { code: "STD", symbol: "&#68;&#98;" },
    { code: "SZL", symbol: "&#76;" },
    { code: "BBD", symbol: "&dollar;" },
    { code: "BMD", symbol: "&dollar;" },
    { code: "BND", symbol: "&dollar;" },
    { code: "KYD", symbol: "&dollar;" },
    { code: "FJD", symbol: "&dollar;" },
    { code: "GYD", symbol: "&dollar;" },
    { code: "JMD", symbol: "&#74;&dollar;" },
    { code: "LRD", symbol: "&dollar;" },
    { code: "NAD", symbol: "&dollar;" },
    { code: "SRD", symbol: "&dollar;" },
    { code: "TTD", symbol: "&dollar;&#84;&#84;" },
    { code: "XCD", symbol: "&dollar;" },
    { code: "BSD", symbol: "&dollar;" },
    { code: "BZD", symbol: "&#66;&#90;&dollar;" },
    { code: "SVC", symbol: "&dollar;" },
    { code: "FKP", symbol: "&pound;" },
    { code: "GIP", symbol: "&pound;" },
    { code: "GGP", symbol: "&pound;" },
    { code: "IMP", symbol: "&pound;" },
    { code: "JEP", symbol: "&pound;" },
    { code: "SHP", symbol: "&pound;" },
    { code: "SYP", symbol: "&pound;" },
    { code: "TVD", symbol: "&dollar;" },
    { code: "XCD", symbol: "&dollar;" },
    { code: "XDR", symbol: "&#83;&#112;&#101;&#99;&#105;&#97;&#108;" },
    { code: "XAG", symbol: "&#65;&#103;" },
    { code: "XAU", symbol: "&#71;&#111;&#108;&#100;" },
    { code: "XPD", symbol: "&#80;&#97;&#108;&#108;&#97;&#100;&#105;&#117;&#109;" },
    { code: "XPT", symbol: "&#80;&#108;&#97;&#116;&#105;&#110;&#117;&#109;" },
    { code: "XPF", symbol: "&#70;" },
    { code: "ZMW", symbol: "&#90;&#75;" },
    { code: "ZWL", symbol: "&#90;&dollar;" },
    { code: "BTC", symbol: "&#3647;" },
    { code: "XBT", symbol: "&#3647;" },
    { code: "XLM", symbol: "&#88;&#76;&#77;" },
    { code: "XMR", symbol: "&#88;&#77;&#82;" },
    { code: "XRP", symbol: "&#88;&#82;&#80;" },
    { code: "XVG", symbol: "&#88;&#86;&#71;" },
    { code: "XEM", symbol: "&#88;&#69;&#77;" },
    { code: "XLM", symbol: "&#88;&#76;&#77;" },
    { code: "NO_ASSET", symbol: ""}
];

const Currency = (props: CurrencyProps) => {
    const currency = currencyList.find(c => c.code === props.code);

    if (currency)
        return <span dangerouslySetInnerHTML={{__html: currency.symbol} }></span>;

    return <span>{props.code}</span>;
};

export default Currency;