import { Row, Col } from "react-bootstrap";
import serialExampleImage from "../../../static/img/intersolve-visa-reverse-serial.png";
import { useTranslation } from "react-i18next";

const VisaCardAccountLoginSection = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="section" id={t('visa__content_security_anchor') ?? ""}>
                <div className="content">
                    <h2 className="display-3">
                        {t("visa__login_content_title")}
                    </h2>
                    <h3 className="display-5 text-muted">
                        {t("visa__login_content_subtitle")}
                    </h3>
                </div>
            </section>
            <section className="section">
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={serialExampleImage}
                                alt={t("visa__content_security_1_imageAlt") ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">
                                {t("visa__content_security_1_title")}
                            </h4>
                            <p>{t("visa__content_security_1_description")}</p>
                        </Col>
                    </Row>
                </div>
            </section>
            {/* <hr />
      <section className="section">
        <div className="content">
          <Row>
            <Col sm={12} md={6} className="explainer">
              <img
                src={enterCodeImage}
                alt={t("visa__content_security_2_imageAlt")}
                className="img-fluid img-whitespace-8"
              />
            </Col>
            <Col sm={12} md={6} className="explainer">
              <h4 className="display-6">
                {t("visa__content_security_2_title")}
              </h4>
              <p>
                {t("visa__content_security_2_description")}
              </p>
            </Col>
          </Row>
        </div>
      </section> */}

        </>
    );
};

export default VisaCardAccountLoginSection;
