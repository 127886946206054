import React from "react";

interface PanelProps{
    children?: string | JSX.Element | JSX.Element[];
}

const Panel = (props: PanelProps) => {
    return (
        <div className="panel">
            {props.children}
        </div>
    );
};

export default Panel;