import React from "react";
import { CreditCard } from "../models/CreditCard";
import { Panel, Currency } from "intersolve.web.componentlib";
import { useTranslation } from "react-i18next";

export interface CardBalanceProps {
    balance?: number;
    valid?: string | null;
    currency?: string;
    tag_type?: string;
    tag_title?: string | null;
    boxed?: boolean;
    card_title?: string | null;
    balance_title?: string | null;
    creditCard?: CreditCard;
    children?: any;
    hideBalance?: boolean;
}

const CardBalance = (props: CardBalanceProps) => {
    let contentClassName = "content";
    // If boxed is true, add "box" to the className
    if (props.boxed) {
        contentClassName += " box bg__light-lighter-5";
    }

    const { i18n } = useTranslation();

    return (
        <>
            <section className="section">
                <div className={contentClassName}>
                    <div className="panel">
                        {props.card_title &&
                            <div className="label">{props.card_title}</div>
                        }
                        <div className="bigger">
                            <span dir="ltr"><span className="text-muted">XXXX XXXX XXXX XXXX </span>{props.creditCard && props.creditCard?.cardNumber?.length >= 3 ? <>{props.creditCard.cardNumber.slice(-3)}</> : <>XXX</>}</span>
                            {props.tag_type && (
                                <span className={`tag tag__${props.tag_type}`}>{props.tag_title}</span>
                            )}
                        </div>
                    </div>
                    {props.hideBalance === true ?
                        <></> :
                        <>
                            {/* show a panel when payoutBalance is not undefined */}
                            {props.balance === undefined && (
                                <Panel>
                                    <div className="label">{props.balance_title ? props.balance_title : "Saldo"}</div>
                                    <div className="panel-row">
                                        <div className="bigger"></div>
                                        <div>{props.valid}</div>
                                    </div>
                                </Panel>
                            )}
                            {props.balance !== undefined && (
                                <Panel>
                                    <div className="label">{props.balance_title ? props.balance_title : "Saldo"}</div>
                                    <div className="panel-row">
                                        <div className="bigger"><Currency code={props.currency} />
                                            {props.balance > 0 ?
                                                ((props.balance / 100).toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) :
                                                Number(0).toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            }
                                        </div>
                                        <div>{props.valid}</div>
                                    </div>
                                </Panel>
                            )}
                        </>
                    }
                </div>
            </section>
        </>
    );
}

export default CardBalance;
