import { Currency } from 'intersolve.web.componentlib';
import { useCallback } from 'react';
import { Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { PayoutGiftCard } from '../../models/GiftCard';
import InformationIconTooltip from '../InformationIconTooltip';

interface PayoutPricingTableProps {
    giftCard: PayoutGiftCard;
}

const PayoutPricingTable = (props: PayoutPricingTableProps) => {
    const { t } = useTranslation();

    const formatCurrency = useCallback((quantity: number) => {
        const currency = quantity / 100;
        return currency.toLocaleString("nl", { useGrouping: false, minimumFractionDigits: 2 });
    }, [])

    return (
        <Table className="table-pricing">
            <tbody>
                <tr>
                    <td>{(props.giftCard?.code)}</td>
                    <td className="price">
                        <strong>
                            <Currency code={props.giftCard.payoutBalance.assetCode} /> {formatCurrency(props.giftCard.payoutBalance.balance ?? 0)}
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td>
                        <InformationIconTooltip
                            textKey="payout__administrative_costs"
                            hintTextKey="payout__administrative_costs_hint"
                        />
                    </td>
                    <td className="price">
                        {props.giftCard.payoutBalance.fee && props.giftCard.payoutBalance.fee > 0 ? "- " : ""}
                        <Currency code={props.giftCard.payoutBalance.assetCode} /> {formatCurrency(props.giftCard.payoutBalance.fee ?? 0)}</td>
                </tr>
                <tr className="total">
                    <td>{t("generic__payOutAmount")}</td>
                    <td className="price">
                        <strong><Currency code={props.giftCard.payoutBalance.assetCode} /> {formatCurrency(props.giftCard.payoutBalance.payout ?? 0)}</strong>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default PayoutPricingTable;