﻿import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { InputProps } from '.';

interface CreditCardInputProps extends InputProps {
    label: string,
    onChange: (value: string | undefined) => void,
    inputRef: RefObject<HTMLInputElement>;
    helpText?: string,
    placeholder?: string,
}

const LEFT_TO_RIGHT_MARK = "‎\u200e";

const CreditCardInput: React.FC<CreditCardInputProps> = (props: CreditCardInputProps) => {
    const [cardInput, setCardInput] = useState<string>();
    const [displayInputValue, setDisplayInputValue] = useState<string>();

    const onInputValueChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const newCardNumber = event.target.value.replace(LEFT_TO_RIGHT_MARK, "");
        setCardInput(newCardNumber.replace(/\s/g, ''));

        setDisplayInputValue(newCardNumber
            .replace(/\D/gi, '')
            .replace(/(.{4})/g, '$1 ').trim());

    }, [setCardInput, setDisplayInputValue]);

    useEffect(() => {
        props.onChange(cardInput);
    }, [cardInput]);

    return (
        <Form.Group className={`form-group ${props.className}`}>
            {props.label &&
                <Form.Label>{props.label}</Form.Label>
            }
            <InputGroup>
                <Form.Control placeholder={props.placeholder} name="InputValue"
                    className={
                        props.error ? "giftcardNumber large error" : "giftcardNumber large"
                    }
                    value={displayInputValue ? LEFT_TO_RIGHT_MARK + displayInputValue : displayInputValue}
                    required
                    type="text"
                    inputMode="numeric"
                    onChange={onInputValueChange}
                    ref={props.inputRef}
                    disabled={props.disabled}
                />

            </InputGroup>

            {props.helpText &&
                <Form.Text className="muted">
                    {props.helpText}
                </Form.Text>}
        </Form.Group>
    );
};

export default CreditCardInput;