import React from "react";

function InfoBox(props) {
    const title = props.title;
    const info = props.info;
    const image = props.image;
    const imageSize = props.imageSize;
    const linkTitle = props.linkTitle;
    const imageTitle = props.imageTitle;
    const link = props.link;
    const imageClass = "is-" + imageSize;

    return (
        <section className="section">
            <div className="content has-light-background">
                <div className="panel-row item-top">
                    {image && (
                        <div className={imageClass}>
                            <img className="img-fluid" src={image} alt={imageTitle} />
                        </div>
                    )}
                    <div>
                        {title && (
                            <p className="mb-1">
                                <strong>{title}</strong>
                            </p>
                        )}
                        <p>
                            {info}
                            {link && (
                                <>
                                    <br />
                                    <a href={link}>{linkTitle}</a>
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoBox;
