import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrandPageProps } from "../../models/BrandPage";

interface ErrorProps extends BrandPageProps {
    returnUrl?: string;
    errorCode?: string;
}
const ErrorPage = (props: ErrorProps) => {
    const { t } = useTranslation();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    return (
        <>
            <section className="section">
                <div className="content">
                    <h1 className="display-2 has-icon">
                        <span className="icon icon-warning__color bg-warning"></span>
                        {t("generic__error_title")}
                    </h1>
                    <p>{t("generic__error_message")}</p>
                    <span className="tag tag__light ms-0">Error: {props.errorCode ? <strong>{props.errorCode}</strong> : <strong>F110</strong>}</span>
                </div>
            </section>
            <section className="section">
                <div className="content">
                    {props.returnUrl &&
                        <a href="/" className="btn btn-light">
                            {t("generic__backButtonLabel")}
                        </a>
                    }
                </div>
            </section>
        </>
    );
};

export default ErrorPage;