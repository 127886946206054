import React, { useEffect, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import { DateInput, AutocompleteInput } from "intersolve.web.componentlib";
import { useTranslation } from "react-i18next";

interface EditableListItemProps {
    type: string;
    label: string | null;
    defaultValue?: any;
    defaultAddress?: any;
    isEditable: boolean;
    isReset: boolean;
    isRequired?: boolean;
    isPartial?: boolean;
    items?: any[];
    selectLabel?: string | null;
    onChange?: (value: any) => void;
}

const EditableListItem = (props: EditableListItemProps) => {
    const [value, setValue] = useState(props.defaultValue);
    const [address, setAddressValue] = useState(props.defaultAddress);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (address === undefined && props.defaultAddress !== undefined)
            setAddressValue(props.defaultAddress);
    }, [address, props.defaultAddress]);

    useEffect(() => {
        if(value === undefined && props.defaultValue !== undefined)
            setValue(props.defaultValue)
    },[props.defaultValue, value])

    const handleInputChange = React.useCallback((event) => {
        setValue(event.target.value);
        if(props.onChange)
            props.onChange(event.target.value);
    }, [props]);

    const handleSelectChange = React.useCallback((value, display) => {
        setValue(value);
        if (props.onChange)
            props.onChange(value);
    }, [props]);

    const handleDateChange = React.useCallback((value) => {
        setValue(value);
        if (props.onChange)
            props.onChange(value);
    }, [props])

    const handleStreetChange = React.useCallback((event) => {
        setAddressValue({ ...address, street: event.target.value });
    }, [address]);

    const handlePostalCodeChange = React.useCallback((event) => {
        setAddressValue({ ...address, postalCode: event.target.value });
    }, [address]);

    const handleCityChange = React.useCallback((event) => {
        setAddressValue({ ...address, city: event.target.value });
    }, [address]);

    const generateSlug = React.useCallback((labelText) => {
        const slug = labelText.toLowerCase().replace(/\s+/g, "_");
        return slug;
    },[]);

    const getAddressDisplayValue = React.useCallback(() => {
        let addressDisplayValue = "";
        if (!address)
            return addressDisplayValue;
        if (address.street)
            addressDisplayValue += address.street;
        if (address.street && (address.postalCode || address.city))
            addressDisplayValue += ", ";
        if (address.postalCode)
            addressDisplayValue += address.postalCode;
        if (address.city)
            addressDisplayValue += address.city;
        return addressDisplayValue;
    }, [address]);

    const getInputField = () => {
        switch (props.type) {
            case "name":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head">{props.label?.toUpperCase()}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your initials and surname"
                            value={value}
                            onChange={handleInputChange}
                            required={props.isRequired}
                        />
                    </Form.Group>
                );
            case "address":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head align-top">
                            {props.label?.toUpperCase()}
                        </Form.Label>
                        <div className="form-subgroup">
                            {/* TODO: this doesn't work when using form autocomplete functions from the browser ...*/}
                            <FormControl
                                type="text"
                                placeholder="Street"
                                name="street"
                                value={address.street}
                                onChange={handleStreetChange}
                                required={props.isRequired}
                            />
                            <div>
                                <FormControl
                                    type="text"
                                    placeholder="Postal Code"
                                    name="zip"
                                    value={address.postalCode}
                                    onChange={handlePostalCodeChange}
                                    required={props.isRequired}
                                />
                                <FormControl
                                    type="text"
                                    placeholder="City"
                                    name="city"
                                    value={address.city}
                                    onChange={handleCityChange}
                                    required={props.isRequired}
                                />
                            </div>
                        </div>
                    </Form.Group>
                );
            case "email":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head">{props.label?.toUpperCase()}</Form.Label>
                        <FormControl
                            type="email"
                            value={value}
                            onChange={handleInputChange}
                            required={props.isRequired}
                        />
                    </Form.Group>
                );
            case "tel":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head">{props.label?.toUpperCase()}</Form.Label>
                        <FormControl
                            type="tel"
                            value={value}
                            onChange={handleInputChange}
                            required={props.isRequired}
                        />
                    </Form.Group>
                );
            case "date":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head">{props.label?.toUpperCase()}</Form.Label>
                        <DateInput onChange={handleDateChange} defaultValue={props.defaultValue}></DateInput>
                    </Form.Group>
                );
            case "select":
                return (
                    <Form.Group
                        controlId={`formName_${generateSlug(props.label)}`}
                        className="form-group"
                    >
                        <Form.Label className="head">{props.label?.toUpperCase()}</Form.Label>
                        <AutocompleteInput items={props.items} label={props.selectLabel} onChange={handleSelectChange} defaultSelectedValue={props.defaultValue} required={props.isRequired} />
                    </Form.Group>
                );
            default:
                return null;
        }
    };

    const getSelectDisplayValue = React.useCallback(() => {
        const selectedItem = props.items?.find((item) => item.value === value);
        return selectedItem?.name;
    },[props.items, value])

    const getDisplayValue = React.useCallback(() => {
        switch (props.type) {
            case "address":
                return getAddressDisplayValue();
            case "select": {
                return getSelectDisplayValue();
            }
            case "date": {
                return value ? new Date(value).toLocaleDateString(i18n.language) : "";
            }
            default:
                return value;
        }
    }, [getAddressDisplayValue, getSelectDisplayValue, i18n.language, props.type, value]);

    return (
        // when form is partion, show the input field, else show the value
        <>
            {props.isEditable && props.isPartial ? (
                <li className="list-group-item is-editable">
                    {getInputField()}
                </li>
            ) : (
                <li className="list-group-item">
                    <span className="head">{props.label?.toUpperCase()}</span>
                        {getDisplayValue()}
                </li>
            )}
        </>
    );
};

export default EditableListItem;
