import React, { useEffect, useState } from "react";
import { Title, Section, LoaderButton } from 'intersolve.web.componentlib';
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEasyToGuess } from "../../utility/securityUtil";
import { BrandPageProps } from "../../models/BrandPage";
import { CreditCard } from "../../models/CreditCard";
import { OTCNumber } from "../../models/OTCNumber";

interface VisaResetSecurityProps extends BrandPageProps{
    creditCard?: CreditCard;
    otcNumber?: OTCNumber;
    onNavigate: (securityCode: string) => void;
}

const VisaResetSecurity = (props: VisaResetSecurityProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [securityCode, setSecurityCode] = useState<string>("");
    const [repeatCode, setRepeatCode] = useState<string>("");
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [messageKey, setMessageKey] = useState("visa__security_passcode_help");
    const [messageClass, setMessageClass] = useState("muted");

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const checkIfSubmitPossible = React.useCallback(() => {
        if (securityCode.length === 6 && securityCode === repeatCode && isValid)
            setSubmitEnabled(true);
        else
            setSubmitEnabled(false);
    }, [isValid, repeatCode, securityCode]);

    const validateCode = React.useCallback((value) => {
        setError(false);
        if (value.length < 6) {
            setMessageClass("muted");
            setMessageKey("visa__security_passcode_help");
            setIsValid(false);
            // when the code is not empty and is not 6 digits long
        } else if (value.length >= 7) {
            setMessageClass("text-danger");
            setMessageKey("visa__security_passcode_error_length");
            setIsValid(false);
        } else if (isEasyToGuess(value)) {
            setMessageClass("text-danger");
            setMessageKey("visa__security_passcode_error_guess");
            setIsValid(false);
        } else if (value.length === 6) {
            setMessageClass("");
            setMessageKey("");
            setIsValid(true);
        }
    }, []);

    const handleSecurityCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setSecurityCode(input);
        validateCode(input);
    }, [validateCode]);

    const handleRepeatCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setRepeatCode(input);
    }, [setRepeatCode]);

    // when the users unfocuses the input, we check if the code is not empty and is not 6 digits long to display the error message
    const handleBlur = React.useCallback((e) => {
        const input = e.target.value;
        validateCode(input);
    }, [validateCode]);

    useEffect(() => {
        if (securityCode && repeatCode)
            checkIfSubmitPossible();
    }, [repeatCode, securityCode, checkIfSubmitPossible]);


    const handleRequestActivationCode = React.useCallback(async () => {
        try {
            const response = await fetch(`/api/visa/userecovery/${props.brand.code}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: props.creditCard?.cardNumber,
                    otcNumber: props.otcNumber?.otcNumber,
                    securityCode: securityCode,
                })
            });
            if (response.ok) {
                props.onNavigate(securityCode);
                navigate(`/${props.brand.code}/visa/account/details`, { replace: true });
            } else {
                if (response.status === 400) {
                    response.json().then((json) => {
                        if (json.code === "SECURITY_CODE_INSECURE") {
                            setError(true);
                            setIsValid(false);
                            setMessageClass("text-danger");
                            setMessageKey("visa__security_passcode_error_guess");
                        }
                        //else
                            //navigate(`/${props.brand.code}/visa/activate/failed`, { replace: true });
                    });
                }
                //else
                    //navigate(`/${props.brand.code}/visa/activate/failed`, { replace: true });
            }
        } catch {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [navigate, props, securityCode]);

    useEffect(() => {
        if (!props.creditCard) {
            navigate(`/${props.brand.code}/visa/account`, { replace: true });
        }
    });

    return (
        props.creditCard ?
            <>
            <Title
                title={t("visa__loginForgotTitle")}
                anchor={"#" + t("visa__content_security_anchor")}
                anchortitle={t("visa__content_security_anchorTitle")}
            ></Title>
            <Section>
                <div className="content form">
                    <p>{t("visa__security_passcode_description")}</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-group" controlId="formSecurityCode">
                            <Form.Label>
                                {t("visa__security_passcode")}
                            </Form.Label>
                            <Form.Control
                                className="large narrow no-spinner"
                                type="password"
                                inputMode="text"
                                pattern="[0-9a-zA-Z]*"
                                placeholder={t("visa__security_passcode_placeholder") ?? ""}
                                value={securityCode}
                                onChange={handleSecurityCodeChange}
                                onBlur={handleBlur}
                            />
                            {messageKey && (
                                <Form.Text className={messageClass}>
                                    {t(messageKey)}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="form-group" controlId="formRepeatCode">
                            <Form.Label>
                                {t("visa__security_passcode_repeat")}
                            </Form.Label>
                            <Form.Control
                                className="large narrow no-spinner"
                                type="password"
                                inputMode="text"
                                pattern="[0-9a-zA-Z]*"
                                placeholder={t(
                                    "visa__security_passcode_repeat_placeholder"
                                ) ?? ""}
                                value={repeatCode}
                                onChange={handleRepeatCodeChange}
                            />
                            {securityCode !== repeatCode && repeatCode.length >= 4 && (
                                <Form.Text className="text-danger">
                                    {t("visa__security_passcode_error_match")}
                                </Form.Text>
                            )}

                            {securityCode === repeatCode && (
                                <Alert variant="warning">
                                    {t("visa__security_passcode_alert_beforeStrong")}
                                    <strong>
                                        {t("visa__security_passcode_alert_strong")}
                                    </strong>
                                    {t("visa__security_passcode_alert_afterStrong")}
                                </Alert>
                            )}
                            </Form.Group>
                            <LoaderButton loading={loading} disabled={!submitEnabled || error}
                                type="submit" className="is-narrow-mobile" onClick={handleRequestActivationCode}
                            >
                                {t("visa__resetPasscode_submit")}
                            </LoaderButton>
                    </Form>
                </div>
            </Section>
            </> :
            <></>
    );
};

export default VisaResetSecurity;
