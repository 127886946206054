import { Layout, DescriptionLabel, Section, Title } from 'intersolve.web.componentlib';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrandPageProps } from '../../models/BrandPage';
import { InformationType } from '../../models/Brand';
import ImageInformationContainer from '../../components/ImageInformationContainer';
import goShoppingImage from "../../static/img/go-shopping.png";
import enterCodeImage from "../../static/img/enter-security-code.png";
import checkTermsImage from "../../static/img/check-terms.png";
import confirmDetailsImage from "../../static/img/confirm-details.png";

interface ReplaceLayoutProps extends BrandPageProps {
    children: any;
}

const ReplaceLayout = (props: ReplaceLayoutProps) => {
    const { i18n } = useTranslation();

    const setLanguage = React.useCallback(async (language: string) => {
        await i18n.changeLanguage(language);
    }, [i18n]);
    const { t } = useTranslation();
    return (
        <Layout
            headerImage={props.brand.styling?.headerImage}
            posterImage={props.brand.styling?.logo}
            addSwoosh={props.brand.styling?.addSwoosh}
            backgroundVariant={props.brand.styling?.backgroundVariant}
            useGradient={props.brand.styling?.useGradient}
            selectedLanguage={i18n.language}
            availableLanguages={props.brand.availableLanguages}
            onLanguageChange={setLanguage}
        >
            <Title title={t('replace__mainTitle')} subTitle={props.brand?.name} anchor="content-1" />
            { props.children }
            <hr />      
            <Section id="hoe-werkt-het">
                <div className="content">
                    <h2 class="display-3">{t('replace__titleBelowContainer')}</h2>
                    <h3 class="display-5 text-muted">{t('replace__textBelowContainer')}</h3>
                </div>
            </Section>
            <ImageInformationContainer imageAlt={'replace__content_explainer_2_imageAlt'} imageSource={enterCodeImage} text={'replace__content_explainer_2_description'} title={'replace__content_explainer_2_title'} />
            <ImageInformationContainer imageAlt={'replace__content_explainer_3_imageAlt'} imageSource={checkTermsImage} text={'replace__content_explainer_3_description'} title={'replace__content_explainer_3_title'} />
            <ImageInformationContainer imageAlt={'replace__content_explainer_4_imageAlt'} imageSource={confirmDetailsImage} text={'replace__content_explainer_4_description'} title={'replace__content_explainer_4_title'} />     
            <ImageInformationContainer imageAlt={'replace__content_explainer_5_imageAlt'} imageSource={goShoppingImage} text={'replace__content_explainer_5_description'} title={'replace__content_explainer_5_title'} />
        </Layout>
    );
};

export default ReplaceLayout;