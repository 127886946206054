import React from "react";
import { useTranslation } from "react-i18next";
import {Section, Panel} from 'intersolve.web.componentlib';

interface BalanceEndSectionProps {
    children?: JSX.Element | JSX.Element[] | string;
    externalAction?: string;
}

const BalanceEndSection = (props: BalanceEndSectionProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Section>
                <div className="content">
                    <Panel>
                        <p className="text-muted">
                            {props.children ?? t("generic__balance_check_end_text")}
                        </p>
                    </Panel>
                    {props.externalAction &&
                        <a href={t("generic__external_action_url") ?? ""} className="btn btn-primary">
                            {t("generic__external_action_linktitle")}
                        </a>
                    }
                </div>
            </Section>
        </>
    );
}
export default BalanceEndSection;