// Check if securityCode contains only repeating characters or sequentially-ascending or descending characters
export const isEasyToGuess = (securityCode: string): boolean => {
    let securityCodeLength = securityCode.length;

    // If there is only 1 character
    if (securityCodeLength < 2) {
        return false;
    }

    let firstChar = securityCode[0];
    let same = true;

    for (let character = 1; character < securityCodeLength; character++) {
        let secondChar = securityCode[character];

        //calculate the difference between the first and second character
        let difference = firstChar.charCodeAt(0) - secondChar.charCodeAt(0);

        if (same) {
            same = difference === 0;
        }

        // If difference between 2 characters in sequence in the string is more then 1 position, the security code is valid, eg: ac / 51
        if ((difference < -1 || difference > 1) && !same)
        {
            return false;
        }

        firstChar = secondChar;
    }

    return true;
};