import React, { ChangeEvent, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { InputProps } from '.';

interface PincodeInputProps extends InputProps {
    value: string|undefined,
    label: string,
    subLabel: string,
    onChange: (value: string | undefined) => void;
    alphaNumerical?: boolean;
}

const PincodeInput: React.FC<PincodeInputProps> = (props: PincodeInputProps) => {
    const handleValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    }, []);

    return (
        <Form.Group className={`form-group ${props.className}`}>
            {props.label &&
                <Form.Label>{props.label}</Form.Label>
            }
            <Form.Control placeholder="XXXXXX" name="pinCode"
                className={props.error ? "pinCode narrow large error" : "pinCode narrow large"}
                disabled={props.disabled }
                value={props.value}
                required
                type="password"
                pattern={props.alphaNumerical ? "[a-zA-Z0-9]*" : "[0-9]*" }
                inputMode={props.alphaNumerical ? "text" : "numeric"}
                onChange={handleValueChange}
            />
            {props.subLabel &&
                <Form.Text className="h9">{props.subLabel}</Form.Text>
            }
        </Form.Group>
    )
}

export default PincodeInput;