import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { Section, DescriptionLabel } from 'intersolve.web.componentlib';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ReplaceCardSuccessProps extends BrandPageProps {
    giftCard?: GiftCard,
    email?: string;
}

const ReplaceCardSuccess = (props: ReplaceCardSuccessProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate()

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/replace`, { replace: true });
        }
    });

    return (
        props.giftCard ?
        <>
            <Section className="content">
                <p>{t('replace__confirmationText')}</p>
            </Section>
            <Section className="content">
                <div className="content has-light-background">
                    <DescriptionLabel as="h4" display={5} id="hoe-werkt-het">{t('replace__titleBelowContainer')}</DescriptionLabel>
                    <hr />
                    <p id="content-1">
                            {t('replace__sentTo') }:<br />
                            {t('replace__email')} {props.email}
                    </p>
                </div>
            </Section>
        </> : <></>
    );
};
export default ReplaceCardSuccess;