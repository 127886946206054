import { Route, Routes, useParams } from "react-router";
import VisaAccount from "../pages/visa/VisaAccount";
import VisaSecurity from "../pages/visa/VisaSecurity";
import VisaCheck from "../pages/visa/VisaCheck";
import VisaLayout from "../pages/visa/VisaLayout";
import VisaOtc from "../pages/visa/VisaOtc";
import VisaReviewInfo from "../pages/visa/VisaReviewInfo";
import VisaSuccess from "../pages/visa/VisaSuccess";
import { useCallback, useEffect, useState } from "react";
import { CreditCard } from "../models/CreditCard";
import { OTCNumber } from "../models/OTCNumber";
import VisaFailed from "../pages/visa/VisaFailed";
import { Brand } from "../models/Brand";
import { Helmet } from "react-helmet-async";
import { CardHolderInformation } from "../models/CardHolderInformation";
import VisaAccountLogin from "../pages/visa/VisaAccountLogin";
import VisaPin from "../pages/visa/VisaPin";
import ErrorBoundary from "../components/ErrorBoundary";
import VisaForgot from "../pages/visa/VisaForgot";
import VisaResetSecurity from "../pages/visa/VisaResetSecurity";
import VisaForgotOtc from "../pages/visa/VisaForgotOtc";
import { OtcRequestResult } from "../models/OtcRequestResult";

export const VisaRoutes = () => {
    const [brand, setBrand] = useState<Brand>();

    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(() => {
        fetch(`/api/brand/${brandId}`)
            .then((res) => res.json())
            .then((res) => setBrand(res));
    }, [setBrand, brandId]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <VisaLayout brand={brand}>
                    <ErrorBoundary>
                        <VisaAccountRoutes brand={brand} />
                        <VisaActivateRoutes brand={brand} />
                    </ErrorBoundary>
                </VisaLayout>
            }
        </>
    );
};

interface VisaRoutesProps {
    brand: Brand;
}

export const VisaAccountRoutes = (props: VisaRoutesProps) => {
    const [creditCard, setCreditCard] = useState<CreditCard>();
    const [holderInformation, setHolderInformation] = useState<CardHolderInformation>();
    const [otcNumber, setOtcNumber] = useState<OTCNumber>();
    const [otcRequestResult, setOtcRequestResult] = useState<OtcRequestResult>();
    const [passwordReset, setPasswordReset] = useState<boolean>(false);

    const onForgotNavigate = useCallback((creditCard: CreditCard, activationCodeResult: OtcRequestResult) => {
        setCreditCard(creditCard);
        setOtcRequestResult(activationCodeResult);
    }, [setCreditCard]);

    const onOtcNavigate = useCallback((otcNumber: OTCNumber) => {
        setOtcNumber(otcNumber);
    }, [setOtcNumber]);

    const getHolderInformation = useCallback(async (creditCard: CreditCard) => {
        const response = await fetch(`/api/visa/GetHolderInformation/${props.brand?.code}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cardNumber: creditCard.cardNumber,
                securityCode: creditCard.securityCode,
            })
        });

        if (response.ok) {
            const data = await response.json();
            setHolderInformation(data);
        }

    }, [props.brand?.code]);

    const onLoginSuccess = useCallback((creditCard: CreditCard) => {
        setCreditCard(creditCard);

        getHolderInformation(creditCard)
            .catch((e) => console.error(e));
    }, [getHolderInformation]);

    const onSecurityNavigate = useCallback((securityCode: string) => {
        if (creditCard) {
            let cc = creditCard;
            cc.securityCode = securityCode;
            setCreditCard(cc);
            setPasswordReset(true);
            getHolderInformation(cc)
                .catch((e) => console.error(e));
        }

    }, [creditCard, getHolderInformation]);

    return (
        <Routes>
            <Route
                path="account/reset"
                element={<VisaForgot brand={props.brand} onNavigate={onForgotNavigate} />}
            />
            <Route
                path="account/reset/otc"
                element={<VisaForgotOtc brand={props.brand} creditCard={creditCard} onNavigate={onOtcNavigate} otcRequestResult={otcRequestResult } />}
            />
            <Route
                path="account/reset/security"
                element={<VisaResetSecurity brand={props.brand} creditCard={creditCard} otcNumber={otcNumber} onNavigate={onSecurityNavigate} />}
            />
            <Route
                path="account/details"
                element={<VisaAccount brand={props.brand} holderInformation={holderInformation} creditCard={creditCard} passwordReset={passwordReset } />}
            />
            <Route
                path="account"
                element={<VisaAccountLogin brand={props.brand} onNavigate={onLoginSuccess} />}
            />
        </Routes>
    );
};

export const VisaActivateRoutes = (props: VisaRoutesProps) => {
    const [creditCard, setCreditCard] = useState<CreditCard>();
    const [otcNumber, setOtcNumber] = useState<OTCNumber>();
    const [activationCodeResult, setActivationCodeResult] = useState<any>();
    const [holderInformation, setHolderInformation] = useState<CardHolderInformation>();

    const onCreditCardNavigate = useCallback((creditCard: CreditCard, activationCodeResult: any) => {
        setCreditCard(creditCard);
        setActivationCodeResult(activationCodeResult);
    }, [setCreditCard]);

    const onOtcNavigate = useCallback((otcNumber: OTCNumber) => {
        setOtcNumber(otcNumber);
    }, [setOtcNumber]);

    const getHolderInformation = useCallback(async (cardNumber, securityCode) => {
        const response = await fetch(`/api/visa/GetHolderInformation/${props.brand?.code}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cardNumber: cardNumber,
                securityCode: securityCode,
            })
        });

        if (response.ok) {
            const data = await response.json();
            setHolderInformation(data);
        }

    }, [props.brand?.code]);

    const onSecurityNavigate = useCallback((securityCode: string) => {
        setCreditCard({ ...creditCard!, securityCode: securityCode });
        getHolderInformation(creditCard?.cardNumber, securityCode)
            .catch((e) => console.error(e));
    }, [creditCard, getHolderInformation]);

    return (
        <Routes>
            <Route
                path="activate/otc"
                element={<VisaOtc brand={props.brand} creditCard={creditCard} otcRequestResult={activationCodeResult} onNavigate={onOtcNavigate} />}
            />
            <Route
                path="activate/failed"
                element={<VisaFailed brand={props.brand} creditCard={creditCard} />}
            />
            <Route
                path="activate/security"
                element={<VisaSecurity brand={props.brand} creditCard={creditCard} otcNumber={otcNumber} onNavigate={onSecurityNavigate} />}
            />
            <Route
                path="activate/review"
                element={<VisaReviewInfo brand={props.brand} creditCard={creditCard} holderInformation={holderInformation} />}
            />
            <Route
                path="activate/pin"
                element={<VisaPin brand={props.brand} creditCard={creditCard } />}
            />
            <Route
                path="activate/success"
                element={<VisaSuccess brand={props.brand} creditCard={creditCard} holderInformation={holderInformation} />}
            />
            <Route
                path="activate"
                element={<VisaCheck brand={props.brand} onNavigate={onCreditCardNavigate} />}
            />
        </Routes>
    );
};