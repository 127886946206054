import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Title, Section, Panel } from 'intersolve.web.componentlib';
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";

//TODO: Temporary Error Boundary handler. Replace with better solution later (or improve upon this version)
class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorId: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    logErrorToMyService = async (error, info) => {
        const id = uuidv4();
        this.setState({ errorId: id })
        const url = `/api/Utility/Error`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(
                {
                    id: id,
                    code: "REACT_COMPONENT_ERROR",
                    message: error.message,
                    info: info
                })
        });
    };

    componentDidCatch(error, info) {
        this.logErrorToMyService(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Title
                        title={this.props.t('visa__accountTitle')}
                        subTitle={this.props.t('visa__cardName')}
                        anchor={this.props.t('visa__explanationAnchor')}
                        anchortitle={this.props.t('visa__explanationAnchorTitle')}
                    ></Title>
                    <Section>
                        <div className="content">
                            <Alert variant="danger">
                                {this.props.t('generic__error_message')}
                            </Alert>
                        </div>
                        <div className="content">
                            <div>{this.props.t('generic__error_code_description') }</div>
                            <Panel muted>{this.state.errorId}</Panel>
                        </div>
                    </Section>
                </>);
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);