import { Navigate, useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { Section, DescriptionLabel } from 'intersolve.web.componentlib';
import React from "react";
import { t } from 'i18next';
import { Customer } from "../../models/Customer";
interface IdentifyFaliedViewProps extends BrandPageProps {
    giftCard?: GiftCard,
    customer?: Customer,
    email?: string;
    cardNumber?: string;
}
const IdentifyFailed = (props: IdentifyFaliedViewProps) => {
    const navigate = useNavigate();
    const handleStartNewidentification = React.useCallback((e: React.MouseEvent) => {
        navigate(`/${props.brand.code}/identify/`);
    }, [navigate, props.brand.code]);


    if (!props.giftCard)
        return <Navigate to={`/${props.brand.code}/identify`} replace />;

    return (
        props.giftCard ?
            <>
                <Section>
                    <div className="content">                                   
                        <div className="content">
                            <div className="panel">
                                <div className="panel-row">
                                    <div className="icon-cancel-circle__color bg-danger icon-md"></div>
                                    <div className="panel">
                                        <div className="label">{t("identify__fail_transaction")}</div>
                                        <div className="bigger">
                                            <span className="muted">{props.cardNumber?.replace(/[^a-z0-9]/gi, '')
                                                .replace(/(.{4})/g, '$1 ').trim()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <p>{t("identify__fail_description")}</p>
                            </div>
                        </div>
                    </div>
                </Section>

                <Section>
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={handleStartNewidentification}>
                            {t('generic__startAgainButtonLabel')}
                        </button>
                    </div>
                </Section>

            </> :
            <>  </>
    );
};


export default IdentifyFailed;

