import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Section, Title } from "intersolve.web.componentlib";
import PincodeDisplay from "../../components/Visa/PincodeDisplay";
import { BrandPageProps } from "../../models/BrandPage";
import { CreditCard } from "../../models/CreditCard";
import { useNavigate } from "react-router-dom";

interface VisaPinProps extends BrandPageProps {
    creditCard?: CreditCard;
}

function VisaPin(props: VisaPinProps) {
    const [pinLoaded, setPinLoaded] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    const onPinLoaded = React.useCallback(() => {
        setPinLoaded(true);
    }, []);

    const onButtonClick = React.useCallback(() => {
        navigate(`/${props.brand.code}/visa/activate/success`, { replace: true });
    }, [navigate, props.brand.code]);

    useEffect(() => {
        if (!props.creditCard) {
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
        }
    });

    return (
        props.creditCard ?
            <>
                <Title
                    title={t("visa__show_pin_title")}
                    subtitle={t("visa__show_pin_subtitle")}
                ></Title>
                <Section className="section" id={t("visa__activatedAnchor")}>
                    <div className="content">
                        <div className="box has-light-background">
                            <PincodeDisplay
                                brand={props.brand}
                                title={t("visa__get_pin_title")}
                                description={t("visa__get_pin_description")}
                                creditCard={props.creditCard}
                                onPinLoaded={onPinLoaded}
                            />
                        </div>
                    </div>
                </Section>
                <section className="section">
                    <div className="content">
                        <button className="btn btn-primary" onClick={onButtonClick} disabled={!pinLoaded}>
                            {t("generic__check_balance_button")}
                        </button>
                    </div>
                </section>
            </> :
            <></>
    );
}

export default VisaPin;
