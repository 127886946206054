import React from "react";

export interface LoaderProps {
    size?: 'sm' | 'md' | 'lg';
}
function Loader(props : LoaderProps) {
    // add a prop that allows you to change the size of the loader, sm, md, lg
    const size = props.size || "sm";

    return (
        <div className={`loading-ellipsis ${size}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Loader;