import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RedirectCallbackHandler from './components/RedirectCallbackHandler';
import { VisaRoutes } from './routes/VisaRoutes';
import { useTranslation } from 'react-i18next';
import { ReplaceRoutes } from './routes/ReplaceRoutes';
import {PayOutRoutes} from './routes/PayOutRoutes';
import { BalanceRoutes } from './routes/BalanceRoutes';
import { IdentifyRoutes } from './routes/IdentifyRoutes';
import { ResetRoutes } from './routes/ResetRoutes';
import AboutPage from './pages/generic/About';

const App = () => {
    const { i18n } = useTranslation();
    document.documentElement.setAttribute('lang', i18n.language);
    document.body.dir = i18n.dir(i18n.language);

    return (
        <BrowserRouter>
            <Routes>
                <Route path=":id/reset/*" element={ <ResetRoutes /> } />
                <Route path=":id/balance/*" element={<BalanceRoutes />} />
                <Route path=":id/visa/*" element={<VisaRoutes />} />
                <Route path=":id/replace/*" element={<ReplaceRoutes />} />
                <Route path=":id/payout/*" element={<PayOutRoutes />} />
                <Route path=":id/Identify/*" element={<IdentifyRoutes />} />
                <Route path="/callback" element={<RedirectCallbackHandler />} />
                <Route path="/version" element={<AboutPage />} /> 
            </Routes>
        </BrowserRouter>
    );
};

export default App