import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { InputProps } from ".";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

interface DateInputProps extends InputProps {
    dayPlaceholder?: string;
    monthPlaceholder?: string;
    yearPlaceholder?: string;
    onChange?: (value: any) => void;
    defaultValue?: string;
}

const DateInput = (props: DateInputProps) => {
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    const handleDateChange = React.useCallback((event: any) => {
        setDate(event.target.value);
    },[]);

    const handleMonthChange = React.useCallback((event: any) => {
        setMonth(event.target.value);
    }, []);

    const handleYearChange = React.useCallback((event: any) => {
        setYear(event.target.value);
    }, []);

    useEffect(() => {
        if (props.defaultValue !== undefined && !date && !month && !year) {
            let fullDate = new Date(props.defaultValue);
            setDate(fullDate.getDate().toString());
            setMonth((fullDate.getMonth() + 1).toString());
            setYear(fullDate.getFullYear().toString());
        }
    },[props.defaultValue, date, month, year])

    useEffect(() => {
        if (date && month && year && props.onChange) {
            props.onChange(`${year}-${month.length === 1 ? "0" + month : month}-${date.length === 1 ? "0" + date : date}`);
        }
    }, [date, month, year])

    return (
        <div className="date-selector">
            <Form.Select value={date} onChange={handleDateChange} placeholder="Date">
                <option value="">{props.dayPlaceholder}</option>
                {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                    <option key={day} value={day}>
                        {day}
                    </option>
                ))}
            </Form.Select>

            <Form.Select
                value={month}
                onChange={handleMonthChange}
                placeholder="Month"
            >
                <option value="">{props.monthPlaceholder}</option>
                {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
                    <option key={month} value={month}>
                        {month}
                    </option>
                ))}
            </Form.Select>

            <Form.Select value={year} onChange={handleYearChange} placeholder="Year">
                <option value="">{props.yearPlaceholder}</option>
                {Array.from(
                    { length: currentYear - 1919 },
                    (_, index) => currentYear - index
                ).map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
};

export default DateInput;
