import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

interface RecaptchaProps {
    onSetRecaptchaToken: (token: string | null | undefined) => void;
    tokenUrl: string;
}

const Recaptcha = (props: RecaptchaProps) => {
    const [recaptchaKey, setRecaptchaKey] = useState<string>();

    const getRecaptchaKey = React.useCallback(() => {
        fetch(props.tokenUrl)
            .then((res) => res.json())
            .then((res) => {
                setRecaptchaKey(res.key);
            })
            .catch((e) => { console.error("something went wrong fetching the recaptcha"); });
    }, [props.tokenUrl]);

    useEffect(() => {
        getRecaptchaKey();
    }, [getRecaptchaKey]);

    const onRecaptchaChange = React.useCallback((token: string | null) => {
        props.onSetRecaptchaToken(token);
    }, [props]);


    return (
        <Form.Group className="form-group">
            <input type="text" id="name" className="input" hidden />

            {recaptchaKey &&
                <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={onRecaptchaChange}
                    className="recaptcha"
                />
            }
        </Form.Group>
    )
};

export default Recaptcha;