import * as React from 'react';
import { Container, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

interface LayoutProps extends HeaderProps {
    children?: JSX.Element | JSX.Element[] | string;
    useGradient?: boolean;
    addSwoosh?: boolean;
    backgroundVariant?: string;
}

const Layout = (props: LayoutProps) => {
    // Set body styling for background
    // [BRAND BACKGROUND] Brand can set background image, which is an svg or image...
    document.body.classList.add("page");
    if (props.useGradient) document.body.classList.add("gradient__linear");
    if (props.backgroundVariant) document.body.classList.add(`bg__${props.backgroundVariant}`);

    // FUNCTION for vertical center when content in higher than viewport on resize and on location change
    // ---------------------------------------------------------
    const location = useLocation();
    const innerContainerRef = React.useRef<HTMLDivElement>(null);
    const [shouldCenterVertically, setShouldCenterVertically] =
        React.useState(true);

    const swooshClass = props.addSwoosh ? 'swoosh background-image' : '';


    React.useEffect(() => {
        function handleResize() {
            if (innerContainerRef.current) {
                const innerContainerHeight = innerContainerRef.current.clientHeight;
                const viewportHeight = window.innerHeight;
                setShouldCenterVertically(innerContainerHeight <= viewportHeight);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [location]);
    // END ------------------------------------------------------

    return (
        <div className={`main ${swooshClass}`}>
            <div className={shouldCenterVertically ? "v-center" : ""}>
                <Container ref={innerContainerRef}>
                    <div className="box__main has-shadow">
                        <Header {...props} />
                        {props.children}
                    </div>
                </Container>
            </div>
        </div>
    );
};

interface HeaderProps {
    headerImage?: string;
    headerPosition?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'
    posterImage?: string;
    selectedLanguage?: string;
    availableLanguages?: string[];
    onLanguageChange: (language: string) => void;
}

const Header = (props: HeaderProps) => {
    const switchLanguage = React.useCallback((eventKey: any, event: Object) => {
        const newLanguage = eventKey.toLowerCase();
        if (props.availableLanguages?.includes(newLanguage)) {
            props.onLanguageChange(newLanguage);
        }
    }, []);

    return (
        <>
            <div className="header-wrapper">
                {props.availableLanguages && props.availableLanguages.length > 1 &&
                    <Dropdown className="dropdown-language" onSelect={switchLanguage}>
                        <Dropdown.Toggle variant="light" id="language-dropdown">
                            {/* language with uppercase */}
                            {props.selectedLanguage?.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* make a list of dropdown items from available languages */}
                            {props.availableLanguages.map((language) => (
                                <Dropdown.Item key={language} eventKey={language}>
                                    {language.toUpperCase()}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <div className="header has-background has-poster">
                    <img
                        src={props.headerImage}
                        alt="Heading"
                        className={`background-image header-image ${props.headerPosition ?? ""}`}
                    />
                </div>
            </div>
            <div className="poster is-floating">
                <img
                    src={props.posterImage}
                    alt="Card as poster image"
                    className="background-image"
                />
            </div>
            <div className="corner-style"></div>
        </>
    );
};

export default Layout;