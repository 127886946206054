﻿import { Html5Qrcode } from "html5-qrcode";
import { Html5QrcodeError, Html5QrcodeResult } from "html5-qrcode/esm/core";
import React, { useEffect } from 'react';

import './scanner.scss';

interface ScannerProps {
    fps: number;
    qrbox: { width: number, height: number; };
    disableFlip: boolean;
    rememberLastUsedCamera: boolean;
    scanSuccessFallback: (decodedText: string, decodedResult: Html5QrcodeResult) => void;
    scanFailureFallback?: (errorMessage: string, decodedResult: Html5QrcodeError) => void;
    isScanning: boolean
}

export const CodeScanner = (props: ScannerProps) => {
    const regionId = "scanner-region";
    let scannerInitialized = false;
    const createConfig = () => {
        return {
            fps: props.fps,
            disableFlip: props.disableFlip,
            rememberLastUsedCamera: false,
            aspectRatio: 1.0
        };
    };

    useEffect(() => {
        if (props.isScanning) {
            const config = createConfig();
            let scanner: undefined | Html5Qrcode;
            if (!scannerInitialized) {
                scanner = new Html5Qrcode(regionId);
                scanner.start({ facingMode: "environment" }, config, props.scanSuccessFallback, props.scanFailureFallback);
                scannerInitialized = true;
            }

            return () => {
                if (scanner !== undefined && scanner.isScanning)
                    scanner.stop();
            };
        }
    }, [props.isScanning]);

    if(props.isScanning)
        return (
            <div className="cameraWrapper">
                {props.isScanning &&
                    <div id={regionId} className="camera" />
                }
            </div>
        );

    return <React.Fragment/>
};