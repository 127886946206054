import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Title, Section, Panel } from "intersolve.web.componentlib";
import CardBalance from "../../components/CardBalance";
import { CreditCard } from "../../models/CreditCard";
import { useNavigate } from "react-router-dom";
import { BrandPageProps } from "../../models/BrandPage";
import { CardHolderInformation } from "../../models/CardHolderInformation";
import PincodeDisplay from "../../components/Visa/PincodeDisplay";

interface VisaSuccessProps extends BrandPageProps {
    creditCard?: CreditCard;
    holderInformation?: CardHolderInformation;
}

function VisaSuccess(props: VisaSuccessProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    useEffect(() => {
        if (!props.creditCard) {
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
        }
    });

    return (
        props.creditCard ?
            <>
                <Title
                    title={t("visa__activated_title")}
                    subtitle={t("visa__activated_subtitle")}
                ></Title>
                <Section>
                    <div className="content">
                        <Panel>
                            <p>
                                {t("visa__activated_text")}
                            </p>
                        </Panel>
                    </div>
                </Section>
                <CardBalance
                    balance={props.holderInformation?.balance}
                    valid={t("visa__activated_valid")}
                    currency={props.holderInformation?.currency}
                    tag_type="success"
                    tag_title={t("generic__tagActive")}
                    card_title={t("visa__cardnumber")}
                    balance_title={t("generic__balance")}
                    boxed={false}
                    creditCard={props.creditCard}
                />
                <Section>
                    <div className="content">
                        <div className="box has-light-background">
                            <PincodeDisplay
                                brand={props.brand}
                                title={t("visa__get_pin_title")}
                                description={t("visa__get_pin_description")}
                                creditCard={props.creditCard}
                            />
                        </div>
                    </div>
                </Section>
                <Section>
                    <div className="content">
                        <p>
                            <small>
                                <em>{t("generic__close_browser_hint")}</em>
                            </small>
                        </p>
                    </div>
                </Section>
            </> :
            <></>
    );
}

export default VisaSuccess;
