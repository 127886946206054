import React, { useId } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface InformationIconTooltipProps {
    textKey: string;
    hintTextKey: string;
}

// To make sure the component can be used multiple times on the same page
// we need to generate a unique id for each instance of the component
// This is done by encoding the id with base64

const base64Encode = (str: string) => {
    return btoa(str).replace(/=/g, ''); // Remove padding characters
};

const InformationIconTooltip: React.FC<InformationIconTooltipProps> = ({
    textKey,
    hintTextKey,
}) => {
    const { t } = useTranslation();
    const uniqueId = useId();
    const base64Id = `info-tooltip__${base64Encode(uniqueId)}`;

    const infoTooltip = (
        <Tooltip id={base64Id}>{t(hintTextKey)}</Tooltip>
    );

    return (
        <OverlayTrigger placement="top" overlay={infoTooltip}>
            <span className="information-icon-tooltip">
                {t(textKey)}{" "}
                <span className="icon icon-info-circle__color bg-info"></span>
            </span>
        </OverlayTrigger>
    );
};

export default InformationIconTooltip;