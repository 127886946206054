import React from 'react';
import { LabelProps } from '.';
import { ToolTip } from '../CustomTooltip';
import { ToolTipProps } from '../CustomTooltip/ToolTip';

interface DescriptionLabelProps extends LabelProps{
    display: number,
    muted?: boolean,
    as?: React.ElementType,
    addToolTip?: boolean;
    toolTip?: {
        props: ToolTipProps;
        enabled: boolean;
        type: "icon" | "subtext";
    };
    visible?: boolean;
}

const DescriptionLabel = (props: DescriptionLabelProps) => {

    const { visible = true } = props; 
 
    if (!visible) {
        return null;
    }

    const classNames = [
        props.display ? `display-${props.display}` : "",
        props.muted ? "text-muted" : "",
        props.className? props.className : ""
    ].join(" ").replace(/\s\s+/g, ' ').trim();

    const Component = props.as ? props.as : "label";

    return (
        <Component
            className={classNames}
        >
            {props.children}
            {
                props.toolTip?.enabled && props.toolTip.type === "icon" &&
                <ToolTip {...props.toolTip.props}></ToolTip>
            }
        </Component>
    );
};

export default DescriptionLabel;