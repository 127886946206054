import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { ToolTip } from '../CustomTooltip';
import { ToolTipProps } from '../CustomTooltip/ToolTip';
import { InputProps } from '.';

interface DefaultInputprops extends InputProps {
    label: string,
    subLabel: string,
    onChange: (value: string | undefined) => void,
    inputRef: RefObject<HTMLInputElement>;  
    addToolTip?: boolean;
    toolTip?: {
        props: ToolTipProps;
        enabled: boolean;
        type: "icon" | "subtext";
    };
    placeholder?: string,
}

const DefaultInput: React.FC<DefaultInputprops> = (props: DefaultInputprops) => {
    const [cardInput, setCardInput] = useState<string>();
    const [displayInputValue, setDisplayInputValue] = useState<string>();

    const onInputValueChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const newCardInput = event.target.value;
        setCardInput(newCardInput);

        setDisplayInputValue(newCardInput);
        
    }, [setCardInput, setDisplayInputValue]);

    useEffect(() => {
        props.onChange(cardInput);
    }, [cardInput]);

    return (
        <Form.Group className={`form-group ${props.className}`}>        
        
            {props.label &&
                <React.Fragment>
                    <Form.Label>{props.label}</Form.Label>

                    {props.toolTip?.enabled && props.toolTip.type === "icon" &&
                        <ToolTip {...props.toolTip.props}></ToolTip>
                    }
                </React.Fragment>
            }
            <InputGroup>
                <Form.Control placeholder={props.placeholder} name="InputValue"
                    className={
                        props.error ? "InputValue large error" : "InputValue large"
                    }
                    value={displayInputValue}
                    required
                    type="text" 
                    inputMode="text"
                    onChange={onInputValueChange}
                    ref={props.inputRef}
                    aria-describedby="captureButton"
                    disabled={props.disabled}
                />             
              
            </InputGroup>
            <small className="form-text">{props.subLabel}</small>
        </Form.Group>
    );
};

export default DefaultInput;