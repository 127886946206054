import React, { useState, useCallback, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { BalanceGiftCard, GiftCard } from "../models/GiftCard";
import Error from '../pages/generic/Error';

import { CardInfo } from '../models/CardInfo';
import ReplaceCardEmail from '../pages/Replace/ReplaceCardEmail';
import ReplaceCardSuccess from '../pages/Replace/ReplaceCardSuccess';
import ReplaceCardView from '../pages/Replace/ReplaceCardView';
import { Helmet } from 'react-helmet-async';
import { Brand } from '../models/Brand';
import ReplaceLayout from '../pages/Replace/ReplaceLayout';

export const ReplaceRoutes = () => {
    const [brand, setBrand] = useState<Brand>();

    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(() => {
        fetch(`/api/brand/${brandId}`)
            .then((res) => res.json())
            .then((res) => setBrand(res));
    }, [setBrand, brandId]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <ReplaceLayout brand={brand}>
                    <ReplaceRouteSet brand={brand} />
                </ReplaceLayout>
            }
        </>
    );
};


interface ReplaceRouteProps {
    brand: Brand;
}

const ReplaceRouteSet = (props: ReplaceRouteProps) => {
    const [giftCard, setGiftCard] = useState<BalanceGiftCard>();
    const [securityCode, setSecurityCode] = useState<CardInfo>();
    const [email, setEmail] = useState<string>();

    const onNavigateGiftCardView = useCallback((giftCard: GiftCard, securityCode: CardInfo) => {
        setGiftCard(giftCard as BalanceGiftCard);
        setSecurityCode(securityCode);
    }, [setGiftCard, setSecurityCode]);
    const onNavigateEmailView = useCallback((email: string) => {
        setEmail(email);
    }, [setEmail]);
    return (
        <>
            <Routes>
                <Route>
                    <Route path="email" element={<ReplaceCardEmail giftCard={giftCard} onNavigate={onNavigateEmailView} cardInfo={securityCode} brand={props.brand} />} />
                    <Route path="success" element={<ReplaceCardSuccess giftCard={giftCard} email={email} brand={props.brand} />} />
                    <Route path="error" element={<Error brand={props.brand} />} />
                    <Route path="" element={<ReplaceCardView brand={props.brand} onNavigate={onNavigateGiftCardView} />} />
                </Route>
            </Routes>
        </>
    );

};

