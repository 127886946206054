import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export interface OtcInputProps {
    label?: string;
    helpText?: string;
    errorText?: string;
    onChange: (value: string) => void;
    type: string;
    numberOfFields: number;
}

const OtcInput: React.FC<OtcInputProps> = (props: OtcInputProps) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const [inputValues, setInputValues] = useState(Array(props.numberOfFields).fill(""));

    const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        if (value.length <= 1) {
            const newInputValues = [...inputValues];
            newInputValues[index] = value;
            setInputValues(newInputValues);

            if (value.length === 0 && index > 0) {
                inputRefs.current[index - 1].focus();
                inputRefs.current[index - 1].select();
            } else if (index < props.numberOfFields - 1 && value.length === 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    }, [props.numberOfFields, inputValues]);

    const handleInputPaste = React.useCallback((event : React.ClipboardEvent) => {
        event.preventDefault();
        const pastedValue = event.clipboardData.getData("text/plain");
        const newInputValues = pastedValue
            .split("")
            .slice(0, inputValues.length)
            .map((char) => parseInt(char, 10) || 0);

        setInputValues(newInputValues);
        inputRefs.current[inputValues.length - 1].focus();
    }, [inputValues.length]);

    const handleInputKeyDown = React.useCallback((event : React.KeyboardEvent, index: number) => {
        if (event.key === 'Backspace' && inputValues[index] === '') {
            // Move to the previous field and delete its value
            const previousIndex = index === 0 ? props.numberOfFields - 1 : index - 1;
            const newInputValues = [...inputValues];
            newInputValues[previousIndex] = '';
            setInputValues(newInputValues);
            inputRefs.current[previousIndex].focus();
        } else if (event.key.toLowerCase() === 'e') {
            event.preventDefault(); // Prevent the input of the letter 'e' because it is part of the number spec.
        }
    }, [props.numberOfFields, inputValues]);

    useEffect(() => {
        const otcValue  = inputValues.join('');
        props.onChange(otcValue);
    }, [inputValues]);

    return (
        <Form.Group className="form-group" controlId="formCard" dir="ltr">
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <InputGroup className="otc">
                {inputValues.map((value, index) => {
                    return (
                        <SingleOtcInput
                            key={`formCardInput_${index.toString()}`}
                            onChange={handleInputChange}
                            onPaste={handleInputPaste}
                            onKeyDown={handleInputKeyDown}
                            type={props.type}
                            index={index}
                            value={value}
                            ref={(el: HTMLInputElement) => (inputRefs.current[index] = el)}
                            error={props.errorText !== undefined }
                        />
                    );
                })}
            </InputGroup>
            {props.helpText && <Form.Text className="muted">{props.helpText}</Form.Text>}
            {props.errorText && <Form.Text className="text-danger mt-2">{props.errorText}</Form.Text>}
        </Form.Group>
    );
}

interface SingleOtcInputProps {
    type: string;
    index: number;
    value: string | number;
    onPaste: (event : React.ClipboardEvent, index : number) => void;
    onChange: (event : ChangeEvent<HTMLInputElement>, index : number) => void;
    onKeyDown: (event: React.KeyboardEvent, index: number) => void;
    error?: boolean;
}

const SingleOtcInput = React.forwardRef<HTMLInputElement, SingleOtcInputProps>((props: SingleOtcInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const [inputClass, setInputClass] = useState("large");
    const handleInputChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event, props.index);
    }, [props]);

    const handleInputPaste = React.useCallback((event: React.ClipboardEvent) => {
        props.onPaste(event, props.index);
    }, [props]);

    const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
        props.onKeyDown(event, props.index);
    }, [props]);

    useEffect(() => {
        setInputClass(props.error ? "large error" : "large")
    },[props.error])

    return (
        <Form.Control
            type={props.type ? "number" : "text"}
            value={props.value}
            maxLength={1}
            placeholder={props.type ? "0" : "X"}
            inputMode={props.type ? "numeric" : "text"}
            className={inputClass}
            onChange={handleInputChange}
            onPaste={handleInputPaste}
            onKeyDown={handleKeyDown}
            ref={ref}
        />
    );
});

export default OtcInput;