import React, { useState, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { BrandPageProps } from '../../models/BrandPage';
import BalanceGiftCardViewCheck from '../../pages/balance/BalanceGiftCardViewCheck';
import { GiftCard } from '../../models/GiftCard';


function isIFrame(): boolean {
    try {
        return window.self !== window.top;
    } catch {
        return true;
    }
}

const BalanceCheckIFrame = (props: BrandPageProps) => {
    const [giftCard, setGiftCard] = useState<GiftCard>();

    const onNavigateGiftCardView = useCallback((giftCard: GiftCard) => {
        setGiftCard(giftCard);

    }, [setGiftCard]);
    return (
        isIFrame() ?
            <Container fluid>
                <BalanceGiftCardViewCheck onNavigate={onNavigateGiftCardView} brand={props.brand} />
            </Container>
            :
            <React.Fragment />
    );
};

export default BalanceCheckIFrame;