import { Layout } from 'intersolve.web.componentlib';
import { BrandPageProps } from '../../models/BrandPage';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface PayOutLayoutProps extends BrandPageProps {
    children: any;
}
const PayOutLayOutPage = (props: PayOutLayoutProps) => {
    const { i18n } = useTranslation();

    const setLanguage = React.useCallback(async (language: string) => {
        await i18n.changeLanguage(language);
    }, [i18n]);

    return (
        <Layout
            headerImage={props.brand.styling?.headerImage}
            posterImage={props.brand.styling?.logo}
            addSwoosh={props.brand.styling?.addSwoosh}
            backgroundVariant={props.brand.styling?.backgroundVariant}
            useGradient={props.brand.styling?.useGradient}
            selectedLanguage={i18n.language}
            availableLanguages={props.brand.availableLanguages}
            onLanguageChange={setLanguage}
        >
            {props.children}
        </Layout>
    );
};

export default PayOutLayOutPage;