import { BrandPageProps } from "../../models/BrandPage";
import { PayoutGiftCard } from "../../models/GiftCard";
import { DescriptionLabel, Section, LoaderButton, SelectInput, handleClickScroll, Title } from 'intersolve.web.componentlib';
import { useCallback, useEffect, useState } from "react";
import { Provider } from "../../models/Provider";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import PayoutPricingTable from "../../components/payout/PayoutPricingTable";
import idinLogo from "../../static/graphics/idin-logo.svg";
import PayoutEndSection from "../../components/payout/PayoutEndSection";

interface PayOutAmountViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    securityCode?: string,
}

const PayOutCard = (props: PayOutAmountViewProps) => {
    const [providers, setProviders] = useState<Provider[]>([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isBalanceSufficient, setIsBalanceSufficient] = useState<boolean>(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchProviders = async () => {
            const response = await fetch(`/api/Authentication/${props.brand.code}/providers`);
            if (response.ok) {
                const data = await response.json();
                setProviders(data);
            }
        };

        fetchProviders();
    }, [props.brand.code]);

    const handleCreateSession = useCallback(() => {
        const state = uuidv4();
        localStorage.setItem(state, JSON.stringify({ url: `/${props.brand.code}/payout/callback`, giftCard: props.giftCard, securityCode: props.securityCode })); //TODO HACK fix this very ugly hack.

        const request = {
            provider: 'IDIN',
            issuer: selectedValue,
            redirectUrl: `${window.location.origin}/callback`,
            state: state
        };
        createSession(props.brand.code, request);
    }, [props.brand.code, props.giftCard, props.securityCode, selectedValue]);

    const createSession = async (brandId, request) => {
        const response = await fetch(`/api/Authentication/${brandId}/session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });
        if (response.ok) {
            const data = await response.json();
            const urlParams = data.authorizationUrl;
            window.location.href = urlParams;
        }
    };

    const calculateBalance = useCallback(() => {
        props.giftCard?.payoutBalance.payout && props.giftCard?.payoutBalance.payout < 0 ? setIsBalanceSufficient(false) : setIsBalanceSufficient(true);
    }, [props.giftCard?.payoutBalance]);

    const handleSelectChange = useCallback((value: string) => {
        setSelectedValue(value);
        setDisabled(!value);
        calculateBalance();
    }, [calculateBalance]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
        <>
            <Title title={t('payout__title')} subTitle={t('payout__subtitle')} />
            <Section className="section">
                <div className="content">
                    <p>{t('payout__paymentExplanation')}</p>
                    <div className="panel">
                        <PayoutPricingTable giftCard={props.giftCard} />
                    </div>
                </div>
            </Section>
            <Section className="content">
                <SelectInput label={t('payout__selectBankAccount')} placeholder={t('payout__hostingBankAccount')} selectItems={providers[0]?.issuers.map((issuer) => ({ value: issuer.id, name: issuer.name }))} id="provider-select" selectedValue={selectedValue} onChange={handleSelectChange}>  </SelectInput>
                <LoaderButton disabled={disabled || !isBalanceSufficient} type="button" onClick={handleCreateSession}>{t('payout__idinIdentification')}</LoaderButton>
            </Section>
            <Section>
                <div className="content has-light-background">
                    <div className="panel-row align-items-start">
                        <img src={idinLogo} alt="iDIN logo" />
                        <div>
                            <p>
                                {t('payout__explainIdentification')}
                            </p>
                            <a href="https://www.idin.nl" target="_blank" rel="noopener noreferrer nofollow">{t('payout__explainationWhatisIDIN')}</a>
                        </div>
                    </div>
                </div>
                </Section>
            <PayoutEndSection />
            </> : <></>
    );
};

export default PayOutCard;