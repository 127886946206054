import { Row, Col } from "react-bootstrap";
import serialExampleImage from "../../../static/img/intersolve-visa-reverse-serial.png";
import goShoppingImage from "../../../static/img/go-shopping.png";
import enterCodeImage from "../../../static/img/enter-security-code.png";
import checkTermsImage from "../../../static/img/check-terms.png";
import confirmDetailsImage from "../../../static/img/confirm-details.png";
import { Section } from "intersolve.web.componentlib";
import { useTranslation } from "react-i18next";

function VisaCardSection() {
    const { t } = useTranslation();
    return (
        <>
            <Section id="explanation">
                <div className="content">
                    <h2 className="display-3">{t('visa__content_title')}</h2>
                    <h3 className="display-5 text-muted">{t('visa__content_subtitle')}</h3>
                </div>
            </Section>
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={serialExampleImage}
                                alt={t('visa__content_explainer_1_imageAlt') ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('visa__content_explainer_1_title')}</h4>
                            <p>
                                {t('visa__content_explainer_1_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={enterCodeImage}
                                alt={t('visa__content_explainer_2_imageAlt') ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('visa__content_explainer_2_title')}</h4>
                            <p>
                                {t('visa__content_explainer_2_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={checkTermsImage}
                                alt={t('visa__content_explainer_3_imageAlt') ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('visa__content_explainer_3_title')}</h4>
                            <p>
                                {t('visa__content_explainer_3_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={confirmDetailsImage}
                                alt={t('visa__content_explainer_4_imageAlt') ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">
                                {t('visa__content_explainer_4_title')}
                            </h4>
                            <p>
                                {t('visa__content_explainer_4_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={goShoppingImage}
                                alt={t('visa__content_explainer_5_imageAlt') ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">
                                {t('visa__content_explainer_5_title')}
                            </h4>
                            <p>
                                {t('visa__content_explainer_5_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
        </>
    );
}

export default VisaCardSection;
