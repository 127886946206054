import React, { useRef, useEffect } from "react";
import { Title, Section } from 'intersolve.web.componentlib';
import OTCForm from "../../components/OtcForm";
import { useTranslation } from "react-i18next";
import { BrandPageProps } from "../../models/BrandPage";
import { CreditCard } from "../../models/CreditCard";
import { OTCNumber } from "../../models/OTCNumber";
import { useNavigate } from "react-router-dom";
import { OtcRequestResult } from "../../models/OtcRequestResult";

interface VisaForgotOtcProps extends BrandPageProps {
    creditCard?: CreditCard;
    otcRequestResult?: OtcRequestResult;
    onNavigate: (otcNumber: OTCNumber) => void;
}

const VisaForgotOtc = (props: VisaForgotOtcProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    const resendCode = React.useCallback(() => {
        navigate(`/${props.brand.code}/visa/account/reset`, { replace: true });
    }, [navigate, props.brand.code]);

    useEffect(() => {
        if (!props.creditCard)
            navigate(`/${props.brand.code}/visa/account/reset`, { replace: true });
    });

    return (
        props.creditCard ?
            <>
            <Title
                title={t('visa__loginForgotTitle')}
                // subtitle={t('visa__cardName')}
                anchor={"#" + t('visa__explanationAnchor')}
                anchortitle={t('visa__explanationAnchorTitle')}
            ></Title>
            <Section>
                <div className="content">
                    <p>
                        {t('visa__otc_beforeNumber')}
                        <strong> {props.otcRequestResult?.phoneNumber}</strong><span>. </span>
                        <span>{t('visa__otc_afterNumber')} <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={resendCode}>{t('visa__otc_resend_code')}</span></span>
                    </p>
                    <OTCForm
                        fields={6}
                        type={"number"}
                        submit_title={t('visa__otcForgot_submit')}
                        // help_text={t('visa__otc_helpText')}
                        link="/visa/account/reset/security"
                        checkTerms={false}
                        label={t('visa__otcForgot_label')}
                        creditCard={props.creditCard}
                        onNavigate={props.onNavigate}
                        brand={props.brand}
                    />
                </div>
            </Section>
            </>
            : <></>
    );
};

export default VisaForgotOtc;
