import React, { useState, useEffect, useRef } from "react";
import { Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CreditCard } from "../../../models/CreditCard";
import {CardInput, PincodeInput, Section, Panel, LoaderButton} from 'intersolve.web.componentlib';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { BrandPageProps } from "../../../models/BrandPage";

interface VisaLoginFormProps extends BrandPageProps {
    onSuccess: (cardInfo: CreditCard) => void;
}

const VisaLoginForm = (props: VisaLoginFormProps) => {
    const { t } = useTranslation();

    const [creditCard, setCreditCard] = useState<string>();
    const [securityCode, setSecurityCode] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorKey, setErrorKey] = useState<string>("");
    const [recaptchaKey, setRecaptchaKey] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();
    const [isVerified, setIsVerified] = useState<boolean>(false);

    const giftCardRef = useRef<HTMLInputElement>(null);

    const getRecaptchaKey = React.useCallback(() => {
        fetch(`/api/Utility/Recaptcha`)
            .then((res) => res.json())
            .then((res) => {
                setRecaptchaKey(res.key);
            })
            .catch((e) => { console.error("something went wrong fetching the recaptcha")});
    }, [setRecaptchaKey]);   

    useEffect(() => {
        getRecaptchaKey();
    }, [getRecaptchaKey]);

    const handleSubmit = React.useCallback((event: any) => {
        if (event) event.preventDefault();
        setLoading(true);
        const url = `/api/GiftCard/`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(
                {
                    code: props.brand?.code,
                    giftCardNumber: creditCard,
                    pinCode: securityCode,
                    "g-recaptcha-response": recaptchaToken
                })
            })
            .then((res) => {
                if (res.ok) {
                    props.onSuccess({cardNumber: creditCard!, securityCode: securityCode});
                }
                else {
                    setError(true);
                    res.status === 404 ? setErrorKey("visa__loginError") : setErrorKey("generic__error_message");
                }
            })
            .catch(error => {
                setError(true);
                setErrorKey("generic__error_message");
                console.error("Error: ", error);

            })
            .finally(() => {
                setLoading(false);
            });
    }, [props, creditCard, securityCode, recaptchaToken]);

    const onChangeCardNumber = React.useCallback((value: string) => {
        setCreditCard(value);
        setError(false);
        setErrorKey("");
    }, []);

    const onChangePin = React.useCallback((value: string) => {
        setSecurityCode(value);
        setError(false);
        setErrorKey("");
    }, []);

    const onRecaptchaChange = React.useCallback((token: string | null) => {
        setIsVerified(token !== null);
        setRecaptchaToken(token);
    }, [setIsVerified, setRecaptchaToken]);

    return (
        <>
            <Section className={loading ? "loading" : ""}>
                <div className="content form">
                    <Form onSubmit={handleSubmit}>
                        <CardInput
                            label={t('visa__cardnumber')}
                            placeholder="0000 0000 0000 0000 000"
                            onChange={onChangeCardNumber}
                            inputRef={giftCardRef}
                            disabled={loading}
                            helpText={t('visa__cardnumberHelp')}
                            value={creditCard}
                        />
                        <PincodeInput
                            label={t('visa__login_security_passcode_label')}
                            onChange={onChangePin}
                            disabled={loading}
                            value={securityCode}
                            alphaNumerical
                        />
                        <Link to="../account/reset" replace>{t('visa__forgot_passcode_linktext')}</Link>
                        <Form.Group className="form-group">
                            <input type="text" id="name" className="input" hidden />

                            {recaptchaKey &&
                                <ReCAPTCHA
                                    sitekey={recaptchaKey}
                                    onChange={onRecaptchaChange}
                                    className="recaptcha"
                                />
                            }
                        </Form.Group>
                        <Panel>
                            {error && (
                                <Alert key="danger" variant="danger my-0">
                                    {t(errorKey)}
                                </Alert>
                            )}
                        </Panel>

                        <LoaderButton disabled={!isVerified} loading={loading} type="submit">
                            {loading ? t('generic__buttonWaiting') : t('visa__loginSubmit')}
                        </LoaderButton>
                    </Form>
                </div>
            </Section>
        </>
    );
}
export default VisaLoginForm;
