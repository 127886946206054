import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import VisaCardAboutSecurity from "../../components/Visa/Section/VisaCardAboutSecurity";
import { Title, LoaderButton, Section } from 'intersolve.web.componentlib';
import CardBalance from "../../components/CardBalance";
import { useTranslation } from 'react-i18next';
import { CreditCard } from "../../models/CreditCard";
import { OTCNumber } from "../../models/OTCNumber";
import { BrandPageProps } from "../../models/BrandPage";
import { isEasyToGuess } from "../../utility/securityUtil";

interface VisaSecurityProps extends BrandPageProps {
    creditCard?: CreditCard;
    otcNumber?: OTCNumber;
    onNavigate: (securityCode: string) => void;
}

const VisaSecurity = (props: VisaSecurityProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [securityCode, setSecurityCode] = useState<string>("");
    const [repeatCode, setRepeatCode] = useState<string>("");
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [messageKey, setMessageKey] = useState("visa__security_passcode_help");
    const [messageClass, setMessageClass] = useState("muted");

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const validateCode = React.useCallback((value) => {
        setError(false);
        if (value.length < 6) {
            setMessageClass("muted");
            setMessageKey("visa__security_passcode_help");
            setIsValid(false);
            // when the code is not empty and is not 6 digits long
        } else if (value.length >= 7) {
            setMessageClass("text-danger");
            setMessageKey("visa__security_passcode_error_length");
            setIsValid(false);
        } else if (isEasyToGuess(value)) {
            setMessageClass("text-danger");
            setMessageKey("visa__security_passcode_error_guess");
            setIsValid(false);
        } else if (value.length === 6) {
            setMessageClass("");
            setMessageKey("");
            setIsValid(true);
        }
    }, []);

    const handleSecurityCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setSecurityCode(input);
        validateCode(input);
    }, [validateCode]);

    const handleRepeatCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setRepeatCode(input);
    }, [setRepeatCode]);

    const checkIfSubmitPossible = React.useCallback(() => {
        if (securityCode.length === 6 && securityCode === repeatCode && isValid)
            setSubmitEnabled(true);
        else
            setSubmitEnabled(false);
    }, [isValid, repeatCode, securityCode]);

    // when the users unfocuses the input, we check if the code is not empty and is not 6 digits long to display the error message
    const handleBlur = React.useCallback((e) => {
        const input = e.target.value;
        validateCode(input);
    }, [validateCode]);

    useEffect(() => {
        if (securityCode && repeatCode)
            checkIfSubmitPossible();
    }, [repeatCode, securityCode, checkIfSubmitPossible]);

    const handleRequestActivationCode = React.useCallback(async () => {
        try {
            const response = await fetch(`/api/visa/UseActivationCode/${props.brand.code}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: props.creditCard?.cardNumber,
                    otcNumber: props.otcNumber?.otcNumber,
                    securityCode: securityCode,
                })
            });
            if (response.ok) {
                props.onNavigate(securityCode);
                navigate(`/${props.brand.code}/visa/activate/review`, { replace: true });
            } else {
                if (response.status === 400) {
                    response.json().then((json) => {
                        if (json.code === "SECURITY_CODE_INSECURE") {
                            setError(true);
                            setIsValid(false);
                            setMessageClass("text-danger");
                            setMessageKey("visa__security_passcode_error_guess");
                        }
                        else
                            navigate(`/${props.brand.code}/visa/activate/failed`, { replace: true });
                    });
                }
                else
                    navigate(`/${props.brand.code}/visa/activate/failed`, { replace: true });
            }
        } catch {
            setError(true);            
        } finally {
            setLoading(false);
        }
    }, [navigate, props, securityCode]);

    useEffect(() => {
        if (!props.creditCard) {
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
        }
    });

    return (
        props.creditCard ?
            <>
                <Title
                    title={t("visa__activationTitle")}
                    anchor={"#" + t("visa__content_security_anchor")}
                    anchortitle={t("visa__content_security_anchorTitle")}
                ></Title>
                <CardBalance
                    boxed={false}
                    tag_title={t("generic__tagInactive")}
                    tag_type="light"
                    creditCard={props.creditCard}
                    hideBalance
                />
                <Section>
                    <div className="content form">
                        <h3 className="display-4">
                            {t("visa__security_passcode_title")}
                        </h3>
                        <p>{t("visa__security_passcode_description")}</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="form-group" controlId="formSecurityCode">
                                <Form.Label>
                                    {t("visa__security_passcode")}
                                </Form.Label>
                                <Form.Control
                                    className="large narrow no-spinner"
                                    type="password"
                                    inputMode="text"
                                    pattern="[0-9a-zA-Z]*"
                                    placeholder={t("visa__security_passcode_placeholder") ?? ""}
                                    value={securityCode}
                                    onChange={handleSecurityCodeChange}
                                    onBlur={handleBlur}
                                />
                                {messageKey && (
                                    <Form.Text className={messageClass}>
                                        {t(messageKey)}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="formRepeatCode">
                                <Form.Label>
                                    {t("visa__security_passcode_repeat")}
                                </Form.Label>
                                <Form.Control
                                    className="large narrow no-spinner"
                                    type="password"
                                    inputMode="text"
                                    pattern="[0-9a-zA-Z]*"
                                    placeholder={t(
                                        "visa__security_passcode_repeat_placeholder"
                                    ) ?? ""}
                                    value={repeatCode}
                                    onChange={handleRepeatCodeChange}
                                />
                                {securityCode !== repeatCode && repeatCode.length >= 4 && (
                                    <Form.Text className="text-danger">
                                        {t("visa__security_passcode_error_match")}
                                    </Form.Text>
                                )}

                                {securityCode && securityCode === repeatCode && (
                                    <Alert variant="warning">
                                        {t("visa__security_passcode_alert_beforeStrong")}
                                        <strong>
                                            {t("visa__security_passcode_alert_strong")}
                                        </strong>
                                        {t("visa__security_passcode_alert_afterStrong")}
                                    </Alert>
                                )}
                            </Form.Group>
                            <LoaderButton loading={loading} disabled={!submitEnabled || error}
                                type="submit" className="is-narrow-mobile" onClick={handleRequestActivationCode}
                            >
                                {t("visa__security_passcode_submit")}
                            </LoaderButton>
                        </Form>
                    </div>
                </Section>
                <hr />
                <VisaCardAboutSecurity />
            </> :
            <></>
    );
};


export default VisaSecurity;
