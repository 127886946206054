import React, { useEffect } from "react";
import { Title, Section } from 'intersolve.web.componentlib';
import { useNavigate } from "react-router";
import { t } from 'i18next';
import { PayoutGiftCard } from "../../models/GiftCard";
import { Customer } from "../../models/Customer";
import { BrandPageProps } from "../../models/BrandPage";
import CardBalance from "../../components/CardBalance";
import BalanceEndSection from "../../components/balance/BalanceEndSection";


interface PayOutFailedViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    customer?: Customer;
    onRetry: () => Promise<void>;
}
const PayOutCardNoBalance = (props: PayOutFailedViewProps) => {
    const navigate = useNavigate();
    const handleRetryNewPayOut = React.useCallback(async (e: React.MouseEvent) => {
       await props.onRetry();
        navigate(`/${props.brand.code}/payout/`);
    }, [navigate, props]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        <>
            <Title
                title={t("payout__no_balance_title")}
                intro={t("payout__no_balance_intro")}
            ></Title>
            <CardBalance
                card_title={t("generic__cardnumber")}
                balance={props.giftCard?.payoutBalance.balance}
                currency={props.giftCard?.payoutBalance.assetCode }
                valid=""
                creditCard={{ cardNumber: props.giftCard?.code ?? "" }}
            ></CardBalance>           
            <BalanceEndSection>
                {t("payout__no_balance_end_text")}
            </BalanceEndSection>
            <Section className="section">
                <div className="panel-row">
                    <button className="btn btn-light" type="button" onClick={handleRetryNewPayOut}>
                        {t('generic__retryButtonLabel')}
                    </button>
                </div>
            </Section>
        </>
    );
}

export default PayOutCardNoBalance;
