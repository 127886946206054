import React from 'react';
import { Customer } from '../models/Customer';
import { Panel, Text } from 'intersolve.web.componentlib';

interface CustomerInfoProps {
    customer: Customer;
}

const CustomerInfo = (props: CustomerInfoProps) => {

    if (!props.customer) 
        return <></>;

    const formatCustomerName = () => {
        return `${props.customer?.individual?.initials ?? ""} ${props.customer?.individual?.middleName ?? ""} ${props.customer?.individual?.lastName ?? ""}`.replace("  ", " ");
    }

    const formatEmailAddress = () => {
        if (!props.customer?.contactInfo?.emailAddresses || props.customer?.contactInfo?.emailAddresses?.length === 0) {
            return <></>;
        }

        return <a href={`mailto://${props.customer?.contactInfo?.emailAddresses[0].value}`}>{props.customer?.contactInfo?.emailAddresses[0].value}</a>
    }

    const formatAddress = () => {
        if (!props.customer?.contactInfo?.addresses || props.customer?.contactInfo?.addresses?.length === 0)
            return <></>;

        return (
            <>
                {props.customer?.contactInfo?.addresses[0].addressLine1}
                <br />
                {props.customer?.contactInfo?.addresses[0].postalCode} {props.customer?.contactInfo?.addresses[0].city}
            </>
        )
    }

    return (
        <div className="content has-light-background px-4 py-3">
            <Panel>
                <Text variant="bigger">{formatCustomerName()}</Text>
                <p>{formatAddress()}</p>
                <p>
                    {formatEmailAddress()}
                </p>
            </Panel>
        </div>
    )
}

export default CustomerInfo;