import { Row, Col } from "react-bootstrap";
import { Section } from "intersolve.web.componentlib";
import { useTranslation } from "react-i18next";

export interface ImageInformationContainerProps {
    imageAlt: string;
    imageSource?: string;
    text?: string;
    title?: string;
    
}
const ImageInformationContainer = (props: ImageInformationContainerProps) => {
    const { t } = useTranslation();
    return (
        <>            
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={props.imageSource}
                                alt={t(props.imageAlt) ?? ""}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t(props.title ?? "")}</h4>
                            <p>
                                {t(props.text ?? "")}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
           
        </>
    );
}

export default ImageInformationContainer;
