import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BalanceEndSection from "../../components/balance/BalanceEndSection";
import { Section } from "intersolve.web.componentlib";
import { BrandPageProps } from "../../models/BrandPage";
import { BalanceGiftCard } from "../../models/GiftCard";
import CardBalance from "../../components/CardBalance";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface BalanceForgotSuccessProps extends BrandPageProps {
    giftCard?: BalanceGiftCard;
}

const BalanceForgotSuccess = (props: BalanceForgotSuccessProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const getExpiryValue = React.useCallback(() => {
        if (props.giftCard) {
            const expiresAtTimestamp = Date.parse(props.giftCard.expiresAt ?? "");

            if (props.giftCard?.expiresAt && !isNaN(expiresAtTimestamp)) {
                const now = new Date();
                const expiresAt = new Date(expiresAtTimestamp);
                const oneYearAfterExpiresAt = new Date(expiresAt.getTime() + 31536000000); // 31536000000 is the number of milliseconds in a year

                if (now <= expiresAt) {
                    return t('reset__validUntil') + ` ${expiresAt?.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })}`;
                } else if (now <= oneYearAfterExpiresAt) {
                    return t('reset__expirationDateDefault');
                } else {
                    return t('reset__expirationDate');
                }
            }
            else {
                return t('reset__unlimitedValidity');
            }
        }
        else {
            return t('reset__unlimitedValidity');
        }
    }, [i18n.language, props.giftCard, t]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/reset`, { replace: true });
        }
    });

    return (
        <>
            <Section>
                <Alert variant={"success"} className="my-0">
                    <h4 className="alert-heading mt-0">
                        {t("reset__security_passcode_success_title")}
                    </h4>
                    <strong>{t("reset__security_passcode_success_strong")}</strong> {t("reset__security_passcode_success_afterStrong")}
                </Alert>
            </Section>
            {props.giftCard &&

                <CardBalance
                    card_title={t("reset__cardnumber")}
                    creditCard={{ cardNumber: props.giftCard.code }}
                    balance={props.giftCard.balances[0].quantity.value}
                    valid={getExpiryValue()}
                    currency={props.giftCard.balances[0].quantity.assetCode}
                ></CardBalance>

            }
            <BalanceEndSection>
               {t("reset__security_code_success_text")}
            </BalanceEndSection>
        </>
    );
};

export default BalanceForgotSuccess;