import { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useParams } from 'react-router-dom';
import { Brand } from '../models/Brand';
import BalanceLayout from '../pages/balance/BalanceLayOut';
import { BalanceGiftCard, GiftCard } from '../models/GiftCard';
import BalanceAmountView from '../pages/balance/BalanceAmountView';
import BalanceCheckIFrame from '../embedded/balance/BalanceCheckIFrame';
import BalanceGiftCardViewCheck from '../pages/balance/BalanceGiftCardViewCheck';
import ErrorPage from '../pages/generic/Error';

export const BalanceRoutes = () => {
    const [brand, setBrand] = useState<Brand>();

    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(() => {
        fetch(`/api/brand/${brandId}`)
            .then((res) => res.json())
            .then((res) => setBrand(res));
    }, [setBrand, brandId]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <BalanceLayout brand={brand}>
                    <BalanceRouteSet brand={brand} />
                </BalanceLayout>
            }
        </>
    );
};

interface BalanceRouteProps {
    brand: Brand;
}

const BalanceRouteSet = (props: BalanceRouteProps) => {
    const [giftCard, setGiftCard] = useState<BalanceGiftCard>();   

    const onNavigateGiftCardView = useCallback((giftCard: BalanceGiftCard) => {
        setGiftCard(giftCard);      
    }, [setGiftCard]);

    return (
        <>
            <Routes>
                <Route>
                    <Route path="error" element={<ErrorPage brand={props.brand} />} />
                    <Route path="embed">
                        <Route path="balance/*" element={<BalanceCheckIFrame brand={props.brand} />} />
                    </Route>
                    <Route path="amount" element={<BalanceAmountView giftCard={giftCard} brand={props.brand} />} />         
                    <Route path="" element={<BalanceGiftCardViewCheck onNavigate={onNavigateGiftCardView} brand={props.brand} />} />
                </Route>
            </Routes>
        </>
    )

};

