import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Title } from 'intersolve.web.componentlib';
import VisaLoginForm from "../../components/Visa/Form/VisaLoginForm";
import VisaCardAccountLoginSection from "../../components/Visa/Section/VisaCardAccountLoginSection";
import { CreditCard } from "../../models/CreditCard";
import { Brand } from "../../models/Brand";
import { useNavigate } from "react-router-dom";

interface VisaAccountLoginProps {
    onNavigate: (cardInfo: CreditCard) => void;
    brand: Brand;
}
const VisaAccountLogin = (props: VisaAccountLoginProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    const handleOnSuccess = React.useCallback((cardInfo: CreditCard) => {
        props.onNavigate(cardInfo);
        navigate(`/${props.brand.code}/visa/account/details`, { replace: true })
    }, [navigate, props])

    return (
        <>
            <Title
                title={t('visa__loginTitle')}
                anchor={"#" + t('visa__explanationAnchor')}
                anchortitle={t('visa__explanationAnchorTitle')}
            ></Title>
            <section className="section">
                <div className="content">
                    {t('visa__loginExplanationText')}
                </div>
            </section>
            <VisaLoginForm brand={props.brand} onSuccess={handleOnSuccess}></VisaLoginForm>
            <hr />
            <VisaCardAccountLoginSection />
        </>
    );
}

export default VisaAccountLogin;
