export interface Brand {
    code: string;
    name?: string;
    styling?: BrandStyling
    availableLanguages?: string[];
}

export interface BrandStyling {
    logo?: string;
    headerImage?: string;
    informationType: InformationType;
    addSwoosh?: boolean;
    useGradient?: boolean;
    backgroundVariant?: string;
}

export enum InformationType {
    None = 0, Tooltip = 1, ContainerAbove = 2, ContainerBelow = 3, ContainerBeneath = 4
 }
