import React from 'react';

export enum ButtonTypes {
    LoaderButton,
    RetryButton,
    DefaultButton
}

export interface BaseButtonProps {
    children?: string | JSX.Element | JSX.Element[];
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    disabled?: boolean;
    type: "submit" | "button" | "reset" | undefined,
    buttonType: ButtonTypes
}

const BaseButton = (props: BaseButtonProps) => {
    const getButtonStyle = (type: ButtonTypes) => {
        switch (type) {
            case ButtonTypes.LoaderButton:
                return "btn-primary";
            case ButtonTypes.RetryButton:
                return "btn-light";
            case ButtonTypes.DefaultButton:
                return "btn-primary";
            default:
                return ""
        }
    }
    return (
        <button
            className={`btn ${getButtonStyle(props.buttonType)} ${props.className}`}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type}
        >
            {props.children}
        </button>
    );

};

export default BaseButton;