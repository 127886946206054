import { useState, useCallback, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { Brand } from '../models/Brand';
import { BalanceGiftCard, GiftCard } from '../models/GiftCard';
import IdentifyLayOut from '../pages/identify/IdentifyLayOut';
import GiftCardView from '../pages/identify/GiftCardView';
import ProviderSelectView from '../pages/identify/ProviderSelectView';
import IDINCallbackHandler from '../components/iDINCallbackHandler';
import { Customer } from '../models/Customer';
import IdentifySuccess from '../pages/identify/IdentifySuccess';
import IdentifyFailed from '../pages/identify/IdentifyFailed';

export const IdentifyRoutes = () => {
    const [brand, setBrand] = useState<Brand>();

    const params = useParams();
    let brandId = params.id;

    const getBrand = useCallback(() => {
        fetch(`/api/brand/${brandId}`)
            .then((res) => res.json())
            .then((res) => setBrand(res));
    }, [setBrand, brandId]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href={`/api/css/brand/${brandId}/custom.css`} />
            </Helmet>
            {brand &&
                <IdentifyLayOut brand={brand}>
                    <IdentifyRouteSet brand={brand} />
                </IdentifyLayOut>
            }
        </>
    );

};
interface IdentifyRouteProps {
    brand: Brand;
}


const IdentifyRouteSet = (props: IdentifyRouteProps) => {
    const navigate = useNavigate();
    const [giftCard, setGiftCard] = useState<BalanceGiftCard>();
    const [customer, setCustomer] = useState<Customer>();
    const [securityCode, setSecurityCode] = useState<string>();   
    const [cardNumber, setCardNumber] = useState<string>();

    const fetchCustomerBySessionId = useCallback(async (brandId, sessionId, cardNumber, securityCodeValue) => {
        const response = await fetch(`/api/Authentication/${brandId}/customer?sessionId=${sessionId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: cardNumber,
                    securityCode: securityCodeValue
                })
            });
        if (response.ok) {
            const data = await response.json();
            setCustomer(data);
            setCardNumber(data.cardNumber);
            console.log(data);
        }
    }, [setCustomer]);

    const onCallBack = useCallback(async (sessionId, state) => {
        if (state) {
            const storage = localStorage.getItem(state);
            if (storage) {
                setGiftCard(JSON.parse(storage).giftCard);
                setSecurityCode(JSON.parse(storage).securityCode);
                const giftCardData = JSON.parse(storage).giftCard;
                const securityCodeData = JSON.parse(storage).securityCode;

                localStorage.removeItem(state);
                try
                {
                    await fetchCustomerBySessionId(props.brand.code, sessionId, giftCardData.code, securityCodeData);
                    if (customer !== null) {
                       
                        navigate(`/${props.brand.code}/identify/success/`);
                    }
                    else {
                        navigate(`/${props.brand.code}/identify/failed/`);
                    }
                }
                catch {
                    navigate(`/${props.brand.code}/identify/failed/`);
                }
                

            }
        }        
    }, [fetchCustomerBySessionId, navigate, props.brand.code]);    
    
    const onNavigateGiftCardView = useCallback((giftCard: GiftCard) => {
        setGiftCard(giftCard as BalanceGiftCard);
    }, [setGiftCard]);
    const onNavigateCardNumber = useCallback((cardNumber: string) => {
        setCardNumber(cardNumber);
    }, [setCardNumber]);

    return (
        <>
          
            <Routes>
                <Route>
                    <Route path="" element={<GiftCardView onNavigate={onNavigateGiftCardView} brand={props.brand} onNavigateCardNumber={onNavigateCardNumber}  />} />
                    <Route path="provider" element={<ProviderSelectView giftCard={giftCard} brand={props.brand} />} />                    
                    <Route path="success" element={<IdentifySuccess giftCard={giftCard} brand={props.brand} customer={customer} cardNumber={cardNumber} />} />
                    <Route path="failed" element={<IdentifyFailed giftCard={giftCard} brand={props.brand} customer={customer} cardNumber={cardNumber} />} />
                    <Route path="callback" element={<IDINCallbackHandler onCallBack={onCallBack} />} />
                </Route>
            </Routes>
               
        </>
    )

};

