import * as React from 'react';
import { Section, Panel, DefaultLabel, Text, Currency } from 'intersolve.web.componentlib';
import { BalanceGiftCard } from "../models/GiftCard";
import { useTranslation } from 'react-i18next';

interface AmountDisplayProps {
    giftCard: BalanceGiftCard;
}

const AmountDisplay = (props: AmountDisplayProps) => {
    const { t, i18n } = useTranslation();

    const renderExpiry = () => {
        const expiresAtTimestamp = Date.parse(props.giftCard.expiresAt ?? "");

        if (props.giftCard.expiresAt && !isNaN(expiresAtTimestamp)) {
            const now = new Date();

            const expiresAt = new Date(expiresAtTimestamp);
            const oneYearAfterExpiresAt = new Date(expiresAt.getTime() + 31536000000); // 31536000000 is the number of milliseconds in a year

            if (now <= expiresAt) {
                return t('generic__validUntil') + ` ${expiresAt?.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })}`;
            } else if (now <= oneYearAfterExpiresAt) {
                return t('generic__expirationDateDefault');
            } else {
                return t('generic__expirationDate');
            }
        }
        else {          
            return t('generic__unlimitedValidity');
        }
    };

    return (
        <Section className="content">
            <Panel>
                <DefaultLabel>{t('generic__labelCardNumber')}</DefaultLabel>
                <Text variant="bigger" muted>
                    {props.giftCard.code
                        .replace(/[^a-z0-9]/gi, '')
                        .replace(/(.{4})/g, '$1 ').trim()}
                </Text>
            </Panel>
            {props.giftCard.balances.map((q) => q.quantity.value) &&
                <Panel>
                    <DefaultLabel>{t('generic__saldo')}</DefaultLabel>
                    <div className="panel-row">
                        <Text variant="bigger">
                            <Currency code={props.giftCard.balances[0].quantity.assetCode} /> {(parseFloat(props.giftCard.balances.map((q) => q.quantity.value).toString()) / 100).toLocaleString("nl", { useGrouping: false, minimumFractionDigits: 2 })}</Text>
                        <Text>{renderExpiry()}</Text>
                    </div>
                </Panel>
            }
        </Section>
    );
};

export default AmountDisplay;