import React from 'react';

interface SectionProps {
    children: JSX.Element | JSX.Element[] | string;
    className?: string
    id?: string
}

const Section = (props: SectionProps) => {
    return (
        <div {...props} className={`section ${props.className}`}>
            {props.children }
        </div>
  );
}

export default Section;