import React from 'react';

interface TextProps {
    children: string;
    variant?: "bigger" | "smaller";
    muted?: boolean;
}

const Text = (props: TextProps) => {
    return (
        <div className={props.variant}>
            <span className={props.muted ? "text-muted" : ""}>
                {props.children}
            </span>
        </div>
    );
};

const Paragraph = (props: TextProps) => {
    return (
        <div className={props.variant}>
            <p className={props.muted ? "text-muted" : ""}>
                {props.children}
            </p>
        </div>
    );
}

export { Text, Paragraph };