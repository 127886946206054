import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardInput, Section, OtcInput, LoaderButton } from "intersolve.web.componentlib";
import { BrandPageProps } from "../../models/BrandPage";
import { InformationType } from "../../models/Brand";
import { useNavigate } from "react-router-dom";
import { Alert, Form } from "react-bootstrap";
import { isEasyToGuess } from "../../utility/securityUtil";
import { BalanceGiftCard } from "../../models/GiftCard";
import Recaptcha from "../../components/Recaptcha";

interface BalanceForgotCodeProps extends BrandPageProps {
    onNavigate: (giftCard: BalanceGiftCard) => void;
}

const BalanceForgotCode = (props: BalanceForgotCodeProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [giftCardNumber, setGiftCardNumber] = useState<string>();
    const [otcValue, setOtcValue] = useState<string>();

    const [error, setError] = useState<boolean>(false);
    const [otcErrorKey, setOtcErrorKey] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [securityCode, setSecurityCode] = useState<string>("");
    const [repeatCode, setRepeatCode] = useState<string>("");
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const [messageKey, setMessageKey] = useState<string>("reset__security_passcode_help");
    const [messageClass, setMessageClass] = useState<string>("muted");

    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();
    const [isVerified, setIsVerified] = useState<boolean>(false);

    //Refs
    const giftCardRef = useRef<HTMLInputElement>(null);

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    //Change handlers
    const onChangeCardNumber = React.useCallback((value: string | undefined) => {
        setGiftCardNumber(value);
    }, [setGiftCardNumber]);

    const handleOtcChange = React.useCallback((value: string) => {
        setError(false);
        setOtcErrorKey("");
        setOtcValue(value);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const checkIfSubmitPossible = React.useCallback(() => {
        if (securityCode.length === 6 && securityCode === repeatCode && isValid && otcValue?.length === 6 && giftCardNumber && isVerified)
            setSubmitEnabled(true);
        else
            setSubmitEnabled(false);
    }, [giftCardNumber, isValid, isVerified, otcValue?.length, repeatCode, securityCode]);

    const validateCode = React.useCallback((value) => {
        if (value.length < 6) {
            setMessageClass("muted");
            setMessageKey("reset__security_passcode_help");
            setIsValid(false);
            // when the code is not empty and is not 6 digits long
        } else if (value.length >= 7) {
            setMessageClass("text-danger");
            setMessageKey("reset__security_passcode_error_length");
            setIsValid(false);
        } else if (isEasyToGuess(value)) {
            setMessageClass("text-danger");
            setMessageKey("reset__security_passcode_error_guess");
            setIsValid(false);
        } else if (value.length === 6) {
            setMessageKey("");
            setMessageClass("");
            setIsValid(true);
        }
    }, []);

    const handleSecurityCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setSecurityCode(input);
        validateCode(input);
    }, [validateCode]);

    const handleRepeatCodeChange = React.useCallback((e) => {
        const input = e.target.value;
        setRepeatCode(input);
    }, []);


    // when the users unfocuses the input, we check if the code is not empty and is not 6 digits long to display the error message
    const handleBlur = React.useCallback((e) => {
        const input = e.target.value;
        validateCode(input);
    }, [validateCode]);

    useEffect(() => {
        if (securityCode && repeatCode && otcValue && giftCardNumber && isVerified)
            checkIfSubmitPossible();
    }, [repeatCode, securityCode, otcValue, checkIfSubmitPossible, giftCardNumber, isVerified]);

    const handleRequestActivationCode = React.useCallback(async () => {
        try {
            const response = await fetch(`/api/recovery/${props.brand.code}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cardNumber: giftCardNumber,
                    otcNumber: otcValue,
                    securityCode: securityCode,
                    "g-recaptcha-response": recaptchaToken,
                })
            });
            if (response.ok) {
                response.json().then((json) => {
                    props.onNavigate(json);
                    navigate(`/${props.brand.code}/reset/success`, { replace: true });
                });
            } else if (response.status === 400) {
                response.json().then((json) => {
                    if (json.code === "SECURITY_CODE_INSECURE") {
                        setError(true);
                        setIsValid(false);
                        setMessageClass("text-danger");
                        setMessageKey("reset__security_passcode_error_guess");
                    }
                    else if (json.code === "RECOVERY_CODE_NOT_FOUND") {
                        setError(true);
                        setOtcErrorKey("reset__recovery_code_error_text");
                    }
                    else {
                        navigate(`/${props.brand.code}/reset/error`, { replace: true });
                    }
                });
            }
            else {
                navigate(`/${props.brand.code}/reset/error`, { replace: true });
            }
        } catch {
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [giftCardNumber, navigate, otcValue, props, recaptchaToken, securityCode]);

    const onSetRecaptchaToken = React.useCallback((token: string | null | undefined) => {
        setIsVerified(token !== null && token !== undefined);
        setRecaptchaToken(token);
    }, []);

    return (
        <>
            <Section>
                {t("reset__forgot_security_code_explanation")}
            </Section>
            <Section>
                <CardInput label={t('reset__labelCardNumber')}
                    placeholder="0000 0000 0000 0000 000"
                    onChange={onChangeCardNumber}
                    inputRef={giftCardRef}
                    useScanner
                    addToolTip
                    toolTip={{
                        enabled: props.brand?.styling?.informationType === InformationType.Tooltip, type: "icon", props: { text: t('reset__tooltipCardNumber'), placement: "right", className: "custom-tooltip" }
                    }}
                />
            </Section>
            <Section>
                <OtcInput
                    numberOfFields={6}
                    type="number"
                    onChange={handleOtcChange}
                    label={t("reset__otc_label")}
                    errorText={otcErrorKey ? t(otcErrorKey) : undefined}
                />
            </Section>
            <Section>
                <div className="content form">
                    <p>{t("reset__security_passcode_description")}</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-group" controlId="formSecurityCode">
                            <Form.Label>
                                {t("reset__security_passcode")}
                            </Form.Label>
                            <Form.Control
                                className="large narrow no-spinner"
                                type="password"
                                inputMode="text"
                                pattern="[0-9a-zA-Z]*"
                                placeholder={t("reset__security_passcode_placeholder") ?? ""}
                                value={securityCode}
                                onChange={handleSecurityCodeChange}
                                onBlur={handleBlur}
                            />
                            {messageKey && (
                                <Form.Text className={messageClass}>
                                    {t(messageKey)}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group className="form-group" controlId="formRepeatCode">
                            <Form.Label>
                                {t("reset__security_passcode_repeat")}
                            </Form.Label>
                            <Form.Control
                                className="large narrow no-spinner"
                                type="password"
                                inputMode="text"
                                pattern="[0-9a-zA-Z]*"
                                placeholder={t("reset__security_passcode_repeat_placeholder") ?? ""}
                                value={repeatCode}
                                onChange={handleRepeatCodeChange}
                            />
                            {securityCode !== repeatCode && repeatCode.length >= 4 && (
                                <Form.Text className="text-danger">
                                    {t("reset__security_passcode_error_match")}
                                </Form.Text>
                            )}

                            {securityCode && securityCode === repeatCode && (
                                <Alert variant="warning">
                                    {t("reset__security_passcode_alert_beforeStrong")} <strong>{t("reset__security_passcode_alert_strong")}</strong> {t("reset__security_passcode_alert_afterStrong")}
                                </Alert>
                            )}
                        </Form.Group>
                        <Recaptcha
                            onSetRecaptchaToken={onSetRecaptchaToken}
                            tokenUrl={`/api/Utility/Recaptcha`}
                        />
                        <LoaderButton loading={loading} disabled={!submitEnabled || error}
                            type="submit" className="is-narrow-mobile" onClick={handleRequestActivationCode}
                        >
                            {t("reset__security_passcode_submit")}
                        </LoaderButton>
                    </Form>
                </div>
            </Section>
        </>
    );
};

export default BalanceForgotCode;