import React, { useState, useEffect, useRef } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { InputProps } from ".";

interface AutocompleteInputProps extends InputProps {
    label: string,
    items: any[];
    placeholder?: string,
    onChange?: (value: string, display: string) => void;
    defaultSelectedValue?: string;
    required?: boolean;
}

const AutocompleteInput = (props: AutocompleteInputProps) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const itemList = props.items;
    const selectLabel = props.label;

    useEffect(() => {
        if (showMenu) {
            inputRef.current?.focus();
        }
    }, [showMenu]);

    useEffect(() => {
        if (props.defaultSelectedValue !== undefined && selectedItem === undefined) {
            let selected = itemList.find((i) => i.value === props.defaultSelectedValue);
            setSelectedItem(selected?.name);
        }
    }, [props.defaultSelectedValue, selectedItem]);

    const handleChange = React.useCallback((event: any) => {
        const { value } = event.target;
        setSearchTerm(value);
    }, []);

    const handleSelect = React.useCallback((item: any) => {
        setSearchTerm("");
        let selected = itemList.find((i) => i.value === item);
        setSelectedItem(selected.name);
        if (props.onChange)
            props.onChange(item, selected.name);
    }, []);

    const handleToggle = React.useCallback((isOpen: boolean) => {
        setShowMenu(isOpen);
    }, []);

    const filteredItems = itemList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Dropdown drop="up" onToggle={handleToggle} onSelect={handleSelect}>
                <Dropdown.Toggle variant="light" id="dropdown-autocomplete" className="form-select">
                    {selectedItem || selectLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu show={showMenu} className="shadow">
                    <div className="is-scrollable">
                        {filteredItems.map((item) => (
                            <Dropdown.Item
                                key={item.value}
                                eventKey={item.value}
                                onSelect={handleSelect}
                            >
                                {item.name}
                            </Dropdown.Item>
                        ))}
                    </div>
                    <div className="px-2">
                        <FormControl
                            ref={inputRef}
                            type="text"
                            placeholder={props.placeholder}
                            value={searchTerm}
                            onChange={handleChange}
                            required={props.required}
                        />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default AutocompleteInput;
