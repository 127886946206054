import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React from 'react';
import { useNavigate } from 'react-router';
import GiftCardForm from '../../components/GiftCardForm';
import { BrandPageProps } from '../../models/BrandPage';
import { BalanceGiftCard, GiftCard } from '../../models/GiftCard';
import { t } from 'i18next';

interface GiftCardViewProps extends BrandPageProps {
    onNavigate: (giftCard: BalanceGiftCard) => void;
    giftCard?: GiftCard    
}

const BalanceGiftCardViewCheck = (props: GiftCardViewProps) => {

    const navigate = useNavigate();
    const handleGiftCardSuccess = React.useCallback((giftCard: GiftCard) => {
        props.onNavigate(giftCard as BalanceGiftCard);
        navigate(`/${props.brand.code}/balance/amount/`);

    }, [navigate, props]);

    return (
        <>
            <GiftCardForm onSuccess={handleGiftCardSuccess} brand={props.brand} url={`/api/GiftCard/`} method={"POST"} showResetUrl />
            <hr />
            <Section className="content" >
                <DescriptionLabel as="h2" display={3} id="uitleg">{t('balance__explanation_3')}</DescriptionLabel>
                <DescriptionLabel as="h3" display={5} muted>{t('balance__GetSaldo')}</DescriptionLabel>
                <p>
                    {t('balance__explanation_4')}
                </p>
            </Section>
            <hr />
            <Section className="content" >
                <DescriptionLabel as="h2" display={3} id="pincode-herstellen">{t('balance__reset_security_title')}</DescriptionLabel>
                <DescriptionLabel as="h3" display={5} muted>{t('balance__reset_security_subtitle')}</DescriptionLabel>
                <p>
                    {t('balance__reset_security_description')}
                </p>
            </Section>
            
        </>
    );
};

export default BalanceGiftCardViewCheck;