import { useEffect, useRef } from "react";
import VisaReviewInfoForm from "../../components/Visa/Form/VisaReviewInfoForm";
import { Title, Section } from 'intersolve.web.componentlib';
import CardBalance from "../../components/CardBalance";
import { useTranslation } from 'react-i18next';
import { BrandPageProps } from "../../models/BrandPage";
import { useNavigate } from "react-router-dom";
import { CreditCard } from "../../models/CreditCard";
import { CardHolderInformation } from "../../models/CardHolderInformation";

interface VisaReviewInfoProps extends BrandPageProps {
    creditCard?: CreditCard;
    holderInformation?: CardHolderInformation;
}

const VisaReviewInfo = (props: VisaReviewInfoProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);


    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (!props.creditCard)
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
    });

    return (
        props.creditCard ?
            <>
                <Title
                    title={t("visa__accountTitle")}
                    subtitle={t("visa__cardName")}
                ></Title>
                <CardBalance
                    boxed={false}
                    balance_title={t("visa__cardnumber")}
                    tag_title={t("generic__tagInactive")}
                    tag_type="light"
                    creditCard={props.creditCard}
                    hideBalance
                >
                    <div ref={scrollRef}></div>
                </CardBalance>
                <Section>
                    <div className="content form">
                        <h3 className="display-4">
                            {t("visa__reviewInformationTitle")}
                        </h3>
                        <p>{t("visa__reviewInformationDescription")}</p>
                        <VisaReviewInfoForm
                            isReview={true}
                            isPartial={true}
                            brand={props.brand}
                            holderInformation={props.holderInformation}
                            creditCard={props.creditCard} />
                    </div>
                </Section>
            </> :
            <></>
    );
};

export default VisaReviewInfo;