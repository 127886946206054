import React, { useEffect } from "react";
import { Title, Section } from 'intersolve.web.componentlib';
import { useTranslation } from "react-i18next";
import VisaForgotForm from "../../components/Visa/Form/VisaForgotForm";
import { CreditCard } from "../../models/CreditCard";
import { useNavigate } from "react-router-dom";
import { BrandPageProps } from "../../models/BrandPage";
import { OtcRequestResult } from "../../models/OtcRequestResult";

interface VisaForgotProps extends BrandPageProps {
    onNavigate: (creditCard: CreditCard, activationCodeResult: OtcRequestResult) => void;
}

const VisaForgot = (props: VisaForgotProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    const handleOnSuccess = React.useCallback((cardInfo: CreditCard, otcRequestResult: OtcRequestResult) => {
        props.onNavigate(cardInfo, otcRequestResult);
        navigate(`/${props.brand.code}/visa/account/reset/otc`, { replace: true });
    }, [navigate, props])

    return (
        <>
            <Title
                title={t('visa__loginForgotTitle')}
                anchor={"#" + t('visa__explanationAnchor')}
                anchortitle={t('visa__explanationAnchorTitle')}
            ></Title>
            <Section>
                <div className="content">
                    {t('visa__loginForgotExplanationText')}
                </div>
            </Section>
            <VisaForgotForm brand={props.brand} onSuccess={handleOnSuccess}></VisaForgotForm>
        </>
    );
}

export default VisaForgot;
