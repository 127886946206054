import React from "react";
import { Row, Col } from "react-bootstrap";
import intersolveEU from "../../../static/graphics/intersolve-eu-brandmark.svg";

import serialExampleImage from "../../../static/img/intersolve-visa-reverse-serial.png";
import InfoBox from "../InfoBox";
import { useTranslation } from "react-i18next";

function VisaCardAboutSecurity() {
    const { t } = useTranslation();
    return (
        <>
            <section className="section" id={t('visa__content_security_anchor')!}>
                <div className="content">
                    <h2 className="display-3">
                        {t("visa__content_security_title")}
                    </h2>
                    <h3 className="display-5 text-muted">
                        {t("visa__content_security_subtitle")}
                    </h3>
                </div>
            </section>
            <section className="section">
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={serialExampleImage}
                                alt={t("visa__content_security_1_imageAlt")!}
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">
                                {t("visa__content_security_1_title")}
                            </h4>
                            <p>{t("visa__content_security_1_description")}</p>
                        </Col>
                    </Row>
                </div>
            </section>
            <hr />
            <InfoBox
                title={t("visa__content_infobox_title")}
                info={t("visa__content_infobox_description")}
                image={intersolveEU}
                imageSize="small"
                imageTitle={t("visa__content_infobox_imageAlt")}
                linkTitle={t("visa__content_infobox_linkTitle")}
                link={t("visa__content_infobox_link")}
            ></InfoBox>
        </>
    );
}

export default VisaCardAboutSecurity;
