import React from 'react';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { InputProps } from '.';

interface CurrencyInputProps extends InputProps{
    value: number,
    onChange?: (values: any, sourceInfo: any) => void,
    name?: string,
    className?: string,
    onBlur?: () => void;
    label?: string;
}

const CurrencyInput = (props: CurrencyInputProps) => {
    return (
        <Form.Group className={`form-group ${props.className}`}>
            {props.label &&
                <Form.Label>{props.label}</Form.Label>
            }
            <NumericFormat
                value={props.value}
                decimalScale={2}
                decimalSeparator=","
                allowNegative={false}
                onValueChange={props.onChange}
                valueIsNumericString={true}
                placeholder="0,00"
                inputMode="decimal"
                name={props.name}
                className={`form-control form-control-lg currency-input ${props.className ?? ""}`}
                onBlur={props.onBlur}
            />
        </Form.Group>

    );
};

export default CurrencyInput;