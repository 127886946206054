import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { OtcInput, DefaultButton } from "intersolve.web.componentlib";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CreditCard } from "../models/CreditCard";
import { OTCNumber } from "../models/OTCNumber";
import { Brand } from "../models/Brand";

export interface OtcFormProps {
    fields: number;
    type: string;
    link: string;
    submit_title?: string | null;
    help_text?: string;
    label: string;
    checkTerms: boolean;
    creditCard: CreditCard;
    brand: Brand;
    onNavigate: (otcNumber: OTCNumber) => void;
   
}

// onetime code form with flexible input fields by setting a number value between 1 and 10
// and a boolean value for the input field type (text or number)
// the form will be rendered with the number of input fields based on the number value
// the input field type will be set based on the boolean value
// the label will be renderd with a label prop
// while typing the input field wil switch focus to the next input field
// when the last input field is filled the form submit button will become active
// the form will be submitted with a callback function
// the submit will be a hidden field with the concetenated values of the input fields
// the callback function will be called with the value of the hidden field
// when the user pastes a value in the first input field the form will be filled
const OTCForm = (props: OtcFormProps) => {
    const { t } = useTranslation();
    const [canSubmit, setCanSubmit] = useState(false);
    const navigate = useNavigate();
    const [otcValue, setOtcValue] = useState<string>();
    const [termsChecked, setTermsChecked] = useState(false);
    const submitButtonRef = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        // checks the input values and sets the canSubmit state
        const checkInputValues = () =>
            setCanSubmit(otcValue?.length === props.fields);

        checkInputValues();
        if (canSubmit)
            // set the focus to the submit button
            submitButtonRef.current?.focus();

    }, [canSubmit, otcValue?.length, props.fields, props.help_text, props.label]);

    const handleOtcChange = React.useCallback((value: string) => {
        setOtcValue(value);
    }, []);

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
    }, []);


    const handleRequest = React.useCallback(() => {
        if ((termsChecked || !props.checkTerms) && otcValue) {
            props.onNavigate({ otcNumber: otcValue });
            navigate(`/${props.brand.code}${props.link}`, { replace: true });
        }
        else if (!termsChecked && props.checkTerms) {
            alert("Please accept the terms and conditions");
        }
    }, [navigate, otcValue, props, termsChecked]);

    const handleTermsChecked = React.useCallback((e) => {
        setTermsChecked(e.target.checked);
    }, []);

    return (
        <div className="content">
            <Form className="form" onSubmit={handleSubmit}>
                <OtcInput
                    numberOfFields={props.fields}
                    type={props.type}
                    onChange={handleOtcChange}
                    label={props.label}
                    helpText={props.help_text}
                />
                {props.checkTerms && (
                    <div className="panel">
                        <Form.Label>
                            {t("visa__terms_and_conditions_title")}
                        </Form.Label>
                        <Form.Check className="mb-0">
                            <Form.Check.Input
                                type="checkbox"
                                id="termsIntersolve"
                                name="termsIntersolve"
                                checked={termsChecked}
                                onChange={handleTermsChecked}
                                required                               
                            />
                            <Form.Check.Label htmlFor="termsIntersolve">
                                {t("visa__terms_and_conditions_text")}
                            </Form.Check.Label>
                        </Form.Check>
                        <p className="indent-checkbox">
                            <small>
                                <a
                                    href={t("visa__terms_and_conditions_link_1") ?? "#"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-2"
                                >
                                    {t("visa__terms_and_conditions_link_1_text")}
                                </a>
                                ,{" "}
                                <a
                                    href={t("visa__terms_and_conditions_link_2") ?? "#"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-2"
                                >
                                    {t("visa__terms_and_conditions_link_2_text")}
                                </a>
                            </small>
                        </p>
                    </div>
                )}
                <DefaultButton type="submit" disabled={!canSubmit} ref={submitButtonRef} onClick={handleRequest}>
                    {props.submit_title}
                </DefaultButton>
            </Form>
        </div>
    );
};

export default OTCForm;
