import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';

const path = window.location.pathname.split('/')[1];
let identifierCode = path;

// If the path is callback, we need to get the identifier code from the state parameter in the query string
if (path === 'callback') {
    const searchParams = new URLSearchParams(window.location.search);
    const state = searchParams.get('state');
    if (state) {
        const storedInfo = localStorage.getItem(state);
        if (storedInfo) {
            const parsedInfo = JSON.parse(storedInfo);
            identifierCode = parsedInfo.url.split('/')[1];
        }
    }
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        load: 'languageOnly',
        fallbackLng: 'en',
        backend: {
            loadPath: `/api/translations/${identifierCode}?culture={{lng}}`,
            crossDomain: true
        },
        detection: {
            convertDetectedLanguage: (lng) => lng.split('-')[0]
        }
    }).catch(() => {
        console.error("Failed to initialize i18next");
    });

export default i18next;