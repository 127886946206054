import { useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { PayoutGiftCard } from "../../models/GiftCard";
import { Section, Title, Panel } from 'intersolve.web.componentlib';
import { t } from 'i18next';
import React, { useEffect } from "react";
import { Customer } from "../../models/Customer";
import PayoutPricingTable from "../../components/payout/PayoutPricingTable";
import BalanceEndSection from "../../components/balance/BalanceEndSection";

interface PayOutFailedViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    customer?: Customer;
    onRetry:() => Promise <void>;
}
const PayOutCardUnsufficientBalance = (props: PayOutFailedViewProps) => {
    const navigate = useNavigate();
    const handleRetryNewPayOut = React.useCallback(async (e: React.MouseEvent) => {
       await props.onRetry();
        navigate(`/${props.brand.code}/payout/`);
    }, [navigate, props]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <Title
                    title={t("payout__insufficient_balance_title")}
                    intro={t("payout__insufficient_balance_intro")}
                ></Title>
                <Section>
                    <Panel>
                        <div className="label">{t("generic__cardnumber")}</div>
                        <div className="bigger">
                            <span dir="ltr"><span className="text-muted">XXXX XXXX XXXX XXXX </span>{props.giftCard && props.giftCard?.code?.length >= 3 ? <>{props.giftCard.code.slice(-3)}</> : <>XXX</>}</span>
                        </div>
                    </Panel>
                    <Panel>
                        <div className="label">{"Saldo"}</div>
                        <div className="panel-row">
                            <PayoutPricingTable giftCard={props.giftCard} />
                        </div>
                    </Panel>
                </Section>
                <BalanceEndSection>
                    {t("payout__no_balance_end_text")}
                </BalanceEndSection>
                <Section className="section">
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={handleRetryNewPayOut}>
                            {t('generic__retryButtonLabel')}
                        </button>
                    </div>
                </Section>
            </> :
            <>
            </>
    );

};
export default PayOutCardUnsufficientBalance;