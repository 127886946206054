import React, { useEffect, useRef } from "react";
import VisaCardSection from "../../components/Visa/Section/VisaCardSection";
import { Title, Section } from 'intersolve.web.componentlib';
import CardBalance from "../../components/CardBalance";
import OTCForm from "../../components/OtcForm";
import { useTranslation } from 'react-i18next';
import { CreditCard } from "../../models/CreditCard";
import { useNavigate } from "react-router";
import { OTCNumber } from "../../models/OTCNumber";
import { BrandPageProps } from "../../models/BrandPage";
import { OtcRequestResult } from "../../models/OtcRequestResult";

interface VisaOtcProps extends BrandPageProps {
    creditCard?: CreditCard;
    otcRequestResult?: OtcRequestResult;
    onNavigate: (otcNumber: OTCNumber) => void;
}

const VisaOtc = (props: VisaOtcProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (!props.creditCard)
            navigate(`/${props.brand.code}/visa/activate`, { replace: true });
    });

    const resendCode = React.useCallback(() => {
        navigate(`/${props.brand.code}/visa/activate`, { replace: true });
    }, [navigate, props.brand.code]);

    return (
        props.creditCard ?
            <>
                <Title
                    title={t('visa__activationTitle')}
                    // subtitle={getTranslation('visa__cardName')}
                    anchor={"#" + t('visa__explanationAnchor')}
                    anchortitle={t('visa__explanationAnchorTitle')}
                ></Title>
                <CardBalance
                    //card_title={getTranslation('visa__cardnumber')}
                    boxed={false}
                    tag_title={t('generic__tagInactive')}
                    tag_type="light"
                    creditCard={props.creditCard}
                    hideBalance
                >
                    <div ref={scrollRef}></div>
                </CardBalance>
                <Section>
                    <div className="content">
                        <h3 className="display-4">
                            {t('visa__otc_check_and_terms')}
                        </h3>
                        <p>
                            {t('visa__otc_beforeNumber')}
                            <strong> {props.otcRequestResult?.phoneNumber}</strong><span>. </span>
                            <span>{t('visa__otc_afterNumber')} <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={resendCode}>{t('visa__otc_resend_code')}</span></span>
                        </p>
                        <OTCForm
                            fields={6}
                            type={"number"}
                            submit_title={t('visa__otc_submit')}
                            // help_text={getTranslation('visa__otc_helpText')}
                            link="/visa/activate/security"
                            checkTerms={true}
                            label={t('visa__otc_label')}
                            creditCard={props.creditCard}
                            onNavigate={props.onNavigate}
                            brand={props.brand}
                        />
                    </div>
                </Section>
                <hr />
                <VisaCardSection />
            </>
            : <></>
    );
};

export default VisaOtc;
