import { useCallback, useEffect, useRef, useState } from "react";
import { Brand } from "../../models/Brand";
import { CreditCard } from "../../models/CreditCard";
import { useTranslation } from "react-i18next";
import { Loader } from "intersolve.web.componentlib";
import { Alert } from "react-bootstrap";

interface PincodeDisplayProps {
    title: string | null;
    description: string | null;
    brand: Brand;
    creditCard: CreditCard;
    onPinCollected?: () => void;
    onPinLoaded?: () => void;
    shouldAutoHidePin?: boolean;
}

const PincodeDisplay = (props: PincodeDisplayProps) => {
    const { t } = useTranslation();

    const [pinCollected, setPinCollected] = useState<boolean>(false);
    const [showPin, setShowPin] = useState(false);
    const [frameLoaded, setFrameLoaded] = useState(false);
    const [pinUrl, setPinUrl] = useState<string>("");
    const [controlId, setControlId] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const pinTimeoutRef = useRef<NodeJS.Timeout>();

    const frameRef = useRef<HTMLIFrameElement>(null);

    const postMessageToFrame = useCallback((_controlId: string) => {
        //TODO: Replace with actual origin once known (probably Intersolve domain)
        const origin = "*";
        frameRef.current?.contentWindow?.postMessage({ operation: "view-pin", controlId: _controlId }, origin);
    }, []);

    const collectPin = useCallback(async () => {
        const response = await fetch(`/api/visa/getPin/${props.brand.code}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: props.brand?.code,
                cardNumber: props.creditCard.cardNumber,
                securityCode: props.creditCard.securityCode
            })
        });

        if (response.ok) {
            const result = await response.json();
            setPinUrl(result.pinFrameURL + "?key=" + result.key);
            setControlId(result.controlId);
            setError(false);

            if (props.onPinCollected)
                props.onPinCollected();
        }
        else {
            setError(true);
        }
    }, [props]);

    const onFrameLoaded = useCallback(() => {
        setTimeout(() => {
            setFrameLoaded(true);
            if (props.onPinLoaded)
                props.onPinLoaded();
        }, 500);
    }, []);

    const handleButtonClick = useCallback(() => {
        setShowPin(true);
        setPinCollected(true);
        collectPin().catch((e) => {
            setError(true);
        });
    }, [collectPin]);

    const handleSwitchClick = useCallback(() => {
        const showingPin = showPin;
        setShowPin(!showingPin);
        if (!showingPin)
            collectPin().catch((e) => {
                setError(true);
            });
    }, [collectPin, showPin]);

    useEffect(() => {
        if (showPin && controlId && frameLoaded) {
            postMessageToFrame(controlId);
        }
    }, [showPin, controlId, postMessageToFrame, frameLoaded]);

    // This effect runs when showPin changes
    useEffect(() => {
        // show the PIN for 8 seconds and hide it again
        // Clear the previous timeout when showPin is false
        if (!showPin) {
            clearTimeout(pinTimeoutRef.current);
        }

        else if (props.shouldAutoHidePin) {
            // Start the timer again when showPin is true and autoHide is enabled
            pinTimeoutRef.current = setTimeout(() => {
                setShowPin(false);
            }, 8000);
        }

        // Clean up the timeout when the component unmounts
        return () => {
            clearTimeout(pinTimeoutRef.current);
        };
    }, [props.shouldAutoHidePin, showPin]);

    return (
        <>
            <h3 className="display-4">{props.title}</h3>
            <p>{props.description}</p>
            {!pinCollected && (
                <button className="btn btn-primary is-narrow-mobile" onClick={handleButtonClick}>
                    {t("visa__get_pin_button_title")}
                </button>
            )}
            {/* when the button is pressed, the pincode appears */}
            {pinCollected && (
                <div className="pincode_content">
                    {error &&
                        <Alert variant="danger">
                            {t("visa__get_pin_error")}
                        </Alert>
                    }
                    {showPin ? (
                        <>
                            {frameLoaded ? null : (
                                <div className="pincode-loader mb-3">
                                    <Loader size="sm" />
                                </div>
                            )}
                            <p className={`pincode ${!frameLoaded ? 'd-none' : ""}`}>
                                <iframe src={pinUrl} title="pincode" width="100%" ref={frameRef} id="pincodeFrame" className={frameLoaded ? "loaded" : ""} onLoad={onFrameLoaded}></iframe>
                            </p>
                            <button
                                className="btn btn-light is-narrow-mobile hug-content"
                                onClick={handleSwitchClick}
                            >
                                {t("generic__hide")}
                            </button>
                        </>
                    ) : (
                        <>
                            <p className="pincode">****</p>
                            <button
                                className="btn btn-light is-narrow-mobile hug-content"
                                onClick={handleSwitchClick}
                            >
                                {t("generic__show")}
                            </button>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default PincodeDisplay;