import { useNavigate } from "react-router-dom";
import { Section, LoaderButton, IBANInput, RetryButton, Panel, Title } from 'intersolve.web.componentlib';
import CustomerInfo from "../../components/CustomerInfo";
import { InformationType } from "../../models/Brand";
import { BrandPageProps } from "../../models/BrandPage";
import { PayoutGiftCard } from "../../models/GiftCard";
import React, { useEffect, useRef, useState } from "react";
import { Customer } from "../../models/Customer";
import { Form, Alert } from "react-bootstrap";
import { t } from 'i18next';
import PayoutPricingTable from "../../components/payout/PayoutPricingTable";
import PayoutEndSection from "../../components/payout/PayoutEndSection";

interface PayOutVerificationViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    customer?: Customer,
    securityCode?: string,
}

const PayOutCardConfirm = (props: PayOutVerificationViewProps) => {
    const [messageKey, setMessageKey] = useState("");
    const [error, setError] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [bankAccountCardNumber, setBankAccountCardNumber] = useState<string>();

    const navigate = useNavigate();
    const [containBankNumber, setContainBankNumber] = useState<boolean>(true);

    const onChangeBankAccountNumber = React.useCallback((value: string | undefined) => {
        setBankAccountCardNumber(value);
        setContainBankNumber(value !== undefined && value.trim().length > 0);
    }, [setBankAccountCardNumber, setContainBankNumber]);
    const customerBankAccount = useRef<HTMLInputElement>(null);
    
    const handleRetryCardInfo = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setError(false);
        setAlert(false);
    }, [setError, setAlert]);

    async function CreateBankAccount(brandCode, bankAccountData) {
        const response = await fetch(`/api/payout/${brandCode}/payout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bankAccountData),
        });       
        return response;
    }

    const handleRetry = React.useCallback(() => {
        navigate(`/${props.brand.code}/payout/`)
    },[navigate, props.brand.code]);

    const handleCreateBankAccount = React.useCallback(async () => {
        setLoading(true);
        const brandCode = props.brand.code;
        const bankAccountData = {
            accountNumber: bankAccountCardNumber,
            securityCode: props.securityCode,
            cardNumber: props.giftCard?.code
        };
       
        try {
            const response = await CreateBankAccount(brandCode, bankAccountData);
            if (response.ok) {
                navigate(`/${props.brand.code}/payout/success/`);
            }
            else {               
              
                if (response.status === 400) {
                    response.json().then((json) => {
                        if (json.code === "BANK_ACCOUNT_VERIFICATION_FAILED") {
                            setAlert(true);
                            setMessageKey("payout__bank_verification_failed");
                        }
                        else if (json.code === "BANK_ACCOUNT_CREATION_FAILED") {
                            setAlert(true);
                            setMessageKey("payout__bank_creation_failed");
                        }
                    });
                }               
            }
        } catch(err) {
            setError(true);
            console.log(err)
            navigate(`/${props.brand.code}/payout/failed/`);
        }
        finally {
            setLoading(false);
        }
    }, [props, bankAccountCardNumber, navigate]);

    const formatCustomerName = () => {
        if (!props.customer)
            return "";

        return `${props.customer?.individual?.initials ?? ""} ${props.customer?.individual?.middleName ?? ""} ${props.customer?.individual?.lastName ?? ""}`.replace("  ", " ");
    }

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
        <>
            <Title title={t('payout__title')} subTitle={t('payout__subtitle')} />
            <Section className="content">
                <Form>
                    <IBANInput label={t('payout__bankAccount') + " " + formatCustomerName()} placeholder="NL00 BANK 0000 0000 0000 00" onChange={onChangeBankAccountNumber} inputRef={customerBankAccount} addToolTip
                        toolTip={{
                            enabled: true,
                            type: "subtext",
                            props: {
                                text: t('payout__iban_input_subtext')
                            }
                        }}
                    />
                </Form>
            </Section>
            <Section>
                <div className="panel">
                    <PayoutPricingTable giftCard={props.giftCard} />
                </div>
            </Section>

            <Section className="content">
                <Panel>
                    {error &&
                        <Alert key="danger" variant="danger">
                            {t('payout__error_invalidBank')}
                        </Alert>
                    }
                    {alert &&
                        <Alert key="danger" variant="danger">
                            {t(messageKey)}
                        </Alert>
                    }
                </Panel>
                <div className="content">
                        <LoaderButton disabled={!containBankNumber} loading={loading} onClick={handleCreateBankAccount}>
                            {loading ? t('payout__loadingButtonLabel') : t('payout__checkAccount')}
                        </LoaderButton>
                        <RetryButton type="button" onClick={handleRetry}>
                            {t('generic__buttonRetry')}
                        </RetryButton>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <h3 className="display-4"> {t('payout__titlepayOutToCustomer')} </h3>
                </div>
                {props.customer &&
                    <CustomerInfo customer={props.customer} />
                }
            </Section>
            <PayoutEndSection />
        </> : <></>
    );
};

export default PayOutCardConfirm;