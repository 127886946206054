import React, { useEffect, useRef, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { CardInput, PincodeInput, LoaderButton, RetryButton, Section, Panel} from 'intersolve.web.componentlib';
import ReCAPTCHA from 'react-google-recaptcha';
import { GiftCard } from '../models/GiftCard';
import { Brand, InformationType } from '../models/Brand';
import { t } from 'i18next';
import { CardInfo } from '../models/CardInfo';
import { Link } from 'react-router-dom';

interface GiftCardFormProps {   
    onSuccess: (giftCard: GiftCard, cardInfo: CardInfo) => void,
    brand: Brand | undefined,
    url: string,
    method: string,
    showResetUrl?: boolean;
}

const GiftCardForm = (props: GiftCardFormProps) => {

    //State
    const [giftCardNumber, setGiftCardNumber] = useState<string>();
    const [pin, setPin] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [recaptchaKey, setRecaptchaKey] = useState<string>();

    //Refs
    const giftCardRef = useRef<HTMLInputElement>(null);

    //Logic
    const getRecaptchaKey = React.useCallback(async () => {
        await fetch(`/api/Utility/Recaptcha`)
            .then((res) => res.json())
            .then((res) => {
                setRecaptchaKey(res.key);
            })
            .catch(error => {
                setError(true);
                console.error("Error: ", error);
            }
            );
    }, [setRecaptchaKey]);


    const handleRetryCardInfo = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setPin("");
        setError(false);
        giftCardRef.current?.focus();
        giftCardRef.current?.select();
    }, [setPin, setError, giftCardRef]);

    const handleSubmit = React.useCallback((event: any) => {
        if (event) event.preventDefault();
        setLoading(true);
        fetch(props.url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(
                {
                    code: props.brand?.code,
                    giftCardNumber: giftCardNumber,
                    pinCode: pin,
                    "g-recaptcha-response": recaptchaToken
                })
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((res) => {
                        props.onSuccess(res, { securityCode: pin });
                    });
                }
                else {
                    setError(true);
                }
            })
            .catch(error => {
                setError(true);
                console.error("Error: ", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props, giftCardNumber, pin, recaptchaToken]);

    //Change handlers
    const onChangeCardNumber = React.useCallback((value: string | undefined) => {
        setGiftCardNumber(value);
    }, [setGiftCardNumber]);

    const onRecaptchaChange = React.useCallback((token: string | null) => {
        setIsVerified(token !== null);
        setRecaptchaToken(token);
    }, [setIsVerified, setRecaptchaToken]);

    const onChangePin = React.useCallback((value: string | undefined) => {
        setPin(value);
    }, [setPin]);

    const getTooltipStyle = React.useCallback((informationType: InformationType | undefined) => {
        switch (informationType) {
            case InformationType.Tooltip:
                return "icon";
            case InformationType.ContainerBeneath:
                return "subtext";
            default:
                return ""
        }
    }, []);

    //Effects
    useEffect(() => {
        getRecaptchaKey();
    }, [getRecaptchaKey]);

    return (
        <Section className="content form">
            <Form onSubmit={handleSubmit} >
                <CardInput label={t('generic__labelCardNumber')} placeholder="0000 0000 0000 0000 000" onChange={onChangeCardNumber} inputRef={giftCardRef} useScanner addToolTip disabled={loading}
                    toolTip={{
                        enabled: props.brand?.styling?.informationType !== InformationType.None, type: getTooltipStyle(props.brand?.styling?.informationType), props: { text: t('generic__tooltipCardNumber'), placement: "right", className: "custom-tooltip" }
                    }}
                />

                {props.showResetUrl &&
                    <Form.Text>
                        <Link to={`/${props.brand?.code}/reset`} replace>{t('reset__forgot_passcode_linktext')}</Link>
                    </Form.Text>    
                }

                <PincodeInput label={t('generic__labelSecurity')} subLabel={t('replace__InputExplainText') } value={pin} onChange={onChangePin} disabled={loading} />

                <Form.Group className="form-group">
                    <input type="text" id="name" className="input" hidden />

                    {recaptchaKey &&
                        <ReCAPTCHA
                            sitekey={recaptchaKey}
                            onChange={onRecaptchaChange}
                            className="recaptcha"
                        />
                    }
                </Form.Group>
                <Panel>
                    {error &&
                        <Alert key="danger" variant="danger">
                            {t('generic__error_invalidCard')}
                        </Alert>
                    }
                </Panel>
                {error ?
                    <RetryButton type="button" onClick={handleRetryCardInfo}>
                        {t('generic__buttonRetry')} 

                    </RetryButton>
                    :
                    <LoaderButton disabled={!isVerified} loading={loading} type="submit">
                        {loading ? t('generic__buttonWaiting') : t('generic__buttonSubmit') }
                    </LoaderButton>
                }
            </Form>
        </Section>);
};


export default GiftCardForm;
