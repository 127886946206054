interface Country {
    code: string;
    name: string;
}

interface LocalizedCountryList {
    language: string;
    countries: Country[];
}

export const countryList = (lang: string) => {
    let countries = countryListInternal.find((c) => c.language === lang)?.countries;
    if (!countries) {
        countries = countryListInternal.find((c) => c.language === 'en')?.countries;
    }
    return countries;
}

const countryListInternal: LocalizedCountryList[] = [
    {
        language: 'en',
        countries: [
            { name: "Afghanistan", code: "AF" },
            { name: "Åland Islands", code: "AX" },
            { name: "Albania", code: "AL" },
            { name: "Algeria", code: "DZ" },
            { name: "American Samoa", code: "AS" },
            { name: "Andorra", code: "AD" },
            { name: "Angola", code: "AO" },
            { name: "Anguilla", code: "AI" },
            { name: "Antarctica", code: "AQ" },
            { name: "Antigua and Barbuda", code: "AG" },
            { name: "Argentina", code: "AR" },
            { name: "Armenia", code: "AM" },
            { name: "Aruba", code: "AW" },
            { name: "Australia", code: "AU" },
            { name: "Austria", code: "AT" },
            { name: "Azerbaijan", code: "AZ" },
            { name: "Bahamas", code: "BS" },
            { name: "Bahrain", code: "BH" },
            { name: "Bangladesh", code: "BD" },
            { name: "Barbados", code: "BB" },
            { name: "Belarus", code: "BY" },
            { name: "Belgium", code: "BE" },
            { name: "Belize", code: "BZ" },
            { name: "Benin", code: "BJ" },
            { name: "Bermuda", code: "BM" },
            { name: "Bhutan", code: "BT" },
            { name: "Bolivia", code: "BO" },
            { name: "Bosnia and Herzegovina", code: "BA" },
            { name: "Botswana", code: "BW" },
            { name: "Bouvet Island", code: "BV" },
            { name: "Brazil", code: "BR" },
            { name: "British Indian Ocean Territory", code: "IO" },
            { name: "Brunei Darussalam", code: "BN" },
            { name: "Bulgaria", code: "BG" },
            { name: "Burkina Faso", code: "BF" },
            { name: "Burundi", code: "BI" },
            { name: "Cape Verde", code: "CV" },
            { name: "Cambodia", code: "KH" },
            { name: "Cameroon", code: "CM" },
            { name: "Canada", code: "CA" },
            { name: "Cayman Islands", code: "KY" },
            { name: "Central African Republic", code: "CF" },
            { name: "Chad", code: "TD" },
            { name: "Chile", code: "CL" },
            { name: "China", code: "CN" },
            { name: "Christmas Island", code: "CX" },
            { name: "Cocos (Keeling) Islands", code: "CC" },
            { name: "Colombia", code: "CO" },
            { name: "Comoros", code: "KM" },
            { name: "Congo", code: "CG" },
            { name: "Congo, The democratic Republic of the", code: "CD" },
            { name: "Cook Islands", code: "CK" },
            { name: "Costa Rica", code: "CR" },
            { name: "Côte d'Ivoire", code: "CI" },
            { name: "Croatia", code: "HR" },
            { name: "Cuba", code: "CU" },
            { name: "Cyprus", code: "CY" },
            { name: "Czech Republic", code: "CZ" },
            { name: "Denmark", code: "DK" },
            { name: "Djibouti", code: "DJ" },
            { name: "Dominica", code: "DM" },
            { name: "Dominican Republic", code: "DO" },
            { name: "Ecuador", code: "EC" },
            { name: "Egypt", code: "EG" },
            { name: "El Salvador", code: "SV" },
            { name: "Equatorial Guinea", code: "GQ" },
            { name: "Eritrea", code: "ER" },
            { name: "Estonia", code: "EE" },
            { name: "Ethiopia", code: "ET" },
            { name: "European Union", code: "EU" },
            { name: "Falkland Islands (Malvinas)", code: "FK" },
            { name: "Faroe Islands", code: "FO" },
            { name: "Fiji", code: "FJ" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "French Guiana", code: "GF" },
            { name: "French Polynesia", code: "PF" },
            { name: "French Southern Territories", code: "TF" },
            { name: "Gabon", code: "GA" },
            { name: "Gambia", code: "GM" },
            { name: "Georgia", code: "GE" },
            { name: "Germany", code: "DE" },
            { name: "Ghana", code: "GH" },
            { name: "Gibraltar", code: "GI" },
            { name: "Greece", code: "GR" },
            { name: "Greenland", code: "GL" },
            { name: "Grenada", code: "GD" },
            { name: "Guadeloupe", code: "GP" },
            { name: "Guam", code: "GU" },
            { name: "Guatemala", code: "GT" },
            { name: "Guinea", code: "GN" },
            { name: "Guinea-Bissau", code: "GW" },
            { name: "Guyana", code: "GY" },
            { name: "Haiti", code: "HT" },
            { name: "Heard Island and McDonald Islands", code: "HM" },
            { name: "Holy See (Vatican City State)", code: "VA" },
            { name: "Honduras", code: "HN" },
            { name: "Hong Kong", code: "HK" },
            { name: "Hungary", code: "HU" },
            { name: "Iceland", code: "IS" },
            { name: "India", code: "IN" },
            { name: "Indonesia", code: "ID" },
            { name: "Iran, Islamic Republic of", code: "IR" },
            { name: "Iraq", code: "IQ" },
            { name: "Ireland", code: "IE" },
            { name: "Israel", code: "IL" },
            { name: "Italy", code: "IT" },
            { name: "Jamaica", code: "JM" },
            { name: "Japan", code: "JP" },
            { name: "Jordan", code: "JO" },
            { name: "Kazakhstan", code: "KZ" },
            { name: "Kenya", code: "KE" },
            { name: "Kiribati", code: "KI" },
            { name: "Korea, Democratic People's Republic of", code: "KP" },
            { name: "Korea, Republic of", code: "KR" },
            { name: "Kuwait", code: "KW" },
            { name: "Kyrgyzstan", code: "KG" },
            { name: "Kosovo", code: "XK" },
            { name: "Lao People's Democratic Republic", code: "LA" },
            { name: "Latvia", code: "LV" },
            { name: "Lebanon", code: "LB" },
            { name: "Lesotho", code: "LS" },
            { name: "Liberia", code: "LR" },
            { name: "Libya", code: "LY" },
            { name: "Liechtenstein", code: "LI" },
            { name: "Lithuania", code: "LT" },
            { name: "Luxembourg", code: "LU" },
            { name: "Macao", code: "MO" },
            { name: "North Macedonia, The former Yugoslav Republic of", code: "MK" },
            { name: "Madagascar", code: "MG" },
            { name: "Malawi", code: "MW" },
            { name: "Malaysia", code: "MY" },
            { name: "Maldives", code: "MV" },
            { name: "Mali", code: "ML" },
            { name: "Malta", code: "MT" },
            { name: "Marshall Islands", code: "MH" },
            { name: "Martinique", code: "MQ" },
            { name: "Mauritania", code: "MR" },
            { name: "Mauritius", code: "MU" },
            { name: "Mayotte", code: "YT" },
            { name: "Mexico", code: "MX" },
            { name: "Micronesia, Federated States of", code: "FM" },
            { name: "Moldova, Republic of", code: "MD" },
            { name: "Monaco", code: "MC" },
            { name: "Mongolia", code: "MN" },
            { name: "Montenegro", code: "ME" },
            { name: "Montserrat", code: "MS" },
            { name: "Morocco", code: "MA" },
            { name: "Mozambique", code: "MZ" },
            { name: "Myanmar", code: "MM" },
            { name: "Namibia", code: "NA" },
            { name: "Nauru", code: "NR" },
            { name: "Nepal", code: "NP" },
            { name: "Netherlands", code: "NL" },
            { name: "New Caledonia", code: "NC" },
            { name: "New Zealand", code: "NZ" },
            { name: "Nicaragua", code: "NI" },
            { name: "Niger", code: "NE" },
            { name: "Nigeria", code: "NG" },
            { name: "Niue", code: "NU" },
            { name: "Norfolk Island", code: "NF" },
            { name: "Northern Mariana Islands", code: "MP" },
            { name: "Norway", code: "NO" },
            { name: "Oman", code: "OM" },
            { name: "Pakistan", code: "PK" },
            { name: "Palau", code: "PW" },
            { name: "Palestinian territory, occupied", code: "PS" },
            { name: "Panama", code: "PA" },
            { name: "Papua New Guinea", code: "PG" },
            { name: "Paraguay", code: "PY" },
            { name: "Peru", code: "PE" },
            { name: "Philippines", code: "PH" },
            { name: "Pitcairn", code: "PN" },
            { name: "Poland", code: "PL" },
            { name: "Portugal", code: "PT" },
            { name: "Puerto Rico", code: "PR" },
            { name: "Qatar", code: "QA" },
            { name: "Réunion", code: "RE" },
            { name: "Romania", code: "RO" },
            { name: "Russian Federation", code: "RU" },
            { name: "Rwanda", code: "RW" },
            { name: "Saint Helena", code: "SH" },
            { name: "Saint Kitts and Nevis", code: "KN" },
            { name: "Saint Lucia", code: "LC" },
            { name: "Saint Pierre and Miquelon", code: "PM" },
            { name: "Saint Vincent and the Grenadines", code: "VC" },
            { name: "Samoa", code: "WS" },
            { name: "San Marino", code: "SM" },
            { name: "Sao Tome and Principe", code: "ST" },
            { name: "Saudi Arabia", code: "SA" },
            { name: "Senegal", code: "SN" },
            { name: "Serbia", code: "RS" },
            { name: "Seychelles", code: "SC" },
            { name: "Sierra Leone", code: "SL" },
            { name: "Singapore", code: "SG" },
            { name: "Slovakia", code: "SK" },
            { name: "Slovenia", code: "SI" },
            { name: "Solomon Islands", code: "SB" },
            { name: "Somalia", code: "SO" },
            { name: "South Africa", code: "ZA" },
            { name: "South Georgia and the South Sandwich Islands", code: "GS" },
            { name: "Spain", code: "ES" },
            { name: "Sri Lanka", code: "LK" },
            { name: "Sudan", code: "SD" },
            { name: "Suriname", code: "SR" },
            { name: "Svalbard and Jan Mayen", code: "SJ" },
            { name: "Swaziland", code: "SZ" },
            { name: "Sweden", code: "SE" },
            { name: "Switzerland", code: "CH" },
            { name: "Syrian Arab Republic", code: "SY" },
            { name: "Taiwan, Province of China", code: "TW" },
            { name: "Tajikistan", code: "TJ" },
            { name: "Tanzania, United Republic of", code: "TZ" },
            { name: "Thailand", code: "TH" },
            { name: "Timor-Leste", code: "TL" },
            { name: "Togo", code: "TG" },
            { name: "Tokelau", code: "TK" },
            { name: "Tonga", code: "TO" },
            { name: "Trinidad and Tobago", code: "TT" },
            { name: "Tunisia", code: "TN" },
            { name: "Turkey", code: "TR" },
            { name: "Turkmenistan", code: "TM" },
            { name: "Turks and Caicos Islands", code: "TC" },
            { name: "Tuvalu", code: "TV" },
            { name: "Uganda", code: "UG" },
            { name: "Ukraine", code: "UA" },
            { name: "United Arab Emirates", code: "AE" },
            { name: "United Kingdom", code: "GB" },
            { name: "United States", code: "US" },
            { name: "United States Minor Outlying Islands", code: "UM" },
            { name: "Uruguay", code: "UY" },
            { name: "Uzbekistan", code: "UZ" },
            { name: "Vanuatu", code: "VU" },
            { name: "Venezuela", code: "VE" },
            { name: "Viet Nam", code: "VN" },
            { name: "Virgin Islands, British", code: "VG" },
            { name: "Virgin Islands, U.S.", code: "VI" },
            { name: "Wallis and Futuna", code: "WF" },
            { name: "Western Sahara", code: "EH" },
            { name: "Yemen", code: "YE" },
            { name: "Zambia", code: "ZM" },
            { name: "Zimbabwe", code: "ZW" },
            { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
            { name: "Curacao", code: "CW" },
            { name: "Sint Maarten (Dutch part)", code: "SX" },
        ]
    },
    {
        language: 'nl',
        countries: [
            { name: "Afghanistan", code: "AF" },
            { name: "Åland-eilanden", code: "AX" },
            { name: "Albanië", code: "AL" },
            { name: "Algerije", code: "DZ" },
            { name: "Amerikaans Samoa", code: "AS" },
            { name: "Andorra", code: "AD" },
            { name: "Angola", code: "AO" },
            { name: "Anguilla", code: "AI" },
            { name: "Antarctica", code: "AQ" },
            { name: "Antigua en Barbuda", code: "AG" },
            { name: "Argentinië", code: "AR" },
            { name: "Armenië", code: "AM" },
            { name: "Aruba", code: "AW" },
            { name: "Australië", code: "AU" },
            { name: "Oostenrijk", code: "AT" },
            { name: "Azerbeidzjan", code: "AZ" },
            { name: "Bahamas", code: "BS" },
            { name: "Bahrein", code: "BH" },
            { name: "Bangladesh", code: "BD" },
            { name: "Barbados", code: "BB" },
            { name: "Wit-Rusland", code: "BY" },
            { name: "Belarus", code: "BY" },
            { name: "België", code: "BE" },
            { name: "Belize", code: "BZ" },
            { name: "Benin", code: "BJ" },
            { name: "Bermuda", code: "BM" },
            { name: "Bhutan", code: "BT" },
            { name: "Bolivia", code: "BO" },
            { name: "Bosnië-Herzegovina", code: "BA" },
            { name: "Botswana", code: "BW" },
            { name: "Bouvet eiland", code: "BV" },
            { name: "Brazilië", code: "BR" },
            { name: "Britse gebieden in de Indische oceaan", code: "IO" },
            { name: "Brunei", code: "BN" },
            { name: "Bulgarije", code: "BG" },
            { name: "Burkina Faso", code: "BF" },
            { name: "Burundi", code: "BI" },
            { name: "Cambodja", code: "KH" },
            { name: "Kameroen", code: "CM" },
            { name: "Canada", code: "CA" },
            { name: "Canarische eilanden", code: "XC" },
            { name: "Kaapverdië", code: "CV" },
            { name: "Cayman-eilanden", code: "KY" },
            { name: "Centraal-Afrikaanse Republiek", code: "CF" },
            { name: "Tsjaad", code: "TD" },
            { name: "Chili", code: "CL" },
            { name: "China", code: "CN" },
            { name: "Kersteiland", code: "CX" },
            { name: "Cocos (Keeling) eilanden", code: "CC" },
            { name: "Colombia", code: "CO" },
            { name: "Comoren", code: "KM" },
            { name: "Congo", code: "CG" },
            { name: "Congo, Democratische Republiek", code: "CD" },
            { name: "Cook eilanden", code: "CK" },
            { name: "Costa Rica", code: "CR" },
            { name: "Ivoorkust", code: "CI" },
            { name: "Kroatië", code: "HR" },
            { name: "Cuba", code: "CU" },
            { name: "Cyprus", code: "CY" },
            { name: "Tsjechië", code: "CZ" },
            { name: "Denemarken", code: "DK" },
            { name: "Djibouti", code: "DJ" },
            { name: "Dominica", code: "DM" },
            { name: "Dominicaanse Republiek", code: "DO" },
            { name: "Oost Timor", code: "TL" },
            { name: "Ecuador", code: "EC" },
            { name: "Egypte", code: "EG" },
            { name: "El Salvador", code: "SV" },
            { name: "Equatoriaal-Guinee", code: "GQ" },
            { name: "Eritrea", code: "ER" },
            { name: "Estland", code: "EE" },
            { name: "Ethiopië", code: "ET" },
            { name: "Europese unie", code: "EU" },
            { name: "Falkland eilanden", code: "FK" },
            { name: "Faeröer", code: "FO" },
            { name: "Fiji-eilanden", code: "FJ" },
            { name: "Finland", code: "FI" },
            { name: "Frankrijk", code: "FR" },
            { name: "Frans Guyana", code: "GF" },
            { name: "Frans-Polynesië", code: "PF" },
            { name: "Franse zuidelijke territoria", code: "TF" },
            { name: "Gabon", code: "GA" },
            { name: "Gambia", code: "GM" },
            { name: "Georgië", code: "GE" },
            { name: "Duitsland", code: "DE" },
            { name: "Ghana", code: "GH" },
            { name: "Gibraltar", code: "GI" },
            { name: "Griekenland", code: "GR" },
            { name: "Groenland", code: "GL" },
            { name: "Grenada", code: "GD" },
            { name: "Guadeloupe", code: "GP" },
            { name: "Guam", code: "GU" },
            { name: "Guatemala", code: "GT" },
            { name: "Guinee", code: "GN" },
            { name: "Guinee Bissau", code: "GW" },
            { name: "Guyana", code: "GY" },
            { name: "Haïti", code: "HT" },
            { name: "Heard en McDonald eilanden", code: "HM" },
            { name: "Vaticaanstad", code: "VA" },
            { name: "Honduras", code: "HN" },
            { name: "Hongarije", code: "HU" },
            { name: "Hongkong", code: "HK" },
            { name: "IJsland", code: "IS" },
            { name: "India", code: "IN" },
            { name: "Indonesië", code: "ID" },
            { name: "Iran", code: "IR" },
            { name: "Irak", code: "IQ" },
            { name: "Ierland", code: "IE" },
            { name: "Israël", code: "IL" },
            { name: "Italië", code: "IT" },
            { name: "Jamaica", code: "JM" },
            { name: "Japan", code: "JP" },
            { name: "Jordanië", code: "JO" },
            { name: "Kazachstan", code: "KZ" },
            { name: "Kenia", code: "KE" },
            { name: "Kiribati", code: "KI" },
            { name: "Koeweit", code: "KW" },
            { name: "Kirgizië", code: "KG" },
            { name: "Kosovo", code: "XK" },
            { name: "Laos", code: "LA" },
            { name: "Letland", code: "LV" },
            { name: "Libanon", code: "LB" },
            { name: "Lesotho", code: "LS" },
            { name: "Liberia", code: "LR" },
            { name: "Libië", code: "LY" },
            { name: "Liechtenstein", code: "LI" },
            { name: "Litouwen", code: "LT" },
            { name: "Luxemburg", code: "LU" },
            { name: "Macao", code: "MO" },
            { name: "Noord-Macedonië", code: "MK" },
            { name: "Madagascar", code: "MG" },
            { name: "Malawi", code: "MW" },
            { name: "Maleisië", code: "MY" },
            { name: "Maldiven", code: "MV" },
            { name: "Mali", code: "ML" },
            { name: "Malta", code: "MT" },
            { name: "Marshall eilanden", code: "MH" },
            { name: "Martinique", code: "MQ" },
            { name: "Mauritanië", code: "MR" },
            { name: "Mauritius", code: "MU" },
            { name: "Mayotte", code: "YT" },
            { name: "Mexico", code: "MX" },
            { name: "Micronesië, federale staten van", code: "FM" },
            { name: "Moldavië", code: "MD" },
            { name: "Monaco", code: "MC" },
            { name: "Mongolië", code: "MN" },
            { name: "Montenegro", code: "ME" },
            { name: "Montserrat", code: "MS" },
            { name: "Marokko", code: "MA" },
            { name: "Mozambique", code: "MZ" },
            { name: "Myanmar", code: "MM" },
            { name: "Namibië", code: "NA" },
            { name: "Nauroe", code: "NR" },
            { name: "Nepal", code: "NP" },
            { name: "Nederland", code: "NL" },
            { name: "Nieuw-Caledonië", code: "NC" },
            { name: "Nieuw-Zeeland", code: "NZ" },
            { name: "Nicaragua", code: "NI" },
            { name: "Niger", code: "NE" },
            { name: "Nigeria", code: "NG" },
            { name: "Niue", code: "NU" },
            { name: "Norfolk eiland", code: "NF" },
            { name: "Noord Korea", code: "KP" },
            { name: "Noordelijke Mariana eilanden", code: "MP" },
            { name: "Noorwegen", code: "NO" },
            { name: "Oman", code: "OM" },
            { name: "Pakistan", code: "PK" },
            { name: "Palau", code: "PW" },
            { name: "Palestijns bezette gebieden", code: "PS" },
            { name: "Panama", code: "PA" },
            { name: "Papoea-Nieuw-Guinea", code: "PG" },
            { name: "Paraguay", code: "PY" },
            { name: "Peru", code: "PE" },
            { name: "Filipijnen", code: "PH" },
            { name: "Pitcairn eilanden", code: "PN" },
            { name: "Polen", code: "PL" },
            { name: "Portugal", code: "PT" },
            { name: "Puerto Rico", code: "PR" },
            { name: "Quatar", code: "QA" },
            { name: "Réunion", code: "RE" },
            { name: "Roemenië", code: "RO" },
            { name: "Rusland", code: "RU" },
            { name: "Rwanda", code: "RW" },
            { name: "St. Helena", code: "SH" },
            { name: "St. Christoffer en Nevis", code: "KN" },
            { name: "St. Lucia", code: "LC" },
            { name: "St. Pierre en Miquelon", code: "PM" },
            { name: "St. Vincent", code: "VC" },
            { name: "Samoa", code: "WS" },
            { name: "San Marino", code: "SM" },
            { name: "Sâo Tomé en Príncipe", code: "ST" },
            { name: "Saoedi-Arabië", code: "SA" },
            { name: "Senegal", code: "SN" },
            { name: "Servië", code: "RS" },
            { name: "Seychellen", code: "SC" },
            { name: "Sierra Leone", code: "SL" },
            { name: "Singapore", code: "SG" },
            { name: "Sint Maarten", code: "SX" },
            { name: "Slowakije", code: "SK" },
            { name: "Slovenië", code: "SI" },
            { name: "Salomonseilanden", code: "SB" },
            { name: "Somalië", code: "SO" },
            { name: "Zuid-Afrika", code: "ZA" },
            { name: "Zuid-Georgië en de Zuidelijke Sandwicheilanden", code: "GS" },
            { name: "Zuid-Korea", code: "KR" },
            { name: "Zuid-Soedan", code: "SS" },
            { name: "Spanje", code: "ES" },
            { name: "Sri Lanka", code: "LK" },
            { name: "Soedan", code: "SD" },
            { name: "Suriname", code: "SR" },
            { name: "Nova Zembla", code: "SJ" },
            { name: "Swaziland", code: "SZ" },
            { name: "Zweden", code: "SE" },
            { name: "Zwitserland", code: "CH" },
            { name: "Syrië", code: "SY" },
            { name: "Taiwan", code: "TW" },
            { name: "Tadzjikistan", code: "TJ" },
            { name: "Tanzania", code: "TZ" },
            { name: "Thailand", code: "TH" },
            { name: "Oost-Timor", code: "TL" },
            { name: "Togo", code: "TG" },
            { name: "Tokelau", code: "TK" },
            { name: "Tonga", code: "TO" },
            { name: "Trinidad en Tobago", code: "TT" },
            { name: "Tunesië", code: "TN" },
            { name: "Turkije", code: "TR" },
            { name: "Turkmenistan", code: "TM" },
            { name: "Turks- en Caicos-eilanden", code: "TC" },
            { name: "Tuvalu", code: "TV" },
            { name: "Oeganda", code: "UG" },
            { name: "Oekraïne", code: "UA" },
            { name: "Verenigde Arabische Emiraten", code: "AE" },
            { name: "Verenigd Koninkrijk", code: "GB" },
            { name: "Verenigde Staten van Amerika", code: "US" },
            { name: "Verenigde Staten, kleine eilandjes", code: "UM" },
            { name: "Uruguay", code: "UY" },
            { name: "Oezbekistan", code: "UZ" },
            { name: "Vanuatu", code: "VU" },
            { name: "Venezuela", code: "VE" },
            { name: "Vietnam", code: "VN" },
            { name: "Britse Maagdeneilanden", code: "VG" },
            { name: "Maagdeneilanden (USA)", code: "VI" },
            { name: "Wallis- en Futuna-eilanden", code: "WF" },
            { name: "Westelijke Sahara", code: "EH" },
            { name: "Jemen", code: "YE" },
            { name: "Zambia", code: "ZM" },
            { name: "Zimbabwe", code: "ZW" },
            { name: "Bonaire, Sint Eustatius en Saba", code: "BQ" },
            { name: "Curaçao", code: "CW" },
            { name: "Sint Maarten (Nederland)", code: "SX" },
        ]
    }
];
