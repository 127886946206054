import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LoaderButton, CreditCardInput, Panel, Section } from 'intersolve.web.componentlib';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { CreditCard } from "../../../models/CreditCard";
import i18n from "../../../i18n";
import { Brand } from "../../../models/Brand";
import { OtcRequestResult } from "../../../models/OtcRequestResult";

export interface VisaNumberFormProps {
    state?: string;
    link?: string;
    brand: Brand;
    onNavigate: (creditCard: CreditCard, otcRequestResult: OtcRequestResult) => void;
}

const VisaNumberForm = (props: VisaNumberFormProps) => {
    const { t } = useTranslation();
    const [cardNumber, setCardNumber] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessageKey, setErrorMessageKey] = useState<string>("");
    const [recaptchaKey, setRecaptchaKey] = useState<string>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();

    const navigate = useNavigate();

    useEffect(() => {
        if (props.state === "loading") {
            setLoading(true);
        }

        if (props.state === "error") {
            setError(true);
        }
    }, [props.state])

    const getRecaptchaKey = React.useCallback(() => {
        fetch(`/api/Utility/Recaptcha`)
            .then((res) => res.json())
            .then((res) => {
                setRecaptchaKey(res.key);
            })
            .catch(() => {
                console.error("Error getting recaptcha key");
            });
    }, [setRecaptchaKey]);   

    useEffect(() => {
        getRecaptchaKey();
    }, [getRecaptchaKey])

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleInputChange = React.useCallback((value: string | undefined) => {
        setCardNumber(value);
        setError(false);
    }, [setCardNumber]);

    const onRecaptchaChange = React.useCallback((token: string | null) => {
        setRecaptchaToken(token);
    }, [setRecaptchaToken]);

    const handleRequestActivationCode = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/visa/RequestActivationCode/${props.brand.code}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "g-recaptcha-response": recaptchaToken,
                    cardNumber: cardNumber,
                    culture: i18n.language ?? "en"
                })
            });
            if (response.ok) {
                response.json().then((json) => {
                    props.onNavigate({ cardNumber: cardNumber! }, json);
                    navigate(`/${props.brand.code}/visa/activate/otc/`, { replace: true });
                });
            }
            else if (response.status === 400) {
                setError(true);
                response.json().then((json) => {
                    if (json.code === "TOKEN_ALREADY_ACTIVATED")
                        setErrorMessageKey("visa__error_tokenActive");
                    else if (json.code === "TOO_MANY_REQUESTS")
                        setErrorMessageKey("generic__error_tooManyRequests");
                    else if (json.code === "INVALID_TOKEN")
                        setErrorMessageKey('visa__cardnumberError');
                    else
                        setErrorMessageKey('generic__error_message');
                });
            }
            else {
                setError(true);
                setErrorMessageKey('visa__cardnumberError')
            }
        } catch {
            setError(true);
            setErrorMessageKey('generic__error_message');
        } finally {
            setLoading(false);
        }
    }, [cardNumber, navigate, props, recaptchaToken]);

    return (
        <>
            <Section className={loading ? "loading" : ""}>
                <div className="content form">
                    <Form onSubmit={handleSubmit}>
                        <CreditCardInput
                            error={error}
                            disabled={loading}
                            placeholder="0000 0000 0000 0000 000"
                            label={t('visa__cardnumber')}
                            helpText={t('visa__cardnumberHelp')}
                            onChange={handleInputChange}
                        />
                        <Form.Group className="form-group">
                            <input type="text" id="name" className="input" hidden />

                            {recaptchaKey &&
                                <ReCAPTCHA
                                    sitekey={recaptchaKey}
                                    onChange={onRecaptchaChange}
                                    className="recaptcha"
                                />
                            }
                        </Form.Group>
                        <Panel>
                            {error && (
                                <Alert key="danger" variant="danger">
                                    {t(errorMessageKey)}
                                </Alert>
                            )}
                        </Panel>
                        <LoaderButton loading={loading} type="submit" disabled={loading || error} onClick={handleRequestActivationCode}>
                            {loading ? t('generic__buttonWaiting') : t('visa__cardnumberSubmit')}
                        </LoaderButton>
                    </Form>
                </div>
            </Section>
        </>
    );
}
export default VisaNumberForm;
