import React, { ChangeEvent, useCallback } from "react";
import { Form } from "react-bootstrap";
import { InputProps } from ".";

interface SelectInputProps extends InputProps {
    selectItems: { value: string, name: string; }[],
    onChange: (value: string | number | string[] | undefined) => void,
    selectedValue: string | number | string[] | undefined,
    placeholder?: string,
    label?: string;
}


/**
 * Create a new styled select input
 * @example <SelectInput placeholder="Selecteer bank" label="test" selectedValue={temp} onChange={(val) => setTemp(val)} selectItems={[{ value: 'a', name: 'AAAA' }, { value: 'b', name: 'BBBB' }]} />
 * @param props
 * @returns JSX element
 */
const SelectInput = (props: SelectInputProps) => {
    const handleValueChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        props.onChange(event.target.value);
    }, []);

    return (
        <Form.Group className={`form-group`}>
            {
                props.label &&
                <Form.Label>{props.label}</Form.Label>
            }
            <Form.Select value={props.selectedValue} onChange={handleValueChange} className="large">
                {
                    props.placeholder &&
                    <option disabled value="">{props.placeholder}</option>
                }
                {
                    props.selectItems &&
                    props.selectItems.map((item) => {
                        return <option key={item.value} value={item.value}>{item.name}</option>;
                    })
                }
            </Form.Select>
        </Form.Group>
    );
};

export default SelectInput;