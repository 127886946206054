import AmountDisplay from "../../components/AmountDisplay";
import { Section, RetryButton } from 'intersolve.web.componentlib';
import React from "react";
import { BrandPageProps } from "../../models/BrandPage";
import { BalanceGiftCard } from "../../models/GiftCard";
import { Navigate, useNavigate } from "react-router";
import { t } from 'i18next';

interface BalanceAmountViewProps extends BrandPageProps {
    giftCard?: BalanceGiftCard,
}

const BalanceAmountView = (props: BalanceAmountViewProps) => {
    const navigate = useNavigate();

    const refreshPage = React.useCallback(() => {
        navigate(`/${props.brand.code}/balance/`);
    }, [navigate, props.brand.code]);

    if (!props.giftCard)
        return <Navigate to={`/${props.brand.code}/balance/`} replace />;
    return (
        <React.Fragment>
            {props.giftCard &&
                <AmountDisplay giftCard={props.giftCard} />
            }
            <Section className="content">
                <div className="panel">
                    {t('balance__thankYou') }
                </div>
                <RetryButton type="button" onClick={refreshPage}>{t('generic__retryButtonLabel') }</RetryButton>
            </Section>
        </React.Fragment>
    );
};

export default BalanceAmountView;