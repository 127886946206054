import React from 'react';
import { Spinner } from 'react-bootstrap';
import BaseButton, { ButtonTypes, BaseButtonProps } from './BaseButton';

interface LoaderButtonProps extends BaseButtonProps {
    loading?: boolean;
}

const LoaderButton = (props: LoaderButtonProps) => {
    return (
        <BaseButton {...props} buttonType={ButtonTypes.LoaderButton} className={props.loading ? "is-loading" : ""}>
            <React.Fragment>
                {props.loading &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"                        
                    />
                }
                {props.children}
            </React.Fragment>
        </BaseButton>
    );
};

export default LoaderButton;