import React, { useEffect } from "react";
import VisaNumberForm from "../../components/Visa/Form/VisaNumberForm";
import VisaCardSection from "../../components/Visa/Section/VisaCardSection";
import { Title, Section } from 'intersolve.web.componentlib';
import { useTranslation } from 'react-i18next';
import { CreditCard } from "../../models/CreditCard";
import { BrandPageProps } from "../../models/BrandPage";
import { OtcRequestResult } from "../../models/OtcRequestResult";

interface VisaCheckProps extends BrandPageProps{
    onNavigate: (creditCard: CreditCard, activationCodeResult: OtcRequestResult) => void;
}

function VisaCheck(props: VisaCheckProps) {
    const { t } = useTranslation();
    const scrollToWindowTop = React.useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[]);

    useEffect(() => {
        scrollToWindowTop();
    }, [scrollToWindowTop]);

    return (
        <>
            <Title
                title={t('visa__accountTitle')}
                subTitle={t('visa__cardName')}
                anchor={t('visa__explanationAnchor')}
                anchortitle={t('visa__explanationAnchorTitle')}
            ></Title>
            <Section>
                <div className="content">
                    {t('visa__explanationText')}
                </div>
            </Section>
            <VisaNumberForm brand={props.brand} link={`${props.brand.code}/visa/visa-otc`} onNavigate={props.onNavigate}></VisaNumberForm>
            <hr />
            <VisaCardSection />
        </>
    );
}

export default VisaCheck;
