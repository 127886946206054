import { useCallback, useEffect, useState } from "react";
import { BrandPageProps } from "../../models/BrandPage";
import { BalanceGiftCard } from "../../models/GiftCard";
import { v4 as uuidv4 } from 'uuid';
import { Provider } from "../../models/Provider";
import { Navigate } from "react-router-dom";
import AmountDisplay from "../../components/AmountDisplay";
import { DescriptionLabel, Section, SelectInput, LoaderButton, handleClickScroll } from 'intersolve.web.componentlib';
import idinLogo from "../../static/graphics/idin-logo.svg";
import { Col, Row } from "react-bootstrap";
import idinImage from "../../static/img/iDIN-illustration.png";
import calculateImage from "../../static/img/calculation-illustration.png";
import { t } from 'i18next';


interface ProviderSelectViewProps extends BrandPageProps {
    giftCard?: BalanceGiftCard,
   
}

const ProviderSelectView = (props: ProviderSelectViewProps) => {
    const [providers, setProviders] = useState<Provider[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        const fetchProviders = async () => {
            const response = await fetch(`/api/Authentication/${props.brand.code}/providers`);
            if (response.ok) {
                const data = await response.json();
                setProviders(data);
            }
        };

        fetchProviders();
    }, [props.brand.code]);

    const handleSelectChange = useCallback((value: string) => {
        setSelectedValue(value);
        setDisabled(!value);
    }, [setSelectedValue, setDisabled]);

    const handleCreateSession = useCallback(() => {
        const state = uuidv4();

        localStorage.setItem(state, JSON.stringify({ url: `/${props.brand.code}/identify/callback`, giftCard: props.giftCard }));

        const request = {
            provider: 'IDIN',
            issuer: selectedValue,
            redirectUrl: `${window.location.origin}/callback`,
            state: state
        };

        createSession(props.brand.code, request);
    }, [selectedValue, props.brand.code, props.giftCard]);

    const createSession = async (brandId, request) => {
        const response = await fetch(`/api/Authentication/${brandId}/session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });
        if (response.ok) {
            const data = await response.json();
            const urlParams = data.authorizationUrl;
            window.location.href = urlParams;
        }
    };

    if (!props.giftCard)
        return <Navigate to={`/${props.brand.code}/identify/`} replace />;

    return (
        <>
            <AmountDisplay giftCard={props.giftCard} />
            <hr className="small" />
            <Section className="content">
                <SelectInput label={t('identify__selectBank')} placeholder={t('identify__hostingBankAccount')} selectItems={providers[0]?.issuers.map((issuer) => ({ value: issuer.id, name: issuer.name }))} id="provider-select" selectedValue={selectedValue} onChange={handleSelectChange} />
                <LoaderButton type="button" onClick={handleCreateSession} disabled={disabled}>{t('identify__idinIdentification')}</LoaderButton>
            </Section>
            <Section>
                <div className="content has-light-background">
                    <div className="panel-row">
                        <img src={idinLogo} alt="iDIN logo" />
                        <div>
                            <p>
                                {t('identify__explainIdentification') }
                                <br />
                                <a className="scroll-link" onClick={(e) => handleClickScroll("content-1", e)}>{t('identify__WhatIsIdin') }</a>
                            </p>
                        </div>
                    </div>
                </div>
            </Section>
            <hr />
            <>
                <Section className="content" id="content-1">
                    <DescriptionLabel as="h2" display={3} >{t('identify__WhatIsIdin')}</DescriptionLabel>
                    <DescriptionLabel as="h3" display={5} muted>{t('identify__identityControl') }</DescriptionLabel>
                </Section>
                <Section className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={idinImage}
                                alt="iDIN"
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <DescriptionLabel as="h4" className="display-6">{t('identify__yourIdentity') }</DescriptionLabel>
                            <p>
                                {t('identify__firstIdinFullExplanation') }
                            </p>
                            <p>
                                {t('identify__secondIdinExplanation')  }
                            </p>
                        </Col>
                    </Row>
                </Section>
                <hr />
                <Section className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={calculateImage}
                                alt="iDIN"
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('identify__spendGiftCardText')}</h4>
                            <p>
                                {t('identify__giftCardExplanation') }
                            </p>
                        </Col>
                    </Row>
                </Section>

            </>
        </>
    );
};

export default ProviderSelectView;