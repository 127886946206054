import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Section, Title } from 'intersolve.web.componentlib';
import { Loader } from "./Loader";
interface CallbackProps {
    onCallBack: (sessionId: string | null | undefined, state: string | null | undefined) => void;
}

interface IDINCallbackHandlerProps extends CallbackProps{
}

const IDINCallbackHandler = (props: IDINCallbackHandlerProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const state = searchParams.get('state');

        // Get the sessionId value from the search params
        const sessionId = searchParams.get('sessionId');
        props.onCallBack(sessionId, state);
    }, [props]);

    return <>
        <Section className="section">
            <div className="content">
                <div className="panel center-content my-4">
                    <div className="mb-4">
                        <Loader />
                    </div>
                    <p className="text-muted">{t("payout__loading_text_2")}</p>
                </div>
            </div>
        </Section>
    </>;

};

export default IDINCallbackHandler