import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Section } from "intersolve.web.componentlib";

const VisaIssuerStatement = () => {
    const { t } = useTranslation();
    const IssuerStatement = (
        <Tooltip className="text-left" id="issuerstatement-tooltip">
            {t("visa__issuer_statement_description")}
        </Tooltip>
    );
    return (
        <>
            <hr className="no-padding" />
            <Section className="small-padding bg__light-lighter-6">
                <div className="hint">
                    <OverlayTrigger placement="top" overlay={IssuerStatement}>
                        <div className="hint-label">
                            <span className="icon icon-info-circle__color bg-info"></span>
                            {t("visa__issuer_statement_text")}{" "}
                        </div>
                    </OverlayTrigger>
                </div>
            </Section>
        </>
    );
}

export default VisaIssuerStatement;
