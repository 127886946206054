import React from "react";
import { useNavigate } from "react-router";
import GiftCardForm from "../../components/GiftCardForm";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { CardInfo } from "../../models/CardInfo";

interface ReplaceCardViewProps extends BrandPageProps {
    onNavigate: (giftCard: GiftCard, securityCode: CardInfo) => void;
    giftCard?: GiftCard;
}

const ReplaceCardView = (props: ReplaceCardViewProps) => {
    const navigate = useNavigate();

    const handleGiftCardSuccess = React.useCallback((giftCard: GiftCard, securityCode: CardInfo) => {
        props.onNavigate(giftCard, securityCode);

        navigate(`/${props.brand.code}/replace/email/`);//TODO: check with UI designer for the flow if you have enough saldo yes or no and how to handle this. if you go to the email page yes or no.
    }, [navigate, props]);

    return (
        <>
            <GiftCardForm brand={props.brand} onSuccess={handleGiftCardSuccess} url={`/api/ReplaceCard/`} method={"POST"} />
        </>
    );
};
export default ReplaceCardView;
