/* eslint-disable react-hooks/exhaustive-deps */
import { BrandPageProps } from "../../models/BrandPage";
import { BalanceGiftCard } from "../../models/GiftCard";
import { Section, DefaultInput, LoaderButton, Panel } from 'intersolve.web.componentlib';
import { InformationType } from "../../models/Brand";
import AmountDisplay from "../../components/AmountDisplay";
import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardInfo } from "../../models/CardInfo";

interface ReplaceCardEmailProps extends BrandPageProps {
    giftCard?: BalanceGiftCard,
    cardInfo?: CardInfo,
    onNavigate: (email: string) => void;
}

const ReplaceCardEmail = (props: ReplaceCardEmailProps) => {
    const navigate = useNavigate();

    const [isBalanceSufficient, setIsBalanceSufficient] = useState<boolean>(true);

    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorRepeatEmail, setErrorRepeatEmail] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailInput, setEmailInput] = useState<string>("");
    const [repeatEmailInput, setRepeatEmailInput] = useState<string>("");
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleEmailChange = React.useCallback((e) => {
        const input = e;
        setEmailInput(input);
        validateEmail(input);
    }, [setEmailInput]);

    const handleRepeatEmailChange = React.useCallback((e) => {
        const input = e;
        setRepeatEmailInput(input);
        validateEmailCheck(input);
        validateEmails(emailInput, input);
    }, [emailInput, repeatEmailInput]);

    const validateEmail = (email) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

        if (!emailRegex.test(email)) {
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
    };

    const validateEmailCheck = (email) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

        if (!emailRegex.test(email)) {
            setErrorRepeatEmail(true);
        } else {
            setErrorRepeatEmail(false);
        }
    };

    const validateEmails = (email1, email2) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

        if (emailRegex.test(email1) && emailRegex.test(email2)) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    };

    const confirmAccount = React.useCallback(async () => {
        try {
            const response = await fetch(`/api/replacecard/${props.brand.code}/replace`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: props.giftCard?.code,
                    email: emailInput,
                    securityCode: props.cardInfo?.securityCode,
                    balances: props.giftCard?.balances
                }),
            });
            if (response.ok) {
                navigate(`/${props.brand.code}/replace/success/`);
                props.onNavigate(emailInput);

            }
            else if (response.status === 400) {
                response.json().then((data) => {
                    if (data.code === "BALANCE_TOO_LOW")
                        setIsBalanceSufficient(false);
                    else
                        setError(true);
                });
            }
            else
                setError(true);
        } catch (error) {
            setError(true);
            navigate(`/${props.brand.code}/replace/error/`);
            console.error('Error:', error);
            console.error("Oeps er is iets fout gegaan");
        }
        finally {
            setLoading(false);
        }

    }, [props, emailInput, navigate]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/replace`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <AmountDisplay giftCard={props.giftCard} />
                <Section className="content">
                    <DefaultInput
                        type="email"
                        inputMode="email"
                        label={t('replace__email_label')}
                        placeholder={t('replace__email_placeholder')}
                        subLabel={t('replace__subLabel')}
                        onChange={handleEmailChange}
                        addToolTip
                        toolTip={{
                            enabled: props.brand?.styling?.informationType === InformationType.Tooltip, type: "icon", props: { text: t('replace__tooltipEmail'), placement: "right", className: "custom-tooltip" }
                        }}
                        value={emailInput}
                    />
                    {emailInput && errorEmail && (
                        <Form.Text className="text-danger">
                            {t("replace__email_text_error")}
                        </Form.Text>
                    )}
                </Section>
                <Section className="content">
                    <DefaultInput
                        type="email"
                        inputMode="email"
                        label={t('replace__email_check_label')}
                        placeholder={t('replace__email_check_placeholder')}
                        subLabel={t('replace__subLabel')}
                        onChange={handleRepeatEmailChange}
                        addToolTip
                        toolTip={{
                            enabled: props.brand?.styling?.informationType === InformationType.Tooltip, type: "icon", props: { text: t('replace__tooltipEmail'), placement: "right", className: "custom-tooltip" }
                        }}
                        value={repeatEmailInput}
                    />
                    {repeatEmailInput && errorRepeatEmail && (
                        <Form.Text className="text-danger">
                            {t("replace__email_text_error")}
                        </Form.Text>
                    )}
                    {emailInput !== '' && repeatEmailInput !== '' && emailInput !== repeatEmailInput && (
                        <Form.Text className="text-danger">
                            {t("replace__email_text_error_match")}
                        </Form.Text>
                    )}
                </Section>
                <Section>
                    <div className="content">
                        <Panel>
                            {error && (
                                <Alert key="danger" variant="danger">
                                    {t('replace__text_error')}
                                </Alert>
                            )}
                            {!isBalanceSufficient && (
                                <Alert key="danger" variant="danger">
                                    {t('replace__insufficient_text_error')}
                                </Alert>
                            )}
                        </Panel>
                    </div>
                </Section>
                <Section className="content">
                    <LoaderButton loading={loading} disabled={(!(submitEnabled && isBalanceSufficient))}
                        type="submit" className="is-narrow-mobile" onClick={confirmAccount}>{t('replace__email_check_submit')}
                    </LoaderButton>
                </Section>
            </> : <></>
    );
};

export default ReplaceCardEmail;