import Section from './Section';
import React from 'react';
import { DescriptionLabel } from '../Labels';
import { handleClickScroll } from '../Utility/Functions';

export interface TitleProps {
    title: string;
    subTitle: string;
    intro?: string;
    anchor?: string;
    anchorTitle?: string;
    tag?: any;
    tagType?: string;
}

const Title = (props: TitleProps) => {
    const tagClasses = "tag tag__" + props.tagType;

    return (
        <>
            <Section>
                <div className="content">
                    <DescriptionLabel display={1} as="h1">{props.title}</DescriptionLabel>
                    {props.subTitle &&
                        <DescriptionLabel display={5} as="h2" muted>
                            {props.subTitle}{props.tag && props.tagType && <span className={tagClasses}>{props.tag}</span>}
                        </DescriptionLabel>
                    }
                    {props.anchor &&
                        <a className="is-mobile" onClick={(e) => handleClickScroll(props.anchor, e)}>{props.anchorTitle}</a>
                    }
                    {props.intro && <p>{props.intro}</p>}
                </div>
            </Section>
        </>
    );
};

export default Title;