import { useEffect } from "react";
import VisaReviewInfoForm from "../../components/Visa/Form/VisaReviewInfoForm";
import { Title, Section } from 'intersolve.web.componentlib';
import CardBalance from "../../components/CardBalance";
import { useTranslation } from 'react-i18next';
import { BrandPageProps } from "../../models/BrandPage";
import { CardHolderInformation } from "../../models/CardHolderInformation";
import { useNavigate } from "react-router-dom";
import PincodeDisplay from "../../components/Visa/PincodeDisplay";
import { CreditCard } from "../../models/CreditCard";
import { Alert } from "react-bootstrap";
interface VisaAccountProps extends BrandPageProps {
    holderInformation?: CardHolderInformation;
    creditCard?: CreditCard;
    passwordReset: boolean;
}

const VisaAccount = (props: VisaAccountProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    useEffect(() => {
        if (!props.creditCard)
            navigate(`/${props.brand.code}/visa/account`, { replace: true });
    });

    return (
        props.creditCard ?
            <>
                <Title title={t('visa__account_title')}></Title>
                {props.passwordReset &&
                    <Section>
                        <Alert variant="success">
                            <h4 className="alert-heading mt-1">{t("visa__security_passcode_success_title")}</h4>
                            <strong>{t("visa__security_passcode_success_strong")}</strong> {t("visa__security_passcode_success_afterStrong")}
                        </Alert>
                    </Section>
                }
                <CardBalance
                    card_title={t("visa__cardnumber")}
                    currency={props.holderInformation?.currency}
                    balance={props.holderInformation?.balance}
                    balance_title="Balance"
                    boxed={false}
                    tag_title={t("generic__tagActive")}
                    tag_type="success"
                    creditCard={props.creditCard}
                />
                <hr />
                <Section>
                    <div className="content form">
                        <h3 className="display-4">{t('visa__account_personal_info_title')}</h3>
                        <p>{t("visa__account_personal_info_description")}</p>
                        <VisaReviewInfoForm brand={props.brand} isReview={false} holderInformation={props.holderInformation} creditCard={props.creditCard} readOnly />
                    </div>
                </Section>
                <hr />
                <Section id="#pincode">
                    <div className="content">
                        <PincodeDisplay
                            brand={props.brand}
                            title={t("visa__account_get_pin_title")}
                            description={t("visa__account_get_pin_description")}
                            creditCard={props.creditCard}
                        />
                    </div>
                </Section>
            </>
            : <></>
    );
};

export default VisaAccount;
