import { useEffect } from "react";

const AboutPage = () => {
    const scrollToWindowTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        scrollToWindowTop();
    }, []);

    return (
        <section className="section">
            <div className="content">
                Application version: {process.env.REACT_APP_VERSION}
            </div>
        </section>
    );
};

export default AboutPage;