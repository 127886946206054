import React, { useState, useRef } from "react";
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Recaptcha from "../../Recaptcha";
import { CardInput, LoaderButton } from 'intersolve.web.componentlib';
import { BrandPageProps } from "../../../models/BrandPage";
import { CreditCard } from "../../../models/CreditCard";
import { OtcRequestResult } from "../../../models/OtcRequestResult";
import i18n from "../../../i18n";

interface VisaForgotFormProps extends BrandPageProps {
    cardNumber?: string;
    onSuccess: (cardInfo: CreditCard, otcRequestResult: OtcRequestResult) => void;
}

const VisaForgotForm = (props: VisaForgotFormProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [creditCard, setCreditCard] = useState<string>();
    const [errorKey, setErrorKey] = useState<string>("");
    const [recaptchaToken, setRecaptchaToken] = useState<string | null | undefined>();
    const [isVerified, setIsVerified] = useState<boolean>(false);

    const giftCardRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const onChangeCardNumber = React.useCallback((value: string) => {
        setCreditCard(value);
        setError(false);
        setErrorKey("");
    }, []);

    const handleSubmit = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleRequestOtcCode = React.useCallback(() => {
        setLoading(true);
        const url = `/api/visa/requestrecovery/${props.brand.code}`;
        fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(
                {
                    "g-recaptcha-response": recaptchaToken,
                    cardNumber: creditCard,
                    culture: i18n.language ?? "en"
                })
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((json) => {
                        props.onSuccess({ cardNumber: creditCard! }, json);
                        //navigate(`/${props.brand.code}/visa/activate/otc/`, { replace: true });
                    })
                }
                else if (res.status === 400) {
                    setError(true);
                    res.json().then((json) => {
                        if (json.code === "TOKEN_IS_NOT_ACTIVE")
                            setErrorKey("visa__error_tokenNotActive");
                        else if (json.code === "TOO_MANY_REQUESTS")
                            setErrorKey("generic__error_tooManyRequests");
                        else if (json.code === "INVALID_TOKEN")
                            setErrorKey('visa__loginError');
                        else
                            setErrorKey('generic__error_message');
                    });
                }
                else {
                    setError(true);
                    setErrorKey('visa__loginError');
                }
            })
            .catch(error => {
                setError(true);
                setErrorKey("generic__error_message");
                console.error("Error: ", error);

            })
            .finally(() => {
                setLoading(false);
            });
    }, [props, creditCard, recaptchaToken, navigate]);

    const onSetRecaptchaToken = React.useCallback((token: string | null | undefined) => {
        setIsVerified(token !== null && token !== undefined);
        setRecaptchaToken(token);
    }, []);

    return (
        <>
            <div className={loading ? "section loading" : "section"}>
                <div className="content form">
                    <Form onSubmit={handleSubmit}>
                        <CardInput
                            label={t('visa__cardnumber')}
                            placeholder="0000 0000 0000 0000 000"
                            onChange={onChangeCardNumber}
                            inputRef={giftCardRef}
                            disabled={loading}
                            helpText={t('visa__cardnumberHelp')}
                            value={creditCard}
                        />

                        <Recaptcha
                            onSetRecaptchaToken={onSetRecaptchaToken}
                            tokenUrl={`/api/Utility/Recaptcha`}
                        />
                        <div className="panel">
                            {error && (
                                <Alert key="danger" variant="danger my-0">
                                    {t(errorKey)}
                                </Alert>
                            )}
                        </div>
                        <LoaderButton disabled={!isVerified} loading={loading} type="submit" onClick={handleRequestOtcCode}>
                            {loading ? t('generic__buttonWaiting') : t('visa__loginSubmitForgotten')}
                        </LoaderButton>
                    </Form>
                </div>
            </div>
        </>
    );
}
export default VisaForgotForm;
